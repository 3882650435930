<template>
  <v-icon
    :size="size"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-icon>
</template>

<script>
  export default {
    name: 'LandIcon',
    props: {
      size: {
        type: [Number, String],
        default: 56,
      },
    },
  }
</script>
