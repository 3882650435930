
import api from '@/api/co.api'

const FeedStates = {
  // 待处理
  PENDING: 'pending',
  // 处理中
  PROCESSING: 'processing',
  // 已解决
  SOLVED: 'solved'
}

const FeedTypes = {
  AFTERSALE: 'aftersale',
  QUESTION: 'question',
  DEMAND: 'demand',
  CONSULT: 'consult',
  PROJECTRECORD: 'projectrecord',
  COMPLAIN: 'complain'
}

const FeedStateDescs = {
  [FeedStates.PENDING]: {
    text: '待处理',
    value: FeedStates.PENDING,
    style: api.comm.StateStyles.BLACK_DARK
  },
  [FeedStates.PROCESSING]: {
    text: '处理中',
    value: FeedStates.PROCESSING,
    style: api.comm.StateStyles.BLUE_DARK
  },
  [FeedStates.SOLVED]: {
    text: '已处理',
    value: FeedStates.SOLVED,
    style: api.comm.StateStyles.GREEN_DARK
  }
}

const FeedTypeDescs = {
  [FeedTypes.AFTERSALE]: {
    text: '售后服务',
    value: FeedTypes.AFTERSALE,
    style: api.comm.StateStyles.PURPLE_PLAIN
  },
  [FeedTypes.QUESTION]: {
    text: '问题反馈',
    value: FeedTypes.QUESTION,
    style: api.comm.StateStyles.ORANGE_PLAIN
  },
  [FeedTypes.DEMAND]: {
    text: '需求申请',
    value: FeedTypes.DEMAND,
    style: api.comm.StateStyles.CYAN_PLAIN
  },
  [FeedTypes.CONSULT]: {
    text: '服务咨询',
    value: FeedTypes.CONSULT,
    style: api.comm.StateStyles.TEAL_PLAIN
  },
  [FeedTypes.PROJECTRECORD]: {
    text: '项目报备',
    value: FeedTypes.PROJECTRECORD,
    style: api.comm.StateStyles.BLUE_PLAIN
  },
  [FeedTypes.COMPLAIN]: {
    text: '用户投诉',
    value: FeedTypes.COMPLAIN,
    style: api.comm.StateStyles.RED_PLAIN
  }
}

const coFeed = {
  States: FeedStates,
  Types: FeedTypes,
  getStateDesc (status) {
    let result = FeedStateDescs[status]
    if (!result) {
      result = {
        text: '未定义',
        value: status,
        style: api.comm.StateStyles.GRAY_DARK
      }
    }

    return result
  },
  getStatesDescs (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in FeedStateDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...FeedStateDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有状态',
        value: includes.join('|')
      })
    }

    return items
  },
  getTypeDesc (type) {
    let result = FeedTypeDescs[type]
    if (!result) {
      result = {
        text: '未定义',
        value: type,
        style: api.comm.StateStyles.GRAY_PLAIN
      }
    }

    return result
  },
  getTypeDescs (addAll = false, excludes = []) {
    const items = []

    const includes = []
    for (const key in FeedTypeDescs) {
      if (!excludes.includes(key)) {
        includes.push(key)
        items.push({
          ...FeedTypeDescs[key]
        })
      }
    }

    if (addAll) {
      items.unshift({
        text: '所有类型',
        value: includes.join('|')
      })
    }

    return items
  },
  getParams ({
    siteId = '',
    status = '',
    feedbackType = '',
    search = '',
    owner = '',
    sort = 'createTime',
    order = 'desc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.CMS_FEEDBACK_LISTCUST,
      data: {
        siteId,
        status,
        feedbackType,
        owner,
        search,
        offset: '0',
        limit: '20',
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.CMS_FEEDBACK_DETAIL}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.http.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.http.delete({
      url: api.url.CMS_FEEDBACK_DELETE,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.http.update({
      url: api.url.CMS_FEEDBACK_UPDATE,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.http.create({
      url: api.url.CMS_FEEDBACK_CREATE,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    const statusDesc = this.getStatusDesc(srcItem.status)
    const typeDesc = this.getTypeDesc(srcItem.feedbackType)

    let problemImgs = []
    try {
      const temps = JSON.parse(srcItem.problemImg)
      for (const i in temps) {
        problemImgs.push(temps[i].image)
      }
    } catch (error) {
      problemImgs = []
    }

    let revertImgs = []
    const revertFiles = []
    try {
      const temps = JSON.parse(srcItem.revertImg)
      for (const i in temps) {
        revertImgs.push(temps[i].image)
        revertFiles.push({
          name: temps[i].name || `图片${i}`,
          url: temps[i].image
        })
      }
    } catch (error) {
      revertImgs = []
    }

    const dstItem = {
      ...srcItem,
      statusDesc,
      typeDesc,
      problemImgs,
      revertImgs,
      revertFiles
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coFeed.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default coFeed
