<template>
  <div
    :class="classes"
    class="pt-2 px-4"
  >
    <land-avatar
      v-if="icon"
      :color="color"
      :icon-color="iconColor"
      :dark="dark"
      :icon="icon"
      :outlined="outlined"
      :size="size"
      class="mb-3 align-self-start"
      @click="onClick"
    />

    <div :class="horizontal && title && 'ml-6'">
      <land-title
        :title="title"
        class="text-uppercase"
        space="3"
        @click="onClick"
      />

      <land-body
        v-if="text || $slots.default"
        :space="horizontal ? 0 : undefined"
        :text="text"
        class="mx-auto"
        max-width="700"
      >
        <slot />
      </land-body>
    </div>
  </div>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'LandAvatarCard',
    mixins: [Heading],
    props: {
      align: {
        type: String,
        default: 'left',
      },
      color: String,
      iconColor: String,
      dark: Boolean,
      horizontal: Boolean,
      icon: String,
      outlined: {
        type: Boolean,
        default: true,
      },
      space: {
        type: [Number, String],
        default: 8,
      },
      size: {
        type: [Number, String],
        default: 72,
      },
      text: String,
      title: String,
      name: String,
    },
    computed: {
      classes () {
        const classes = [
          `mb-${this.space}`,
        ]

        if (this.horizontal) {
          classes.push('d-flex')

          if (!this.$slots.default && !this.text) {
            classes.push('align-center')
          }
        }

        return classes
      },
    },
    methods: {
      onClick () {
        this.$emit('click', {
          text: this.text,
          title: this.title
        })
      }
    }
  }
</script>
