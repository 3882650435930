<template>
  <land-section
    :color="backColor"
    :space="space"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
      :color="assistColor"
      :icon="header.icon"
      :outlined="header.outlined"
    >
      {{ header.subtitle }}
    </land-section-heading>

    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="10"
        >
          <v-tabs
            v-model="currTab"
            background-color="transparent"
            :color="assistColor"
            grow
            class="mb-6"
          >
            <v-tab
              v-for="(tab, tabInd) in features"
              :key="`tab_${tabInd}`"
              class="text-h6 text-md-h5"
              style="font-weight: 400;"
            >
              {{ tab.title }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>

      <v-tabs-items
        v-model="currTab"
        class="mt-4"
      >
        <v-tab-item
          v-for="(feature, index) in features"
          :key="`tab_item_${index}`"
        >
          <v-card
            flat
            :color="backColor"
          >
            <land-images-text-guide
              :content="feature"
              :reversed="feature.reversed || false"
              :show-indicator="feature.showIndicator"
              :cycle="feature.cycle || false"
              :image-width="feature.imageWidth || 344"
              :image-height="feature.imageHeight || 500"
              @action="onAction"
            />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'LandIntroTabs',
    props: {
      space: {
        type: [Number, String],
        default: 80,
      },
      backColor: {
        type: String,
        default: 'white'
      },
      foreColor: {
        type: String,
        default: 'primary'
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      features: {
        type: Array,
        default: () => {
          return []
        }
      },
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        currTab: 'tab_0'
      }
    },
    methods: {
      onAction (res) {
        this.$emit('action', res)
      }
    }
  }
</script>
