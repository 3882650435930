/**
 * 接口地址
 */
const CAPTCHA_BASEURL = 'http://218.94.27.174:5002'
const CMS_BASEURL = 'http://218.94.27.174:5021'
const UPMS_BASEURL = 'http://218.94.27.174:5007'
const UCENTER_BASEURL = 'http://218.94.27.174:5011'
const PAY_BASEURL = 'https://proxy.landcoo.com:6031'
const OSS_BASEURL = 'http://218.94.27.174:5099'

// const CMS_MNG_BASEURL16 = 'http://192.168.0.205:5002'
const CMS_MNG_BASEURL06 = 'http://218.94.27.174:5017'
// const CMS_MNG_BASEURL26 = 'http://192.168.0.205:5002'

const apiUrl = {
  CAPTCHA_GET: CAPTCHA_BASEURL + '/captcha',

  AMAP_GET_REGEO: 'https://restapi.amap.com/v3/geocode/regeo',
  AMAP_GET_DISTRICT: 'https://restapi.amap.com/v3/config/district',

  OSS_RES_UPLOAD: OSS_BASEURL + '/oss/fileUpload',

  PAY_CHANNEL_LIST: PAY_BASEURL + '/payChannel/list',
  PAY_MONEY_GET: PAY_BASEURL + '/money/get',
  PAY_ACCOUNT_ESHOP_GET: PAY_BASEURL + '/accountEshop/get',
  PAY_RECORD_LIST: PAY_BASEURL + '/payRecord/list',
  PAY_RECORD_DETAIL: PAY_BASEURL + '/payRecord/details',

  PAY_QUERY_MONEY: PAY_BASEURL + '/pay/queryMoney',
  PAY_QUERY_RECORD: PAY_BASEURL + '/pay/queryPayRecord',

  UC_TERM_CODE: UCENTER_BASEURL + '/term/code',
  UC_TERM_INVITE: UCENTER_BASEURL + '/term/invite',

  uc_sso_login: UCENTER_BASEURL + '/sso/login',
  uc_sso_logout: UCENTER_BASEURL + '/sso/logout',
  uc_sso_signup: UCENTER_BASEURL + '/sso/signup',
  uc_sso_refresh: UCENTER_BASEURL + '/sso/refreshToken',

  UC_UNION_AUTH: UCENTER_BASEURL + '/union/oauth',
  UC_UNION_AUTH_MOBILE: UCENTER_BASEURL + '/union/oauthWithMobile',
  UC_UNION_QRCODE: UCENTER_BASEURL + '/union/getQrCode',

  UC_USER_CODELOGIN: UCENTER_BASEURL + '/codeLogin',
  UC_USER_INFO: UCENTER_BASEURL + '/user/base',
  UC_USER_BASICINFO: UCENTER_BASEURL + '/user/basicInfo',
  UC_USER_UPDATE: UCENTER_BASEURL + '/user/update',
  UC_USER_MOBILE: UCENTER_BASEURL + '/user/mobile',
  UC_USER_ACCOUNT: UCENTER_BASEURL + '/user/account',
  UC_USER_FORGOTPWD: UCENTER_BASEURL + '/user/forgetPwd',
  UC_FOCUSE_LIST: UCENTER_BASEURL + '/focuse/list',
  UC_FOCUSE_SAVE: UCENTER_BASEURL + '/focuse/save',
  UC_FOCUSE_INFO: UCENTER_BASEURL + '/focuse/info',
  UC_COUPON_LIST: UCENTER_BASEURL + '/coupon/list',
  UC_COUPON_EXCHANGE: UCENTER_BASEURL + '/coupon/exchange2',
  UC_HISTORY_LIST: UCENTER_BASEURL + '/history/list',
  UC_HISTORY_DELETE: UCENTER_BASEURL + '/history/delete',

  UC_DELVERY_LIST: UCENTER_BASEURL + '/delivery/list',
  UC_DELVERY_CREATE: UCENTER_BASEURL + '/delivery/create',
  UC_DELVERY_UPDATE: UCENTER_BASEURL + '/delivery/update',
  UC_DELVERY_DELETE: UCENTER_BASEURL + '/delivery/delete',

  UC_ORDER_LIST: UCENTER_BASEURL + '/order/list',
  UC_ORDER_DETAIL: UCENTER_BASEURL + '/order/detail',
  UC_ORDER_PAY: UCENTER_BASEURL + '/order/pay',
  UC_ORDER_QUERY: UCENTER_BASEURL + '/order/query',
  UC_ORDER_OFFLINEPAY: UCENTER_BASEURL + '/order/offlinePay',
  UC_ORDER_CANCEL: UCENTER_BASEURL + '/order/cancel',
  UC_ORDER_STATUS: UCENTER_BASEURL + '/order/status',
  UC_ORDER_RECEIPT: UCENTER_BASEURL + '/order/receipt',
  UC_ORDER_RECEIPT_DEDUCTION: UCENTER_BASEURL + '/order/receiptWithDeduction',

  UC_BILL_LIST: UCENTER_BASEURL + '/bill/list',

  UC_MSGTIME_GET: UCENTER_BASEURL + '/msg/getReadTime',
  UC_MSGTIME_UPDATE: UCENTER_BASEURL + '/msg/update',
  UC_SITER_RELATION: UCENTER_BASEURL + '/siterelation/getSiteRelation',
  UC_SITER_USERLIST: UCENTER_BASEURL + '/siterelation/userList',
  UC_SITER_UPDATE: UCENTER_BASEURL + '/siterelation/update',

  UC_REPORT_CREATE: UCENTER_BASEURL + '/report/create',
  UC_REPORT_UPDATE: UCENTER_BASEURL + '/report/update',
  UC_REPORT_DELETE: UCENTER_BASEURL + '/report/delete',
  UC_REPORT_DETAIL: UCENTER_BASEURL + '/report/details',
  UC_REPORT_LIST: UCENTER_BASEURL + '/report/list',

  UC_ARTICLE_CREATE: UCENTER_BASEURL + '/article/create',
  UC_ARTICLE_UPDATE: UCENTER_BASEURL + '/article/update',
  UC_ARTICLE_DELETE: UCENTER_BASEURL + '/article/delete',
  UC_ARTICLE_DETAIL: UCENTER_BASEURL + '/article/details',
  UC_ARTICLE_LIST: UCENTER_BASEURL + '/article/list',

  UC_PROCESS_QUERY: UCENTER_BASEURL + '/process/query',
  UC_PROCESS_CREATE: UCENTER_BASEURL + '/process/create',
  UC_PROCESS_UPDATE: UCENTER_BASEURL + '/process/update',

  UC_COURSE_LIST: UCENTER_BASEURL + '/course/list',
  UC_COURSE_UPDATE: UCENTER_BASEURL + '/course/update',
  UC_COURSE_DETAIL: UCENTER_BASEURL + '/course/details',

  UC_COURSE_RECORD_LIST: UCENTER_BASEURL + '/courseRecord/list',
  UC_COURSE_RECORD_UPDATE: UCENTER_BASEURL + '/courseRecord/update',
  UC_COURSE_RECORD_DETAIL: UCENTER_BASEURL + '/courseRecord/details',

  UC_CLASS_LIST: UCENTER_BASEURL + '/class/list',
  UC_CLASS_UPDATE: UCENTER_BASEURL + '/class/update',
  UC_CLASS_DETAIL: UCENTER_BASEURL + '/class/details',

  UC_CLASS_RECORD_LIST: UCENTER_BASEURL + '/classRecord/list',
  UC_CLASS_RECORD_CREATE: UCENTER_BASEURL + '/classRecord/create',
  UC_CLASS_RECORD_UPDATE: UCENTER_BASEURL + '/classRecord/update',
  UC_CLASS_RECORD_DETAIL: UCENTER_BASEURL + '/classRecord/details',
  UC_CLASS_RECORD_STAT_BYSTUDENT: UCENTER_BASEURL + '/classRecord/statsByStudent',

  UC_APPO_CREATE: UCENTER_BASEURL + '/appointment/create',
  UC_APPO_UPDATE: UCENTER_BASEURL + '/appointment/update',
  UC_APPO_DELETE: UCENTER_BASEURL + '/appointment/delete',
  UC_APPO_DETAIL: UCENTER_BASEURL + '/appointment/details',
  UC_APPO_LIST: UCENTER_BASEURL + '/appointment/list',

  CMS_SITE_LIST: CMS_BASEURL + '/site/list',
  CMS_SITE_RECOMMEND: CMS_BASEURL + '/site/recommend',
  CMS_SITE_INFO: CMS_BASEURL + '/site/info',
  CMS_SITE_DETAIL: CMS_BASEURL + '/site/detailsBySiteId',
  CMS_SITE_TYPES: CMS_BASEURL + '/site/types',

  // CMS_TASK_LIST: CMS_BASEURL + '/mission/list',
  // CMS_TASK_DETAIL: CMS_BASEURL + '/mission/details',

  CMS_NOTICE_LIST: CMS_BASEURL + '/notice/list',
  CMS_NOTICE_INFO: CMS_BASEURL + '/notice/info',
  CMS_NOTICE_SIBLING: CMS_BASEURL + '/notice/previousAndNext',

  CMS_CATEGORY_LIST: CMS_BASEURL + '/category/list',
  CMS_CATEGORY_INFO: CMS_BASEURL + '/category/info',

  CMS_CONTENT_TYPES: CMS_BASEURL + '/content/types',
  CMS_CONTENT_TOPICS: CMS_BASEURL + '/content/topics',
  CMS_CONTENT_LIST: CMS_BASEURL + '/content/list',

  CMS_CONTENT_DETAIL: CMS_BASEURL + '/content/info',
  CMS_CONTENT_FAVORITE: CMS_BASEURL + '/content/favorite',
  CMS_CART_SYNC: CMS_BASEURL + '/cart/sync',
  CMS_CART_CLEAR: CMS_BASEURL + '/cart/clear',
  CMS_CART_DELETE: CMS_BASEURL + '/cart/delete',
  CMS_COUPON_LIST: CMS_BASEURL + '/coupon/list',
  CMS_COUPON_ACHIEVE: CMS_BASEURL + '/coupon/achieve',
  CMS_ORDER_PLACE: CMS_BASEURL + '/order/place',
  CMS_ORDER_PAY: CMS_BASEURL + '/order/pay',
  CMS_ORDER_LIST: CMS_BASEURL + '/order/list',
  CMS_ORDER_CANCEL: CMS_BASEURL + '/order/cancel',
  CMS_ORDER_FINISH: CMS_BASEURL + '/order/finish',
  CMS_COMMENT_LIST: CMS_BASEURL + '/comment/list',
  CMS_COMMENT_PRAISE: CMS_BASEURL + '/comment/praise',
  CMS_COMMENT_ADD: CMS_BASEURL + '/comment/add',
  CMS_COMMENT_DELETE: CMS_BASEURL + '/comment/delete',
  CMS_CONTENT_RECOMMAND: CMS_BASEURL + '/content/recommend',
  CMS_API_DICT: CMS_BASEURL + '/api/dict',
  CMS_API_JSON: CMS_BASEURL + '/api/getJson',
  CMS_APPVER_LATEST: CMS_BASEURL + '/appver/latest',

  UPMS_CONFIG_LIST: UPMS_BASEURL + '/config/list',

  CMS_FEEDBACK_CREATE: CMS_BASEURL + '/feedback/create',
  CMS_FEEDBACK_DELETE: CMS_BASEURL + '/feedback/delete',
  CMS_FEEDBACK_LISTCUST: CMS_BASEURL + '/feedback/listCustomer',
  CMS_FEEDBACK_UPDATE: CMS_BASEURL + '/feedback/update',
  CMS_FEEDBACK_DETAIL: CMS_BASEURL + '/feedback/details',

  CMS_SURVEY_DETAIL: CMS_BASEURL + '/questions/details',
  CMS_SURVEY_LIST: CMS_BASEURL + '/questions/list',

  CMS_ACTIVITY_LIST: CMS_BASEURL + '/activity/list',
  CMS_ACTIVITY_DETAILS: CMS_BASEURL + '/activity/details',
  CMS_EVENT_RULES: CMS_BASEURL + '/eventRules/list',

  CMS_PRIZE_LIST: CMS_BASEURL + '/prize/list',
  // CMS_TASK_LIST: CMS_BASEURL + '/task/list',
  CMS_QUIZ_LIST: CMS_BASEURL + '/quiz/list',
  CMS_CORRECT_LIST: CMS_BASEURL + '/answerList/question',

  UC_SIGN_CREATE: UCENTER_BASEURL + '/sign/create',
  UC_SIGN_LIST: UCENTER_BASEURL + '/sign/list',

  UC_PRIZE_CREATE: UCENTER_BASEURL + '/prize/create',
  UC_PRIZE_LIST: UCENTER_BASEURL + '/prize/list',

  UC_ENROLL_CREATE: UCENTER_BASEURL + '/enrollment/create',
  UC_ENROLL_UPDATE: UCENTER_BASEURL + '/enrollment/update',
  UC_ENROLL_LIST: UCENTER_BASEURL + '/enrollment/list',
  UC_ENROLL_USER: UCENTER_BASEURL + '/enrollment/user',

  UC_TASK_LIST: UCENTER_BASEURL + '/taskRecord/list',
  UC_TASK_CREATE: UCENTER_BASEURL + '/taskRecord/create',
  UC_TASK_UPDATE: UCENTER_BASEURL + '/taskRecord/update',

  UC_ANSWER_RECORD_LIST: UCENTER_BASEURL + '/questionRecord/list',
  UC_ANSWER_RECORD_CREATE: UCENTER_BASEURL + '/questionRecord/create',

  UC_QUIZ_LIST: UCENTER_BASEURL + '/quizRecord/list',
  UC_QUIZ_CREATE: UCENTER_BASEURL + '/quizRecord/create',

  UC_LINGDAN_LIST: UCENTER_BASEURL + '/mission/list',
  UC_LINGDAN_DELETE: UCENTER_BASEURL + '/mission/delete',
  UC_LINGDAN_DETAIL: UCENTER_BASEURL + '/mission/details',
  UC_LINGDAN_CREATE: UCENTER_BASEURL + '/mission/create',
  UC_LINGDAN_UPDATE: UCENTER_BASEURL + '/mission/update',

  UC_JIAODAN_LIST: UCENTER_BASEURL + '/missionRecord/list',
  UC_JIAODAN_DELETE: UCENTER_BASEURL + '/missionRecord/delete',
  UC_JIAODAN_DETAIL: UCENTER_BASEURL + '/missionRecord/details',
  UC_JIAODAN_CREATE: UCENTER_BASEURL + '/missionRecord/create',
  UC_JIAODAN_UPDATE: UCENTER_BASEURL + '/missionRecord/update',

  UC_EARN_CREATE: UCENTER_BASEURL + '/earn/create',
  UC_EARN_DETAIL: UCENTER_BASEURL + '/earn/details',
  UC_EARN_UPDATE: UCENTER_BASEURL + '/earn/update',

  UC_EARN_REC_LIST: UCENTER_BASEURL + '/earnRecord/list',
  UC_EARN_REC_DETAIL: UCENTER_BASEURL + '/earnRecord/details',
  UC_EARN_REC_WITHDRAW: UCENTER_BASEURL + '/earnRecord/withdraw',

  UC_TEAM_GETTEAM: UCENTER_BASEURL + '/team/getTeam',
  UC_TEAM_GETUSER: UCENTER_BASEURL + '/team/byUser',
  UC_TEAM_CODE: UCENTER_BASEURL + '/team/code',
  UC_TEAM_INVITE: UCENTER_BASEURL + '/team/invite',

  UC_ANSWER_CREATE: UCENTER_BASEURL + '/answer/create',
  UC_ANSWER_LIST: UCENTER_BASEURL + '/answer/list',

  // MNG_ST_USERS_LIST: CMS_MNG_BASEURL16 + '/manage/siterelation/userList',
  // MNG_ST_USERS_DELETE: CMS_MNG_BASEURL16 + '/manage/siterelation/delete',
  // MNG_ST_USERS_UPDATE: CMS_MNG_BASEURL16 + '/manage/siterelation/update',
  // MNG_ST_USERS_DETAIL: CMS_MNG_BASEURL16 + '/manage/siterelation/details',
  // MNG_ST_USERS_CREATE: CMS_MNG_BASEURL16 + '/manage/siterelation/createUser',

  // MNG_ST_RECEIPT_GET: CMS_MNG_BASEURL26 + '/manage/site/getReceiptNoSite',

  // MNG_RECEIPT_LIST: CMS_MNG_BASEURL26 + '/manage/receipt/list',
  // MNG_RECEIPT_UPDATE: CMS_MNG_BASEURL26 + '/manage/receipt/update',

  MNG_USER_UPDATE: CMS_MNG_BASEURL06 + '/manage/user/update',

  CMS_PSQ_LIST: CMS_BASEURL + '/questionnaire/list',
  CMS_PSQ_DETAILS: CMS_BASEURL + '/questionnaire/details',
  CMS_PSQ_DETAILS_RANDOM: CMS_BASEURL + '/questionnaire/getDetailsRandom',

  UC_QUESTION_ANSWER_SUBMIT: UCENTER_BASEURL + '/questionAnswer/submit',
  UC_QUESTION_ANSWER_UPDATE: UCENTER_BASEURL + '/questionAnswer/update',
  UC_QUESTION_ANSWER_LIST: UCENTER_BASEURL + '/questionAnswer/list',
  UC_QUESTION_ANSWER_DETAIL: UCENTER_BASEURL + '/questionAnswer/details',

  UC_QUESTION_ANSWER_PRACTICE_LIST: UCENTER_BASEURL + '/questionAnswer/practiceList',
  UC_QUESTION_ANSWER_PRACTICE_CREATE: UCENTER_BASEURL + '/questionAnswer/createPractice',
  UC_QUESTION_ANSWER_PRACTICE_DETAIL: UCENTER_BASEURL + '/questionAnswer/practiceDetails',
  UC_QUESTION_ANSWER_PRACTICE_DELETE: UCENTER_BASEURL + '/questionAnswer/deletePractice',

  UC_PROPERTY_LIST: UCENTER_BASEURL + '/property/list',
  UC_PROPERTY_DETAIL: UCENTER_BASEURL + '/property/details',
  UC_PROPERTY_CREATE: UCENTER_BASEURL + '/property/create',
  UC_PROPERTY_UPDATE: UCENTER_BASEURL + '/property/update',
  UC_PROPERTY_DELETE: UCENTER_BASEURL + '/property/delete',

  UC_PROPERTY_PROCESS_LIST: UCENTER_BASEURL + '/property/process',
  UC_PROPERTY_PROCESS_CREATE: UCENTER_BASEURL + '/property/createProcess',
  UC_PROPERTY_PROCESS_DELETE: UCENTER_BASEURL + '/property/deleteProcess',

  UC_GOV_REPORT_LIST: UCENTER_BASEURL + '/govReport/list',
  UC_GOV_REPORT_CREATE: UCENTER_BASEURL + '/govReport/create',
  UC_GOV_REPORT_UPDATE: UCENTER_BASEURL + '/govReport/update',
  UC_GOV_REPORT_DELETE: UCENTER_BASEURL + '/govReport/delete',
  UC_GOV_REPORT_DETAIL: UCENTER_BASEURL + '/govReport/details',

  UC_BALANCE_LIST: UCENTER_BASEURL + '/balanceRecord/list',
  UC_BALANCE_CREATE: UCENTER_BASEURL + '/balanceRecord/create',

  UC_BUSI_CARD_LIST: UCENTER_BASEURL + '/businessCard/list',
  UC_BUSI_CARD_DELETE: UCENTER_BASEURL + '/businessCard/delete',
  UC_BUSI_CARD_UPDATE: UCENTER_BASEURL + '/businessCard/update',
  UC_BUSI_CARD_CREATE: UCENTER_BASEURL + '/businessCard/create',
  UC_BUSI_CARD_DETAILS: UCENTER_BASEURL + '/businessCard/details',

  UC_CARD_HOLDER_LIST: UCENTER_BASEURL + '/cardHolder/list',
  UC_CARD_HOLDER_DELETE: UCENTER_BASEURL + '/cardHolder/delete',
  UC_CARD_HOLDER_UPDATE: UCENTER_BASEURL + '/cardHolder/update',
  UC_CARD_HOLDER_CREATE: UCENTER_BASEURL + '/cardHolder/create',
  UC_CARD_HOLDER_DETAIL: UCENTER_BASEURL + '/cardHolder/details',
}

export default apiUrl
