<template>
  <land-section
    :color="backColor"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
      :color="assistColor"
    >
      {{ header.subtitle }}
    </land-section-heading>
    <v-container>
      <v-row justify="center">
        <v-col
          cols="12"
          md="6"
          xl="5"
        >
          <div
            class="d-flex flex-column justify-center"
            style="height: 100%"
          >
            <v-timeline
              dense
              align-top
            >
              <template v-for="(step, stepInd) in steps">
                <v-timeline-item
                  :key="`step_${stepInd}`"
                >
                  <template #icon>
                    <v-avatar
                      :color="assistColor"
                      size="36"
                    >
                      <span class="white--text text-subtitle-1">{{ stepInd + 1 }}</span>
                    </v-avatar>
                  </template>
                  <v-card
                    :color="((stepInd === stepActive) && hovered) ? 'white' : 'grey lighten-3'"
                    :elevation="((stepInd === stepActive) && hovered) ? 3 : 0"
                    @mouseenter="onStepEnter(stepInd)"
                  >
                    <v-card-title class="text-h6">
                      {{ step.text }}
                    </v-card-title>
                  </v-card>
                </v-timeline-item>
              </template>
            </v-timeline>
          </div>
        </v-col>
        <v-col
          v-if="covers.length"
          cols="12"
          md="6"
          xl="5"
        >
          <v-carousel
            v-model="coverActive"
            cycle
            height="460"
            hide-delimiter-background
            :show-arrows="false"
            :hide-delimiters="covers.length < 2"
            light
            @change="changeCarousel"
          >
            <v-carousel-item
              v-for="(cvr, cvrInd) in covers"
              :key="`cover_${cvrInd}`"
            >
              <v-img
                transition="scale-transition"
                contain
                height="100%"
                :src="cvr"
              />
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
      <div
        v-if="button.text"
        class="d-flex flex-row justify-center mt-10"
      >
        <v-btn
          depressed
          :color="button.backColor || 'white'"
          :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text']"
          :width="btnWidth"
          :x-large="btnXLarge"
          @click="onClick"
        >
          {{ button.text }}
        </v-btn>
      </div>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'LandImplementStep',
    props: {
      backColor: {
        type: String,
        default: 'white'
      },
      foreColor: {
        type: String,
        default: 'primary'
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
      ganged: {
        type: Boolean,
        default: false
      },
      hovered: {
        type: Boolean,
        default: false
      },
      steps: {
        type: Array,
        default: () => {
          return []
        }
      },
      covers: {
        type: Array,
        default: () => {
          return []
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        active: 0,
        stepActive: 0,
        coverActive: 0
      }
    },
    computed: {
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return true
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 140
        } else {
          return 180
        }
      },
      fluid () {
        const result = this.$vuetify.breakpoint.smAndDown
        console.log('fluid: %o', result)
        return result
      }
    },
    methods: {
      onClick () {
        this.$emit('action', this.button)
      },
      onStepEnter (stepIndex) {
        this.stepActive = stepIndex
        if (this.ganged) {
          this.coverActive = stepIndex
        }
      },
      changeCarousel (number) {
        this.coverActive = number
        if (this.ganged) {
          this.stepActive = number
        }
      }
    }
  }
</script>
