<template>
  <land-section
    :color="backColor"
    :space="space"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
      :color="assistColor"
      :icon="header.icon"
      :outlined="header.outlined"
    >
      {{ header.subtitle }}
    </land-section-heading>
    <v-container
      :fluid="header.fluid === true ? true : false"
    >
      <land-images-text-guide
        :content="content"
        :cycle="content.cycle"
        :reversed="content.reversed"
        :show-indicator="content.showIndicator"
        :image-width="content.imageWidth"
        :image-height="content.imageHeight"
        @action="onAction"
      />
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'LandIntroImageText',
    props: {
      space: {
        type: [Number, String],
        default: 80,
      },
      backColor: {
        type: String,
        default: 'white'
      },
      foreColor: {
        type: String,
        default: 'primary'
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
      content: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    methods: {
      onAction (res) {
        this.$emit('action', res)
      }
    }
  }
</script>
