<template>
  <land-info-card :title="title">
    <div
      :class="['d-flex', 'flex-column', backColor, 'mt-8']"
    >
      <v-hover
        v-for="(item, index) in items"
        :key="`cate_${index}`"
        v-slot="{ hover }"
      >
        <div
          :class="[
            'px-4 py-3', 'text-subtitle-1',
            'rounded-lg', 'rounded-r-0',
            index === selectedItem ? activeColor : (hover ? hoverColor : 'transparent'),
            index === selectedItem ? 'font-weight-bold' : '',
            index === selectedItem ? '' : ''
          ]"
          @click="onClick(item, index)"
        >
          <a class="black--text darken-4">{{ item.title }}</a>
        </div>
      </v-hover>
    </div>
  </land-info-card>
</template>

<script>
  export default {
    name: 'LandCategories',
    props: {
      title: {
        type: String,
        default: '目录'
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      active: {
        type: [Number, String],
        default: 0
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      activeColor: {
        type: String,
        default: 'white'
      },
      hoverColor: {
        type: String,
        default: 'blue-grey lighten-4'
      }
    },
    data () {
      return {
        selectedItem: 0
      }
    },
    watch: {
      active: {
        handler (newVal) {
          this.selectedItem = newVal
        },
        immediate: true
      }
    },
    methods: {
      onClick (item, index) {
        this.selectedItem = index
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>
