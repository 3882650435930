
import apiHttp from './co.api.http'
import apiComm from './co.api.comm'
import apiUrl from './co.api.url'

const CaptchaTypes = {
  IMAGE: '0',
  SMS: '1',
  EMAIL: '2'
}

const theCaptcha = {
  key: ''
}

const apiCaptcha = {
  Types: CaptchaTypes,
  getKey (imgSrc) {
    if (imgSrc) {
      const index = imgSrc.lastIndexOf('0')
      return imgSrc.slice(index + 1)
    } else {
      return null
    }
  },
  request ({
    scope = 'login',
    type = CaptchaTypes.IMAGE,
    receiver = '',
    key = '',
    executing = null,
    executed = null
  }) {
    const params = {
      type, scope, key, receiver
    }
    const callback = function (status, resp) {
      // console.log('co.captcha request, status: %o, resp: %o', status, resp)
      const obj = resp.data || {}
      const data = {
        ...obj
      }
      const temp = obj.value || ''
      const zeroInd = temp.lastIndexOf('0')
      if (zeroInd !== -1) {
        data.key = temp.slice(zeroInd + 1)
        data.code = temp.slice(0, zeroInd)
      } else {
        data.key = ''
        data.code = ''
      }

      if (typeof executed === 'function') {
        executed({
          status,
          data
        })
      }
    }

    if (typeof executing === 'function') {
      executing()
    }
    apiHttp.get(apiUrl.CAPTCHA_GET, params, callback)
  },
  getCode ({
    scope,
    type,
    receiver,
    executing = null,
    executed = null
  }) {
    const me = this
    if (type !== CaptchaTypes.IMAGE && !theCaptcha.key) {
      // apiComm.showMessage({
      //   content: '获取验证码错误',
      //   type: apiComm.MessageTypes.ERROR
      // })
      return
    }

    theCaptcha.scope = scope
    theCaptcha.type = type
    theCaptcha.receiver = receiver

    const trigger = function (status, resp) {
      // console.log('status: %o, resp: %o', status, resp)
      const data = resp.data || {}
      if (status) {
        if (data.type === CaptchaTypes.IMAGE) {
          theCaptcha.key = me.getKey(data.value)
        } else {
          // apiComm.showMessage({
          //   content: '验证码已发送'
          // })
        }
      } else {
        apiComm.showMessage({
          content: resp.errMsg,
          type: apiComm.MessageTypes.ERROR
        })
      }

      if (typeof executed === 'function') {
        executed({
          status,
          data
        })
      }
    }

    if (typeof executing === 'function') {
      executing()
    }
    apiHttp.get(apiUrl.CAPTCHA_GET, theCaptcha, trigger)
  },
  getCaptcha () {
    return theCaptcha
  }
}

export default apiCaptcha
