<template>
  <div
    :class="['d-flex', 'flex-column', margin, padding, backColor]"
  >
    <div
      class="d-flex flex-row align-center mb-3"
    >
      <span :class="[size, color]">
        {{ title }}
      </span>
      <v-spacer />
      <v-btn
        v-for="(item, index) in items"
        :key="`nav_${index}`"
        icon
        @click="onClick(item, index)"
      >
        <v-icon>{{ item.icon }}</v-icon>
      </v-btn>
    </div>
    <v-divider v-if="divided" />
  </div>
</template>

<script>
  export default {
    name: 'LandNavigation',
    props: {
      title: {
        type: String,
        default: '标题名称'
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      dark: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: ''
      },
      size: {
        type: String,
        default: 'text-lg-h6'
      },
      dense: {
        type: Boolean,
        default: false
      },
      flat: {
        type: Boolean,
        default: true
      },
      divided: {
        type: Boolean,
        default: true
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
    },
    data () {
      return {}
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>

<style lang="sass" scoped>
</style>
