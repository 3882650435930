<template>
  <v-img
    :height="wdHeight"
    width="100%"
    :src="content.cover"
  >
    <div
      :class="[
        'd-flex', 'flex-column', 'justify-space-around', 'px-4 px-md-16', 'py-6 py-md-14',
        classify.align ? `align-${classify.align}` : 'align-center'
      ]"
      style="height: 100%"
    >
      <div
        :class="[
          'd-flex', 'flex-column',
          classify.align ? `align-${classify.align}` : 'align-center'
        ]"
      >
        <div
          v-if="content.title"
          :class="['hidden-sm-and-down', 'text-h4', 'text-sm-h3', 'text-md-h2', classify.color ? `${classify.color}--text` : 'white--text']"
          v-text="content.title"
        />

        <div
          v-if="content.subtitle"
          :class="['text-h6', 'text-md-h5', 'mt-6', classify.color ? `${classify.color}--text` : 'white--text']"
          style="font-weight: 400;"
          v-html="content.subtitle"
        />
      </div>

      <div
        v-if="!smAndDown && content.features && content.features.length"
        class="d-flex flex-row justify-between align-center"
      >
        <template v-for="(feature, index) in content.features">
          <div
            :key="`feature_${index}`"
            class="d-flex align-center"
          >
            <v-icon
              v-if="feature.icon"
              :color="feature.iconColor || 'white'"
              class="mr-2"
              size="32"
            >
              {{ feature.icon }}
            </v-icon>

            <div
              v-if="feature.text"
              :class="['text-subtitle-1', 'text-md-h5', classify.color ? `${classify.color}--text` : 'white--text']"
              style="font-weight: 400;"
              v-text="feature.text"
            />
          </div>
          <div
            v-if="index < (content.features.length - 1)"
            :key="`spacer_${index}`"
            class="pa-2 pa-md-4"
          />
        </template>
      </div>

      <div
        :class="[
          'd-flex', 'flex-column',
          classify.align ? `align-${classify.align}` : 'align-center'
        ]"
      >
        <v-btn
          v-if="button.text"
          depressed
          :color="button.backColor || 'white'"
          :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text']"
          :width="btnWidth"
          :x-large="btnXLarge"
          @click="onClick"
        >
          {{ button.text }}
        </v-btn>

        <div
          v-if="content.contact"
          :class="['text-h6', 'mt-3', classify.color ? `${classify.color}--text` : 'white--text']"
          v-text="content.contact"
        />
      </div>
    </div>
  </v-img>
</template>

<script>
  export default {
    name: 'LandSummary',
    props: {
      classify: {
        type: Object,
        default: () => {
          return {}
        }
      },
      content: {
        type: Object,
        default: () => {
          return {}
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    data () {
      return {
        inPoints: {
          xs: 200,
          sm: 300,
          md: 500,
          lg: 650,
          xl: 650
        },
        inHeight: 500
      }
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const pts = this.classify.points || {}
        const ht = pts[name] || this.inPoints[name]
        return ht
      },
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return true
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 140
        } else {
          return 180
        }
      },
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      }
    },
    methods: {
      onClick (ev) {
        const params = {
          content: this.content
        }

        this.$emit('click', params)
      }
    }
  }
</script>
