/**
 * 用于web端各个页面banner
 * 当前仅在web端使用
*/

import api from '@/api/co.api'
import cache from '@/api/kind/co.kind.banner.cache'
import topic from '@/api/kind/co.kind.topic'

const Genres = {
  normal: {
    height: 360,
    points: {
      xs: 230,
      sm: 230,
      md: 360,
      lg: 360,
      xl: 360
    },
    cycle: true,
    interval: 9000,
    hideDelimiters: false,
    showArrows: true
  },
  large: {
    height: 500,
    points: {
      xs: 160,
      sm: 300,
      md: 500,
      lg: 650,
      xl: 650
    },
    cycle: true,
    interval: 9000,
    hideDelimiters: false,
    showArrows: true
  }
}

const BannerTypes = {
  // home 表示首页
  home: 'show-tp-home',
  // notice 表示新闻、资讯
  notice: 'show-tp-notice',
  // content 表示商品
  content: 'show-tp-content',
  // about 表示关于我们
  about: 'show-tp-about',
  // contact 表示联系方式
  contact: 'show-tp-contact',
  // service 表示服务、营销、市场
  service: 'show-tp-service',
  // case 表示案例
  case: 'show-tp-case',
  // material 资料、政策等
  material: 'show-tp-material',
  // party 党建
  party: 'show-tp-party',
  // park 园区
  park: 'show-tp-park',
  extend1: 'show-tp-extend1'
}

const kindBanner = {
  Types: BannerTypes,
  Genres,
  initialize ({
    siteId,
    type = '',
    model = '',
    target = ''
  }) {
    cache.extract()
    this.getTopics({
      siteId,
      type,
      model,
      target
    }).then((res) => {
      console.info('co.kind.banner getTopics is ok!!!')
    })
  },
  getTopics ({
    siteId = '',
    type = '',
    model = '',
    target = ''
  }) {
    const prom = new Promise((resolve, reject) => {
      const executed = function (ev) {
        if (ev.status) {
          for (const i in cache.items) {
            const item = cache.items[i]
            cache.data[item.type] = {
              topicId: item.topicId,
              siteId: item.siteId,
              name: item.name,
              type: item.type,
              target: item.target,
              model: item.model,
              cover: item.cover
            }
          }
          cache.store(cache.data)
          resolve({ status: ev.status, data: cache.data })
        } else {
          reject(new Error('获取主题失败'))
        }
      } // const executed = function (ev)

      cache.params = topic.getParams({ siteId, type, model, target, executed })
      cache.items = cache.params.items
      cache.params.reload = true

      api.http.getItems(cache.params)
    })
    return prom
  },
  getTopic (type) {
    // console.log('co.config getConfig, key: %o', key)
    let result = null
    try {
      result = cache.data[type]
      if (!result) {
        result = cache.cache[type] || {}
      } else {
        const keys = Object.keys(result)
        if (keys.length < 1) {
          result = cache.cache[type] || {}
        }
      }
    } catch (error) {
      result = {}
      console.warn('co.kind.topic getTopic, error: %o', error)
    }

    return result
  },
}

export default kindBanner
