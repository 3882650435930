
import app from '@/api/co.app'
import Cookies from 'js-cookie'

const libCookie = {
  /**
   * @description 存储 cookie 值
   * @param {String} name cookie name
   * @param {String} value cookie value
   * @param {Object} setting cookie setting
   */
  set (name = 'default', value = '', cookieSetting = {}) {
    const currentCookieSetting = {
      expires: 1
    }
    Object.assign(currentCookieSetting, cookieSetting)
    Cookies.set(`${app.init.name}-${name}`, value, currentCookieSetting)
  },

  /**
   * @description 拿到 cookie 值
   * @param {String} name cookie name
   */
  get (name = 'default') {
    return Cookies.get(`${app.init.name}-${name}`)
  },

  /**
   * @description 拿到 cookie 全部的值
   */
  getAll () {
    return Cookies.get()
  },

  /**
   * @description 删除 cookie
   * @param {String} name cookie name
   */
  remove (name = 'default') {
    return Cookies.remove(`${app.init.name}-${name}`)
  }

}

export default libCookie
