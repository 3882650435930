var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:[_vm.margin, _vm.padding, _vm.backColor]},_vm._l((_vm.items),function(item,i){return _c('v-col',{key:("notice_" + i),staticClass:"px-0 py-0",attrs:{"cols":"12"}},[_c('v-hover',{attrs:{"disabled":!_vm.hovered,"close-delay":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:[_vm.round, 'transition-swing', 'co-overflow-hidden', _vm.dense ? '' : 'mb-4'],attrs:{"elevation":_vm.dense ? 0 : (hover ? _vm.hoverElevation : _vm.normalElevation),"color":_vm.cardColor,"flat":"","tile":""}},[_c('div',{class:['d-flex', 'flex-row', 'align-center', 'co-w-full',
                   _vm.dense ? 'pr-4' : 'px-4',
                   _vm.dense ? 'py-1' : 'py-3'
          ]},[(_vm.indicator)?_c('v-icon',{staticClass:"pr-3",attrs:{"small":_vm.indicatorSmall,"color":hover ? 'primary' : 'blue-grey lighten-2'}},[_vm._v(" "+_vm._s(_vm.indicator)+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('a',_vm._g(_vm._b({class:['text-subtitle-1', 'd-inline-block', 'text-truncate',
                         hover ? 'primary--text' : 'black--text darken-4'
                ],staticStyle:{"flex":"1"},on:{"click":function($event){return _vm.onClick(item, i)}}},'a',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.title))])]),_c('span',{staticClass:"text-body-2 blue-grey--text lighten-2 co-shrink0 pl-9"},[_vm._v(" "+_vm._s(_vm.shortDated ? item.shortDate : item.createDate)+" ")])],1)])]}}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }