
import Vue from 'vue'

import base from '@/api/app/co.app.base'
// import event from '@/api/app/co.app.event'
import init from '@/api/app/co.app.init'
import keys from '@/api/app/co.app.keys'
import style from '@/api/app/co.app.style'

const coApp = {
  bus: new Vue(),
  base,
  init,
  keys,
  style,
  initialize () {
  },
  emit (event, param = {}) {
    if (coApp.bus) {
      coApp.bus.$emit(event, param)
    } else {
      console.log('#co.bus emit bus is null!')
    }
  },
  on (event, func = null) {
    if (coApp.bus) {
      coApp.bus.$on(event, func)
    }
  },
  off (event) {
    if (coApp.bus) {
      coApp.bus.$off(event)
    }
  }
}

export default coApp
