<template>
  <v-speed-dial
    v-model="fab"
    :open-on-hover="hovered"
    fixed
    right
    bottom
    class="mb-8 mr-4"
  >
    <template #activator>
      <v-btn
        v-model="fab"
        :color="activator.color || 'blue darken-2'"
        dark
        fab
      >
        <v-icon v-if="fab">
          {{ activator.active || defActive }}
        </v-icon>
        <v-icon v-else>
          {{ activator.normal || defNormal }}
        </v-icon>
      </v-btn>
    </template>
    <v-btn
      v-for="(item, index) in items"
      :key="`btn_${index}`"
      fab
      dark
      small
      :color="item.color"
      @click="onClick(item)"
    >
      <v-img
        v-if="item.image"
        :src="item.image"
        contain
        height="22"
        width="22"
      />
      <v-icon v-else>
        {{ item.icon }}
      </v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
  export default {
    name: 'LandSpeedDial',
    props: {
      hovered: {
        type: Boolean,
        default: false
      },
      transition: {
        type: String,
        default: ''
      },
      activator: {
        type: Object,
        default: () => {
          return {}
        }
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        fab: false,
        defNormal: 'mdi-dots-vertical',
        defActive: 'mdi-dots-horizontal'
      }
    },
    methods: {
      onClick (res) {
        this.$emit('click', res)
      }
    }
  }
</script>
