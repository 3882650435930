<template>
  <div
    class="d-flex flex-column"
  >
    <div
      v-if="previous.noticeId"
      class="d-flex flex-row mt-6"
    >
      <span class="co-shrink0 text-subtitle-1 grey--text lighten-3 pr-4">上一篇:</span>
      <a
        class="text-subtitle-1"
        @click="onClick(part, previous)"
      >
        {{ previous.title }}
      </a>
    </div>
    <div
      v-if="next.noticeId"
      class="d-flex flex-row mt-6"
    >
      <span class="co-shrink0 text-subtitle-1 grey--text lighten-3 pr-4">下一篇:</span>
      <a
        class="text-subtitle-1"
        @click="onClick(part, next)"
      >
        {{ next.title }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LandNoticePreviousNext',
    props: {
      previous: {
        type: Object,
        default: () => {
          return {}
        }
      },
      next: {
        type: Object,
        default: () => {
          return {}
        }
      },
      part: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      onClick (part, notice) {
        this.$emit('click', {
          part,
          notice
        })
      }
    }
  }
</script>
