<template>
  <v-theme-provider :dark="dark">
    <v-container class="pa-0">
      <v-row
        :justify="justify"
      >
        <v-col
          v-if="icon"
          :class="`text-${align}`"
          cols="12"
          class="mb-4"
        >
          <land-icon
            :color="wdIconColor"
          >
            {{ icon }}
          </land-icon>
        </v-col>

        <v-col
          v-if="title || subtitle"
          :cols="callout ? 9 : 12"
        >
          <land-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="1"
          />

          <land-title
            :title="title"
            class="text-uppercase"
            space="1"
          />

          <land-divider :color="color" />

          <land-body
            v-if="text || $slots.default"
            :text="text"
            space="6"
          >
            <slot />
          </land-body>
        </v-col>

        <v-col
          v-if="callout"
          cols="2"
          class="px-0"
        >
          <div
            class="text-h2 grey--text text--lighten-4 font-weight-bold pr-8"
            v-text="callout"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-theme-provider>
</template>

<script>
  // Mixins
  import Heading from '@/mixins/heading'

  export default {
    name: 'LandInfoCard',
    mixins: [Heading],
    props: {
      dark: Boolean,
      callout: String,
      color: {
        type: String,
        default: 'primary',
      },
      iconColor: {
        type: String,
        default: 'primary'
      },
      icon: String,
      subtitle: String,
      text: String,
      title: String
    },
    computed: {
      wdIconColor () {
        if (this.dark) {
          return this.iconColor || 'white'
        } else {
          return this.iconColor || 'primary'
        }
      }
    }
  }
</script>
