<template>
  <div
    v-if="visible"
    class="co-alert"
  >
    <v-alert
      :value="showed"
      :border="border"
      :color="color"
      :icon="icon || icons[type]"
      :type="type"
      transition="scale-transition"
      max-width="700"
      min-width="420"
    >
      <v-row align="center">
        <v-col class="grow pa-2">
          {{ content }}
        </v-col>
        <v-col
          v-if="closed"
          class="shrink pa-0 pl-2"
        >
          <v-btn
            icon
            color="white"
            @click="onClose"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
  export default {
    name: 'LandAlert',
    data () {
      return {
        timer: null,
        icons: {
          info: 'mdi-information-outline',
          success: 'mdi-check-circle-outline',
          warning: 'mdi-alert-outline',
          error: 'mdi-close-circle-outline'
        },
        color: undefined,
        icon: '',
        type: 'info',
        closed: false,
        visible: false,
        showed: false,
        content: '一个提示信息一个提示信息一个提示信息一个提示信息',
        border: 'left',
        duration: 4000
      }
    },
    methods: {
      onClose () {
        this.hide()
      },
      hide () {
        this.showed = false
        setTimeout(() => {
          this.visible = false
        }, 1600)
      },
      show ({
        type = 'info',
        content = '',
        duration = 4000,
        closed = false,
        icon = ''
      }) {
        this.type = type
        this.content = content
        this.duration = parseInt(duration, 10) || 0
        this.closed = closed
        this.icon = icon

        this.visible = true
        setTimeout(() => {
          this.showed = true
        }, 200)

        if (this.duration > 0) {
          this.timer = setTimeout(() => {
            this.hide()
          }, this.duration)
        }
      },
    }
  }
</script>

<style scoped>
  .co-alert {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    z-index: 2000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 16px 32px;
  }
</style>
