
import local from '@/api/lib/co.lib.local'
import cookie from '@/api/lib/co.lib.cookie'

const coLib = {
  local,
  cookie
}

export default coLib
