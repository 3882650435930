
import api from '@/api/co.api'
import comm from '@/api/activity/co.activity.comm'
import dayjs from 'dayjs'

const activityMain = {
  getParams ({
    siteId,
    type = '',
    status = '',
    sort = 'updateTime',
    order = 'desc',
    limit = '10',
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.CMS_ACTIVITY_LIST,
      data: {
        siteId,
        type,
        offset: '0',
        limit,
        status,
        sort,
        order
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: `${api.url.CMS_ACTIVITY_DETAILS}/${id}`,
      executing,
      executed,
      transform: transform || this.convertItem
    })

    api.http.getDetail(params)
  },
  parseAccessory (srcItem) {
    const accessory = []
    const items = api.comm.jsonToArray(srcItem.annex)
    for (const i in items) {
      const item = items[i]
      const obj = api.comm.parsePath(item.url)
      const dst = {
        name: item.name,
        url: item.url,
        size: item.size,
        type: obj.type,
        suffix: obj.suffix,
        dir: obj.dir,
        icon: obj.icon,
        color: obj.color,
        viewable: obj.viewable
      }

      accessory.push(dst)
    }

    return accessory
  },
  convertContent (srcItem) {
    let content = ''
    const arrItems = []

    if (srcItem.model === 'shop' && srcItem.content) {
      try {
        const details = JSON.parse(srcItem.content)
        for (const i in details) {
          const item = details[i]
          if (item.type === 'text') {
            arrItems.push({
              name: 'div',
              attrs: {
                class: comm.textClass,
                style: comm.textStyle
              },
              children: [{
                type: 'text',
                text: item.value
              }]
            })
          } else if (item.type === 'image') {
            arrItems.push({
              name: 'img',
              attrs: {
                class: comm.imageClass,
                style: comm.imageStyle,
                src: item.value
              }
            })
          }
        }

        content = arrItems
      } catch (err) {
        console.warn('land.acti convertContent is error! err: %o', err)
        console.warn('land.acti convertContent is error! content: %o', srcItem.content)
      }
    } else {
      if (srcItem.content) {
        content = srcItem.content.replace(/<img/gi, '<img style="max-width:100%;height:auto;"')
      }
    }
    return content
  },
  convertItem (srcItem, parseContent = true) {
    const nowTime = dayjs().format('YYYY-MM-DD')
    const type = srcItem.type || comm.Types.ACTIVITY_REGISTER
    const actLabels = comm.getLabels(type)

    const startTime = dayjs(srcItem.startTime).format('YYYY-MM-DD')
    const endTime = dayjs(srcItem.endTime).format('YYYY-MM-DD')

    let status = comm.States.NOTSTART
    if (nowTime < startTime) {
      status = comm.States.NOTSTART
    } else {
      if (nowTime > endTime) {
        status = comm.States.END
      } else {
        status = comm.States.PROCESSING
      }
    }

    const timelimit = `${actLabels.hintTitle} ${startTime} ~ ${endTime}`
    const buttonText = actLabels.buttonTexts[status]
    const buttonDisabled = actLabels.buttonDisableds[status]
    let content = ''
    if (parseContent) {
      content = activityMain.convertContent(srcItem)
    }

    const remark = api.comm.jsonToObject(srcItem.remark)

    const accessory = activityMain.parseAccessory(srcItem)

    const dstItem = {
      title: srcItem.title,
      subtitle: srcItem.subtitle || '',
      timelimit,
      cover: srcItem.cover,
      activityId: srcItem.activityId,
      siteId: srcItem.siteId,
      hits: srcItem.hits,
      praise: srcItem.praise,
      collect: srcItem.collect,
      startTime,
      endTime,
      status,
      buttonText,
      buttonDisabled,
      type,
      model: srcItem.model || 'shop',
      content,
      link: srcItem.link,
      updateTime: srcItem.updateTime,
      remark,
      assist: remark.assist || [],
      memo: remark.memo || '',
      accessory
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = activityMain.convertItem(item, false)
      params.items.push(dstItem)
    })

    return params.items.length
  }
}

export default activityMain
