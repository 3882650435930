import api from '@/api/co.api.js'

const surveyQuestion = {
  getParams ({
    id,
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: `${api.url.CMS_PSQ_DETAILS}/${id}`,
      data: {
        offset: 0,
        limit: 50
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  getParamsRandom ({ type = '', limit = '10', caches = [], executing = null, executed = null, transform = null }) {
    const params = api.comm.createParams({
      url: api.url.CMS_PSQ_DETAILS_RANDOM,
      data: { type, limit },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  convertItem (srcItem) {
    const options = api.comm.jsonToArray(srcItem.options)
    const config = api.comm.jsonToObject(srcItem.config)
    const analysis = api.comm.jsonToObject(srcItem.answerAnalysis)

    const dstItem = {
      questionId: srcItem.questionId,
      questionnaireId: srcItem.questionnaireId,
      sortNum: srcItem.sortNum,
      title: srcItem.title,
      description: srcItem.description || '',
      type: srcItem.type,
      score: srcItem.score,
      options,
      config,
      answer: srcItem.answer || '',
      analysis,
      createTime: srcItem.createTime
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = surveyQuestion.convertItem(item, false)
      params.items.push(dstItem)
    })

    return params.items.length
  }
}

export default surveyQuestion
