
/**
 * coSurvey 表示问卷服务接口
 *
 * json配置文件存放目录
 * /home/ld-association/web/json
 */

import api from '@/api/co.api.js'
import app from '@/api/co.app.js'

import comm from '@/api/survey/co.survey.comm.js'
import question from '@/api/survey/co.survey.question.js'
import answer from '@/api/survey/co.survey.answer.js'
import main from '@/api/survey/co.survey.main.js'
import random from '@/api/survey/co.survey.random.js'
import practice from '@/api/survey/co.survey.practice.js'

const CFG_FILE_NAME = `land.${app.base.name}.survey.json`

const coSurvey = {
  comm,
  question,
  answer,
  main,
  random,
  practice,
  initialize () {
    comm.extract()

    const executed = function (res) {
      if (res.status) {
        comm.reset(res.data)
      }
    }

    const params = {
      url: api.url.CMS_API_JSON,
      data: {
        fileName: CFG_FILE_NAME,
        fileType: 'json',
        dirName: 'json'
      },
      executed
    }

    api.http.getJson(params)
  },
}

export default coSurvey
