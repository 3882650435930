
import api from '@/api/co.api.js'
import surveyComm from './co.survey.comm.js'

const surveyRandom = {
  getParams ({
    type = '',
    limit = 10,
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.CMS_PSQ_DETAILS_RANDOM,
      data: {
        type,
        limit,
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  convertItem (srcItem, index) {
    const options = api.comm.jsonToArray(srcItem.options)
    const config = api.comm.jsonToObject(srcItem.config)
    const answer = srcItem.answer || ''

    const optList = []
    for (const i in options) {
      const src = options[i]
      const dst = {
        ...src,
        selected: false,
        state: surveyComm.QuestionStates.normal
      }
      optList.push(dst)
    }

    const dstItem = {
      index,
      questionId: srcItem.questionId,
      siteId: srcItem.siteId,
      title: srcItem.title,
      type: srcItem.type,
      score: srcItem.score,
      options: optList,
      config,
      answer,
      readonly: false,
      correctFlag: 0
    }
    return dstItem
  },
  convertItems (params, srcItems) {
    let index = 1
    srcItems.forEach(item => {
      const dstItem = surveyRandom.convertItem(item, index)
      params.items.push(dstItem)
      index += 1
    })

    return params.items.length
  }
}

export default surveyRandom
