
import api from '@/api/co.api'

const kindTopic = {
  getParams ({
    siteId = '',
    model = '',
    type = '',
    target = '',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.CMS_CONTENT_TOPICS,
      data: {
        siteId,
        model,
        type,
        target,
        search: '',
        offset: '0',
        limit: '20',
        sort: 'sort',
        order: 'asc'
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  convertItem (srcItem) {
    const link = api.comm.jsonToObject(srcItem.url)
    const pattern = api.comm.jsonToObject(srcItem.description)

    const dstItem = {
      ...srcItem,
      index: '',
      pattern,
      link,
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = kindTopic.convertItem(item)
      params.items.push(dstItem)
    })

    return params.items.length
  },
}

export default kindTopic
