<template>
  <v-row>
    <v-col
      cols="3"
      class="text-center hidden-sm-and-down"
    >
      <template v-for="(avat, i) in avatars">
        <land-avatar-card
          :key="`avatar_${i}`"
          align="center"
          v-bind="avat"
        />

        <v-divider
          v-if="i + 1 !== avatars.length"
          :key="`divider_${i}`"
          class="my-8"
        />
      </template>
    </v-col>

    <v-col
      cols="2"
      class="hidden-sm-and-down text-center"
    >
      <v-responsive
        height="calc(100% - 16px)"
        class="mt-2"
      >
        <v-divider vertical />
      </v-responsive>
    </v-col>

    <v-col
      cols="12"
      md="7"
      align-self="center"
    >
      <land-subheading
        v-if="intro.title"
        size="display-1"
        :title="intro.title"
        space="2"
      />

      <land-title
        v-if="intro.subtitle"
        class="primary--text"
        :title="intro.subtitle"
        tag="div"
      />

      <land-body
        v-if="intro.text"
      >
        <span v-html="intro.text" />
      </land-body>

      <v-btn
        v-if="intro.buttonText"
        depressed
        color="primary"
        min-width="164"
        class="mb-12"
        large
      >
        {{ intro.buttonText }}
      </v-btn>

      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="`feature_${i}`"
          cols="12"
          md="6"
        >
          <land-list-item :text="feature" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LandMarking',
    props: {
      avatars: {
        type: Array,
        default: () => {
          return []
        }
      },
      intro: {
        type: Object,
        default: () => {
          return {}
        }
      },
      features: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data () {
      return {}
    },
    methods: {
      onButtonClick () {
        this.$emit('click', this.intro)
      }
    }
  }
</script>
