<template>
  <v-theme-provider :dark="dark">
    <div class="px-4">
      <land-info-card
        title="联系方式"
        pace="6"
        :color="assistColor"
      >
        {{ subtitle }}
      </land-info-card>

      <land-business-contact
        dense
        :dark="dark"
      />
    </div>
  </v-theme-provider>
</template>

<script>
  import config from '@/api/co.config'
  const SiteKeys = config.comm.siteKeys

  export default {
    name: 'LandBusinessInfo',
    props: {
      dark: Boolean,
      assistColor: {
        type: String,
        default: 'primary'
      },
    },
    data () {
      return {
        subtitle: ''
      }
    },
    created () {
      const param = config.getSiteParam(SiteKeys.subtitle)
      this.subtitle = param.value || ''
    },
  }
</script>
