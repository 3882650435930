/**
 * co.kind.category 对应cms_category
 * 定义了content、notice 内容分类
*/
import api from '@/api/co.api'

/**
 * Purposes 定义了model
 * 当前定义应用于资料创建
 */
// const Purposes = {
//   category: 'category',
//   party: 'party',
//   material: 'material',
//   bookParty: 'bookParty',
//   bookMaterial: 'bookMaterial'
// }

// const TargetTypes = {
//   // 列表
//   LIST: 'list',
//   // 分组 与detail 同一级别
//   GROUP: 'group',
//   // 详情
//   DETAIL: 'detail'
// }

// const TargetTypesDescs = {
//   [TargetTypes.LIST]: {
//     text: '目录',
//     value: TargetTypes.LIST,
//     style: api.comm.StateStyles.BLUE_PLAIN
//   },
//   [TargetTypes.GROUP]: {
//     text: '分组',
//     value: TargetTypes.GROUP,
//     style: api.comm.StateStyles.GRAY_PLAIN
//   },
//   [TargetTypes.DETAIL]: {
//     text: '详情',
//     value: TargetTypes.DETAIL,
//     style: api.comm.StateStyles.GREEN_PLAIN
//   }
// }

// const ModelTypes = {
//   // 商品
//   SHOP: 'shop',
//   // 文章
//   ARTICLE: 'article',
//   // 党建
//   PARTY: 'party',
//   // 资料库
//   MATERIAL: 'material'
// }

// const ModelTypesDescs = {
//   [ModelTypes.SHOP]: {
//     text: '商品',
//     value: ModelTypes.SHOP,
//     style: api.comm.StateStyles.RED
//   },
//   [ModelTypes.ARTICLE]: {
//     text: '文章',
//     value: ModelTypes.ARTICLE,
//     style: api.comm.StateStyles.TEAL
//   },
//   [ModelTypes.PARTY]: {
//     text: '党建',
//     value: ModelTypes.PARTY,
//     style: api.comm.StateStyles.BLUE
//   },
//   [ModelTypes.MATERIAL]: {
//     text: '资料',
//     value: ModelTypes.MATERIAL,
//     style: api.comm.StateStyles.GREEN
//   }
// }

const coKindCategory = {
  // TargetTypes,
  // TargetTypesDescs,
  // ModelTypes,
  // ModelTypesDescs,
  // Purposes,
  // getTargetTypeDesc (target) {
  //   const result = TargetTypesDescs[target] || {
  //     text: '默认',
  //     value: target,
  //     style: api.comm.StateStyles.GRAY
  //   }

  //   return result
  // },
  // getTargetTypeDescs (hasAll = false, excludes = []) {
  //   const items = []
  //   const includes = []
  //   for (const key in TargetTypesDescs) {
  //     if (!excludes.includes(key)) {
  //       includes.push(key)
  //       items.push({
  //         ...TargetTypesDescs[key]
  //       })
  //     }
  //   }

  //   if (hasAll && includes.length !== 1) {
  //     items.unshift({
  //       text: '所有类型',
  //       value: includes.join('|') || ''
  //     })
  //   }

  //   return items
  // },
  // getModelTypeDescs (hasAll = false, excludes = []) {
  //   const items = []
  //   const includes = []
  //   for (const key in ModelTypesDescs) {
  //     if (!excludes.includes(key)) {
  //       includes.push(key)
  //       items.push({
  //         ...ModelTypesDescs[key]
  //       })
  //     }
  //   }

  //   if (hasAll && includes.length !== 1) {
  //     items.unshift({
  //       text: '所有模式',
  //       value: includes.join('|') || ''
  //     })
  //   }

  //   return items
  // },
  getParams ({
    siteId = '',
    model = '',
    type = '',
    target = '',
    parentId = '0',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.CMS_CATEGORY_LIST,
      data: {
        siteId,
        model,
        type,
        parentId,
        target,
        search: '',
        offset: '0',
        limit: '20',
        sort: 'sort',
        order: 'asc'
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.CMS_CATEGORY_INFO}`,
      data: {
        id
      },
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.http.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.http.delete({
      url: api.url.MNG_CATEGORY_DELETE,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.http.update({
      url: api.url.MNG_CATEGORY_UPDATE,
      id,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    // const modelDesc = api.comm.getModelDesc(srcItem.model)
    // const typeDesc = api.comm.getKindTypeDescription(srcItem.type)

    // let targetDesc = {}
    // const target = srcItem.target
    // if (srcItem.type === api.comm.KindStyles.CATALOG) {
    //   targetDesc = this.getTargetTypeDesc(target)
    // } else {
    //   targetDesc = api.comm.getContentTypeDescription(target)
    // }

    let link = {}
    if (srcItem.href) {
      try {
        link = JSON.parse(srcItem.href)
      } catch (err) {
        console.warn('href json转换异常：' + srcItem.href)
      }
    }

    let pattern = {}
    if (srcItem.description) {
      try {
        pattern = JSON.parse(srcItem.description)
      } catch (err) {
        console.warn('description json转换异常：%o' + srcItem.description)
      }
    }

    const dstItem = {
      ...srcItem,
      index: '',
      pattern,
      // typeDesc,
      // modelDesc,
      // targetDesc,
      link,
      sort: parseInt(srcItem.sort, 10) || 1,
      hasChildren: false,
      children: [],
      parent: null
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = coKindCategory.convertItem(item)
      params.items.push(dstItem)
    })

    return params.items.length
  },
}

export default coKindCategory
