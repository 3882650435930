
import base from '@/api/app/co.app.base'

const appInit = {
  name: base.name,
  title: base.title,
  version: base.version,

  // 站点Id
  siteId: '20006',
  // 积分商城Id
  emallId: '',

  siteAll: false,

  // token超时偏移量 毫秒
  expireOffset: 1000,
  autoLogin: 360000000,

  defaultAvatar: `${base.res_skin}/assist/def-avatar.jpeg`,
  copyright: '南京智能制造产业园建设发展有限公司',
  beianId: '苏ICP备18002521号-1',
  authTime: '@2024年 ~ 2025年',

  reset (data) {
    const me = appInit
    if (data) {
      console.log('#co.app.init reset, data is null!')
      return
    }
    const obj = data.init || {}
    for (const key in obj) {
      me[key] = obj[key] || ''
    }
  }
}

export default appInit
