<template>
  <v-carousel
    cycle
    :interval="interval"
    :height="wdHeight"
    :hide-delimiters="hideDelimiters"
    :show-arrows="showArrows"
    show-arrows-on-hover
    hide-delimiter-background
  >
    <v-carousel-item
      v-for="(item, index) in items"
      :key="index"
    >
      <v-img
        transition="scale-transition"
        height="100%"
        :src="item.cover"
        @click="onClick(item, index)"
      />
    </v-carousel-item>
  </v-carousel>
</template>

<script>
  export default {
    name: 'LandBanner',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      height: {
        type: [Number, String],
        default: 500
      },
      points: {
        type: Object,
        default: () => {
          return {
            xs: 200,
            sm: 300,
            md: 500,
            lg: 650,
            xl: 800
          }
        }
      },
      cycle: {
        type: Boolean,
        default: true
      },
      interval: {
        type: Number,
        default: 9000
      },
      hideDelimiters: {
        type: Boolean,
        default: false
      },
      showArrows: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    },
    computed: {
      wdHeight () {
        const name = this.$vuetify.breakpoint.name
        const ht = this.points[name] || this.height
        return ht
      },
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }

        this.$emit('click', params)
      }
    }
  }
</script>

<style>
</style>
