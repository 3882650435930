<template>
  <div class="px-4">
    <land-info-card
      :title="title"
      space="4"
      :color="color"
    />
    <v-form
      ref="form"
      lazy-validation
    >
      <v-text-field
        v-model="txtName"
        label="姓名"
        outlined
        dense
        required
        :rules="nameRules"
      />

      <v-text-field
        v-model="txtPhone"
        label="手机号码"
        outlined
        dense
        required
        :rules="phoneRules"
      />

      <v-text-field
        v-model="txtTitle"
        label="标题"
        outlined
        dense
        required
        :rules="titleRules"
      />

      <v-textarea
        v-model="txtMemo"
        label="你的需求或留言"
        outlined
        dense
        rows="5"
      />

      <v-btn
        depressed
        :loading="submiting"
        :color="color"
        class="mt-2"
        min-width="164"
        large
        @click="onSubmit"
      >
        {{ buttonText }}
      </v-btn>
    </v-form>
  </div>
</template>

<script>
  export default {
    name: 'LandContactForm',
    // Injected from the Vuetify Themeable mixin
    inject: ['theme'],
    props: {
      subtitle: String,
      title: {
        type: String,
        default: '给我们留言',
      },
      buttonText: {
        type: String,
        default: '提交留言',
      },
      submiting: {
        type: Boolean,
        default: false
      },
      color: {
        type: String,
        default: 'primary',
      },
    },
    data () {
      return {
        txtName: '',
        txtPhone: '',
        txtTitle: '',
        txtMemo: '',
        nameRules: [
          val => !!val || '必须填写姓名',
          val => (val && val.length <= 32) || '姓名必须小于32个字符',
        ],
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
        titleRules: [
          val => !!val || '必须填写留言标题',
          val => (val && val.length <= 64) || '留言标题必须小于64个字符',
        ],
      }
    },
    methods: {
      onSubmit () {
        if (this.submiting) {
          return
        }
        if (!this.$refs.form.validate()) {
          return
        }

        const params = {
          name: this.txtName,
          phone: this.txtPhone,
          title: this.txtTitle,
          message: this.txtMemo
        }
        this.$emit('submit', params)
      }
    }
  }
</script>
