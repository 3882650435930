<template>
  <v-hover
    v-slot="{ hover }"
    :disabled="!hovered"
    close-delay="120"
  >
    <v-card
      :color="wdBackColor"
      :elevation="hover ? hoverElevation : (active ? '8' : normalElevation)"
      :class="['pa-2', hover ? 'transition-swing' : '']"
      outlined
      style="border-color: rgba(0, 0, 0, 0.12) !important;"
    >
      <v-card
        flat
        outlined
        color="transparent"
        style="border-color: rgba(0, 0, 0, 0.12) !important;"
      >
        <div class="pa-6 pb-12 mb-8">
          <land-title
            :title="title"
            class="text-uppercase"
            space="1"
          />

          <land-subtitle
            v-if="subtitle"
            :title="subtitle"
            space="8"
          />

          <div class="d-flex align-center justify-center">
            <land-heading
              mobile-size="display-2"
              space="0"
              tag="h3"
            >
              <small>￥</small>{{ price }}
            </land-heading>
          </div>
        </div>

        <div class="mb-8" />

        <div
          class="d-flex flex-column justify-center align-center pb-12"
        >
          <div class="px-8 mb-12">
            <template v-for="(feature, i) in features">
              <div
                :key="feature"
                class="my-3 text-center"
                v-text="feature"
              />

              <v-divider
                v-if="i + 1 < features.length"
                :key="`divider-${i}`"
              />
            </template>
          </div>

          <v-btn
            depressed
            :outlined="active ? false : true"
            color="primary"
            width="140"
            large
            @click="onClick"
          >
            {{ buttonText }}
          </v-btn>
        </div>
      </v-card>
    </v-card>
  </v-hover>
</template>

<script>
  export default {
    name: 'PriceCard',
    provide: {
      heading: { align: 'center' },
    },
    props: {
      name: {
        type: String,
        default: ''
      },
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      buttonText: {
        type: String,
        default: '查看详情'
      },
      features: {
        type: Array,
        default: () => ([]),
      },
      price: {
        type: [Number, String],
        default: 0
      },
      backColor: {
        type: String,
        default: 'white'
      },
      active: {
        type: Boolean,
        default: false
      },
      hovered: {
        type: Boolean,
        default: true
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    computed: {
      wdBackColor () {
        if (this.$vuetify.isDark) {
          return 'white'
        } else {
          return this.backColor || 'transparent'
        }
      }
    },
    methods: {
      onClick () {
        const params = {
          name: this.name,
          title: this.title
        }
        this.$emit('click', params)
      }
    }
  }
</script>
