
<template>
  <land-section
    :color="backColor || 'transparent'"
    :space="space"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
      :color="assistColor"
      :icon="header.icon"
      :outlined="header.outlined"
    >
      {{ header.subtitle }}
    </land-section-heading>

    <v-container
      v-if="features.length"
      :fluid="header.fluid === true ? true : false"
    >
      <v-row justify="center">
        <v-col
          v-for="(feature, i) in features"
          :key="`feature_${i}`"
          cols="12"
          md="6"
        >
          <land-avatar-card
            v-bind="feature"
            align="left"
            horizontal
            @click="onFeature(feature)"
          >
            {{ feature.subtitle }}
          </land-avatar-card>
        </v-col>
      </v-row>
      <div
        v-if="button.text"
        class="d-flex flex-row justify-center mt-8"
      >
        <v-btn
          depressed
          :color="button.backColor || 'white'"
          :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text']"
          :width="btnWidth"
          :x-large="btnXLarge"
          @click="onClick"
        >
          {{ button.text }}
        </v-btn>
      </div>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'LandIntroIconsHor',
    props: {
      space: {
        type: [Number, String],
        default: 80,
      },
      backColor: {
        type: String,
        default: 'white'
      },
      foreColor: {
        type: String,
        default: 'primary'
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
      features: {
        type: Array,
        default: () => {
          return []
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    computed: {
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return true
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 140
        } else {
          return 180
        }
      },
    },
    methods: {
      onFeature (feature) {
        this.$emit('click', feature)
      },
      onClick () {
        this.$emit('action', this.button)
      }
    }
  }
</script>
