<template>
  <div class="d-flex flex-column co-w-full">
    <p
      v-if="info.model === 'article'"
      v-html="info.content"
    />
    <div
      v-else-if="info.model === 'shop'"
      class="d-flex flex-column"
    >
      <v-img
        v-for="(img, imgIndex) in info.imageList"
        :key="`img_${imgIndex}`"
        :src="img.value"
        contain
      />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LandNoticeContent',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  }
</script>
