<template>
  <v-dialog
    :value="wdVisible"
    :width="width || 500"
    @click:outside="onCancel"
  >
    <v-card>
      <v-card-title
        v-if="wdTitle"
        :class="['text-h6', wdTitleColor]"
      >
        {{ wdTitle }}
      </v-card-title>

      <v-card-text class="px-6 py-5">
        {{ wdContent }}
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="wdShowCancel"
          text
          @click="onCancel"
        >
          {{ wdCancelText }}
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="onConfirm"
        >
          {{ wdConfirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LandDialog',
    props: {
      width: {
        type: [Number, String],
        default: 500
      },
    },
    data () {
      return {
        wdVisible: false,
        wdTitle: '',
        wdContent: '',
        wdTitleColor: 'grey lighten-3 black--text',
        wdCancelText: '否',
        wdConfirmText: '是',
        wdShowCancel: false,
        wdType: 'info',
        cancel: null,
        confirm: null,
        titleColors: {
          info: 'grey lighten-3 black--text',
          warning: 'orange white--text',
          error: 'red white--text',
          success: 'green white--text'
        }
      }
    },
    methods: {
      show ({
        title,
        content,
        type = 'info',
        cancelText = '否',
        confirmText = '是',
        showCancel = false,
        cancel = null,
        confirm = null
      }) {
        this.wdType = type
        this.wdTitle = title
        this.wdContent = content
        this.wdTitleColor = this.titleColors[type] || 'grey lighten-3 black--text'
        this.wdCancelText = cancelText
        this.wdConfirmText = confirmText
        this.wdShowCancel = showCancel
        this.cancel = cancel
        this.confirm = confirm
        this.wdVisible = true
      },
      hide () {
        this.wdVisible = false
      },
      onCancel () {
        this.wdVisible = false
        const params = {
          title: this.wdTitle,
          content: this.wdContent
        }
        if (typeof this.cancel === 'function') {
          this.cancel(params)
        }
      },
      onConfirm () {
        this.wdVisible = false
        const params = {
          title: this.wdTitle,
          content: this.wdContent
        }
        if (typeof this.confirm === 'function') {
          this.confirm(params)
        }
      }
    }
  }
</script>
