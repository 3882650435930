<template>
  <div
    v-if="info.accessory && info.accessory.length"
    class="d-flex flex-column co-w-full mt-8"
  >
    <land-navigation
      title="附件"
      size="text-lg-h6"
    />
    <div class="co-w-full co-h16" />
    <v-row :class="[margin, padding, backColor]">
      <v-col
        v-for="(acc, accIndex) in info.accessory"
        :key="`acc_${accIndex}`"
        cols="12"
        class="px-0 py-0"
      >
        <v-hover
          v-slot="{ hover }"
          :disabled="!hovered"
          close-delay="120"
        >
          <v-card
            :elevation="dense ? 0 : (hover ? hoverElevation : normalElevation)"
            :class="[round, 'transition-swing', 'co-overflow-hidden', dense ? '' : 'mb-4']"
            :color="cardColor"
            flat
            tile
          >
            <div
              :class="['d-flex', 'flex-row', 'align-center', 'co-w-full',
                       dense ? 'pr-4' : 'px-4',
                       dense ? 'py-1' : 'py-3'
              ]"
            >
              <v-icon
                v-if="acc.icon"
                :color="hover ? acc.color : 'blue-grey darken-3'"
                class="pr-3"
              >
                {{ acc.icon }}
              </v-icon>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <a
                    :class="['text-subtitle-1', 'd-inline-block', 'text-truncate',
                             hover ? 'primary--text' : 'black--text darken-4'
                    ]"
                    style="flex: 1;"
                    v-bind="attrs"
                    v-on="on"
                    @click="viewFile(acc)"
                  >
                    {{ acc.name }}
                  </a>
                </template>
                <span>{{ acc.name }}</span>
              </v-tooltip>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import api from '@/api/co.api'

  export default {
    name: 'LandNoticeAccessory',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      },
      hovered: {
        type: Boolean,
        default: true
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      cardColor: {
        type: String,
        default: 'grey lighten-4'
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
      dense: {
        type: Boolean,
        default: false
      },
    },
    methods: {
      viewFile (acc) {
        api.comm.viewFile(acc)
      }
    }
  }
</script>
