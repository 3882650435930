<template>
  <v-dialog
    :value="wdVisible"
    :width="width || 500"
    persistent
  >
    <v-card>
      <v-card-title
        v-if="wdTitle"
        :class="['text-h6', wdTitleColor]"
      >
        {{ wdTitle }}
      </v-card-title>

      <v-card-text class="pa-5">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field
            v-model="txtName"
            label="姓名"
            outlined
            dense
            required
            :rules="nameRules"
          />

          <v-text-field
            v-model="txtPhone"
            label="手机号码"
            outlined
            dense
            required
            :rules="phoneRules"
          />

          <v-textarea
            v-model="txtMemo"
            label="你的需求"
            outlined
            dense
            rows="5"
          />
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="wdShowCancel"
          outlined
          color="blue-grey"
          width="100"
          :disabled="submiting"
          @click="onCancel"
        >
          {{ wdCancelText }}
        </v-btn>
        <v-btn
          color="primary"
          width="100"
          :loading="submiting"
          @click="onConfirm"
        >
          {{ wdConfirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'LandDialogPutin',
    props: {
      width: {
        type: [Number, String],
        default: 600
      },
    },
    data () {
      return {
        submiting: false,
        wdVisible: false,
        wdTitle: '',
        wdTitleColor: 'grey lighten-3 black--text',
        wdCancelText: '取消',
        wdConfirmText: '提交',
        wdShowCancel: true,
        wdType: 'info',
        cancel: null,
        confirm: null,
        titleColors: {
          info: 'grey lighten-3 black--text',
          warning: 'orange white--text',
          error: 'red white--text',
          success: 'green white--text'
        },
        txtName: '',
        txtPhone: '',
        txtMemo: '',
        nameRules: [
          val => !!val || '必须填写姓名',
          val => (val && val.length <= 32) || '姓名必须小于32个字符',
        ],
        phoneRules: [
          val => !!val || '必须填写手机号码',
          val => /^[1][3-9][0-9]{9}$/.test(val) || '请输入正确的手机号码'
        ],
      }
    },
    methods: {
      show ({
        title,
        type = 'info',
        cancelText = '取消',
        confirmText = '提交',
        showCancel = true,
        cancel = null,
        confirm = null
      }) {
        this.wdType = type
        this.wdTitle = title
        this.wdTitleColor = this.titleColors[type] || 'grey lighten-3 black--text'
        this.wdCancelText = cancelText
        this.wdConfirmText = confirmText
        this.wdShowCancel = showCancel
        this.cancel = cancel
        this.confirm = confirm
        this.wdVisible = true
      },
      hide () {
        this.submiting = false
        this.wdVisible = false
        this.txtName = ''
        this.txtPhone = ''
        this.txtMemo = ''
      },
      onCancel () {
        this.hide()

        const params = {
          title: this.wdTitle
        }
        if (typeof this.cancel === 'function') {
          this.cancel(params)
        }
      },
      onConfirm () {
        if (this.submiting) {
          return
        }
        if (!this.$refs.form.validate()) {
          return
        }

        this.submiting = true
        const params = {
          name: this.txtName,
          phone: this.txtPhone,
          message: this.txtMemo
        }
        if (typeof this.confirm === 'function') {
          this.confirm(params)
        }
      }
    }
  }
</script>
