<template>
  <div class="d-flex flex-column justify-center align-center">
    <span class="text-lg-h5 primary--text">
      {{ info.title }}
    </span>
    <div class="d-flex flex-row justify-space-between align-center mt-3">
      <span class="text-body-1 blue-grey--text lighten-3">
        发布时间:
        <span class="pl-1 blue-grey--text darken-4">{{ info.createTime }}</span>
      </span>

      <span class="text-body-1 blue-grey--text lighten-3 ml-6">
        阅读次数:
        <span class="pl-1 blue-grey--text darken-4">{{ info.hits }}</span>
      </span>
    </div>
    <div class="co-w-full co-h2 primary my-4" />
  </div>
</template>

<script>
  export default {
    name: 'LandNoticeHeader',
    props: {
      info: {
        type: Object,
        default: () => {
          return {}
        }
      }
    }
  }
</script>
