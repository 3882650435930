var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-img',{attrs:{"height":_vm.wdHeight,"width":"100%","src":_vm.content.cover}},[_c('div',{class:[
      'd-flex', 'flex-column', 'justify-space-around', 'px-4 px-md-16', 'py-6 py-md-14',
      _vm.classify.align ? ("align-" + (_vm.classify.align)) : 'align-center'
    ],staticStyle:{"height":"100%"}},[_c('div',{class:[
        'd-flex', 'flex-column',
        _vm.classify.align ? ("align-" + (_vm.classify.align)) : 'align-center'
      ]},[(_vm.content.title)?_c('div',{class:['hidden-sm-and-down', 'text-h4', 'text-sm-h3', 'text-md-h2', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],domProps:{"textContent":_vm._s(_vm.content.title)}}):_vm._e(),(_vm.content.subtitle)?_c('div',{class:['text-h6', 'text-md-h5', 'mt-6', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],staticStyle:{"font-weight":"400"},domProps:{"innerHTML":_vm._s(_vm.content.subtitle)}}):_vm._e()]),(!_vm.smAndDown && _vm.content.features && _vm.content.features.length)?_c('div',{staticClass:"d-flex flex-row justify-between align-center"},[_vm._l((_vm.content.features),function(feature,index){return [_c('div',{key:("feature_" + index),staticClass:"d-flex align-center"},[(feature.icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":feature.iconColor || 'white',"size":"32"}},[_vm._v(" "+_vm._s(feature.icon)+" ")]):_vm._e(),(feature.text)?_c('div',{class:['text-subtitle-1', 'text-md-h5', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],staticStyle:{"font-weight":"400"},domProps:{"textContent":_vm._s(feature.text)}}):_vm._e()],1),(index < (_vm.content.features.length - 1))?_c('div',{key:("spacer_" + index),staticClass:"pa-2 pa-md-4"}):_vm._e()]})],2):_vm._e(),_c('div',{class:[
        'd-flex', 'flex-column',
        _vm.classify.align ? ("align-" + (_vm.classify.align)) : 'align-center'
      ]},[(_vm.button.text)?_c('v-btn',{class:[_vm.button.foreColor ? ((_vm.button.foreColor) + "--text") : 'primary--text'],attrs:{"depressed":"","color":_vm.button.backColor || 'white',"width":_vm.btnWidth,"x-large":_vm.btnXLarge},on:{"click":_vm.onClick}},[_vm._v(" "+_vm._s(_vm.button.text)+" ")]):_vm._e(),(_vm.content.contact)?_c('div',{class:['text-h6', 'mt-3', _vm.classify.color ? ((_vm.classify.color) + "--text") : 'white--text'],domProps:{"textContent":_vm._s(_vm.content.contact)}}):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }