/*
* confKeys 表示 upms_config 表中 value 对应的值
* site: 表示站点基本配置参数：如电话、地址、邮箱、公司等基本信息
* home：表示首页配置参数；
* content：表示商品页面配置参数；
* notice：表示资讯（素材）页面配置参数；
* cart：表示购物车页面配置参数；
* clean：表示结算页面配置参数
* base: 表示站点基础配置, 如是否有购物车、是否能电话联系、是否有客服
*/
const confKeys = {
  site: 'siteParams',
  home: 'homeParams',
  mall: 'mallParams',
  emall: 'emallParams',
  content: 'contentParams',
  notice: 'noticeParams',
  cart: 'cartParams',
  clean: 'cleanParams',
  login: 'loginParams',
  base: 'baseParams',
  apps: 'appsParams',
  my: 'myParams',
  mng: 'mngParams',
  bgimg: 'bgimgParams'
}

/*
* sitePreset 站点默认参数
*/
const sitePreset = {
  keys: {
    title: 'title',
    subtitle: 'subtitle',
    qq: 'qq',
    web: 'web',
    weixin: 'weixin',
    phone: 'phone',
    telphone: 'telphone',
    fax: 'fax',
    address: 'address',
    email: 'email',
    authdate: 'authdate',
    logo: 'logo',
    head: 'head',
    nicklabel: 'nicklabel',
    nickhint: 'nickhint',
    qrcode1: 'qrcode1',
    qrcode2: 'qrcode2',
    qrcode3: 'qrcode3'
  },
  title: {
    value: '南京蓝库软件科技有限公司',
    visible: true,
    label: '名称',
    key: 'title',
    icon: ''
  },
  subtitle: {
    value: '',
    visible: true,
    label: '描述',
    key: 'subtitle',
    icon: ''
  },
  qq: {
    label: 'QQ',
    value: '',
    visible: false,
    key: 'qq',
    icon: 'qq'
  },
  web: {
    label: '官网',
    value: '',
    visible: false,
    key: 'web',
    icon: ''
  },
  weixin: {
    label: '微信',
    value: '',
    visible: false,
    key: 'weixin',
    icon: 'weixin'
  },
  phone: {
    label: '手机',
    value: '13382081515',
    visible: false,
    key: 'phone',
    icon: 'mobile'
  },
  telphone: {
    label: '电话',
    value: '025-58655236',
    visible: false,
    key: 'telphone',
    icon: 'phone'
  },
  fax: {
    label: '传真',
    value: '',
    visible: false,
    key: 'fax',
    icon: ''
  },
  address: {
    label: '地址',
    value: '南京市集庆门大街268号苏宁慧谷E08-2栋719室',
    visible: true,
    key: 'address',
    icon: 'location'
  },
  email: {
    label: '邮箱',
    value: 'product@landcoo.com',
    visible: true,
    key: 'email',
    icon: ''
  },
  authdate: {
    label: '授权时间',
    value: '2020-2021',
    visible: true,
    key: 'authdate'
  },
  logo: {
    label: 'LOGO',
    value: '',
    visible: true,
    key: 'logo'
  },
  head: {
    label: '头像',
    value: '',
    visible: true,
    key: 'head'
  },
  nicklabel: {
    label: '未登录提示语',
    value: '点击登录',
    visible: true,
    key: 'nicklabel'
  },
  nickhint: {
    label: '未登录辅助提示语',
    value: '登录后享有更多的操作权益',
    visible: true,
    key: 'nickhint'
  },
  qrcode1: {
    label: '公众号二维码',
    value: '',
    visible: true,
    key: 'qrcode1'
  },
  qrcode2: {
    label: '小程序二维码',
    value: '',
    visible: true,
    key: 'qrcode2'
  },
  qrcode3: {
    label: '公众号二维码',
    value: '',
    visible: true,
    key: 'qrcode3'
  }
}

const configComm = {
  Keys: confKeys,
  confKeys,
  sitePreset,
  siteKeys: sitePreset.keys
}

export default configComm
