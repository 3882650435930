// Imports
import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
  mode: 'history', // hash
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/home/Index.vue'),
      children: [
        {
          path: '',
          name: 'Home',
          component: () => import('@/pages/landcoo/Home.vue'),
        },
        {
          path: 'notice',
          name: 'Notice',
          component: () => import('@/pages/landcoo/Notice.vue'),
        },
        {
          path: 'park',
          name: 'Park',
          component: () => import('@/pages/landcoo/Park.vue'),
        },
        {
          path: 'policy',
          name: 'Policy',
          component: () => import('@/pages/landcoo/Policy.vue'),
        },
        {
          path: 'disclose',
          name: 'Disclose',
          component: () => import('@/pages/landcoo/Disclose.vue'),
        },
        {
          path: 'service',
          name: 'Service',
          component: () => import('@/pages/landcoo/Service.vue'),
        },
        {
          path: 'party',
          name: 'Party',
          component: () => import('@/pages/landcoo/Party.vue'),
        },
        {
          path: 'my',
          name: 'My',
          component: () => import('@/pages/landcoo/My.vue'),
        },
        {
          path: 'home/psqs',
          name: 'HomePsqs',
          component: () => import('@/pages/landcoo/home/Psqs.vue'),
        },
        {
          path: 'home/problem',
          name: 'HomeProblem',
          component: () => import('@/pages/landcoo/home/Problem.vue'),
        },
        {
          path: 'home/activity',
          name: 'ActivityDetail',
          component: () => import('@/pages/landcoo/home/Activity.vue'),
        },
        {
          path: 'home/activitys',
          name: 'ActivityList',
          component: () => import('@/pages/landcoo/home/Activitys.vue'),
        },
        {
          path: 'home/registration',
          name: 'Registration',
          component: () => import('@/pages/landcoo/home/Registration.vue'),
        },
        {
          path: 'home/reporting',
          name: 'Reporting',
          component: () => import('@/pages/landcoo/home/Reporting.vue'),
        },
        {
          path: 'park/images',
          name: 'ParkImages',
          component: () => import('@/pages/landcoo/park/Images.vue'),
        },
        {
          path: 'party/notices',
          name: 'PartyNotices',
          component: () => import('@/pages/landcoo/party/Notices.vue'),
        },
        {
          path: 'party/notice',
          name: 'PartyNotice',
          component: () => import('@/pages/landcoo/party/Notice.vue'),
        },
        {
          path: 'notice/list',
          name: 'NoticeList',
          component: () => import('@/pages/landcoo/notice/List.vue'),
        },
        {
          path: 'notice/detail',
          name: 'NoticeDetail',
          component: () => import('@/pages/landcoo/notice/Detail.vue'),
        },
        {
          path: 'policy/detail',
          name: 'PolicyDetail',
          component: () => import('@/pages/landcoo/policy/Detail.vue'),
        },
        {
          path: 'policy/list',
          name: 'PolicyList',
          component: () => import('@/pages/landcoo/policy/List.vue'),
        },
        {
          path: 'disclose/detail',
          name: 'DiscloseDetail',
          component: () => import('@/pages/landcoo/disclose/Detail.vue'),
        },
        {
          path: 'disclose/list',
          name: 'DiscloseList',
          component: () => import('@/pages/landcoo/disclose/List.vue'),
        },
        {
          path: 'disclose/resume',
          name: 'DiscloseResume',
          component: () => import('@/pages/landcoo/disclose/Resume.vue'),
        },
        {
          path: 'contact',
          name: 'Contact',
          component: () => import('@/pages/landcoo/Contact.vue'),
        },
        {
          path: 'kitchen-sink',
          name: 'Sink',
          component: () => import('@/views/sink/Index.vue'),
          meta: { src: require('@/assets/sink.jpg') },
        },
        {
          path: '*',
          name: 'FourOhFour',
          component: () => import('@/views/404/Index.vue'),
        },
      ],
    },

  ],
})

export default router
