var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{class:[_vm.margin],attrs:{"no-gutters":false}},_vm._l((_vm.items),function(item,i){return _c('v-col',{key:("gallery_" + i),staticClass:"land-gallery",attrs:{"cols":"12","sm":_vm.smCols,"md":_vm.mdCols}},[_c('v-hover',{attrs:{"disabled":!_vm.hovered,"close-delay":"120"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:[_vm.round, _vm.padding, 'transition-swing'],attrs:{"elevation":hover ? _vm.hoverElevation : _vm.normalElevation,"color":_vm.backColor,"flat":"","tile":""}},[_c('v-img',{staticClass:"align-end",attrs:{"aspect-ratio":_vm.ratio,"src":item.cover || _vm.defCover,"height":"inherit"},on:{"click":function($event){return _vm.onClick(item, i)}}},[(_vm.titled && _vm.floated)?_c('div',{class:[
              'text-uppercase', 'text-md-h6', 'text-sm-h7', 'px-5', 'py-2',
              _vm.textAlign, 'white--text', 'grey darken-4', 'co-text-line1'
            ],staticStyle:{"opacity":"0.75","line-height":"32px"}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]),(_vm.titled && !_vm.floated)?_c('div',{class:[
            'text-uppercase', 'text-md-h6', 'text-sm-h7',
            'px-5', 'py-3', _vm.textAlign, 'co-text-line1'
          ],staticStyle:{"line-height":"32px"},on:{"click":function($event){return _vm.onClick(item, i)}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()],1)]}}],null,true)})],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }