<template>
  <v-navigation-drawer
    v-model="visible"
    fixed
    overlay-color="secondary"
    overlay-opacity=".8"
    temporary
  >
    <v-list>
      <v-subheader>{{ category.title }}</v-subheader>
      <v-list-item-group
        v-model="active"
      >
        <v-list-item
          v-for="(item, index) in category.items"
          :key="index"
        >
          <v-list-item-content>
            <v-list-item-title
              class="text-subtitle-1"
              @click="onClick(item, index)"
              v-text="item.title"
            />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    name: 'LandNoticeCategoryDrawer',
    props: {
      category: {
        type: Object,
        default: () => {
          return {}
        }
      },
      drawer: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        visible: false,
        active: ''
      }
    },
    watch: {
      drawer: {
        handler (newVal) {
          this.visible = newVal
        },
        immediate: true
      },
      category: {
        handler (newVal) {
          this.active = newVal.active
        },
        immediate: true
      }
    },
    methods: {
      onClick (item, index) {
        this.$emit('click', {
          item,
          index
        })
      }
    }
  }
</script>
