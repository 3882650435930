import api from '@/api/co.api.js'
import surveyComm from './co.survey.comm.js'
import dayjs from 'dayjs'

const surveyMain = {
  getParams ({
    siteId,
    type = '',
    status = 'publish',
    start = '',
    end = '',
    sort = 'createTime',
    order = 'desc',
    limit = '10',
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.CMS_PSQ_LIST,
      data: {
        siteId,
        type,
        status,
        start,
        end,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  getDetail ({ id, executing = null, executed = null, transform = null }) {
    const params = api.comm.createParams({
      url: `${api.url.CMS_PSQ_DETAILS}/${id}`,
      executing,
      executed,
      transform: transform || this.convertItem
    })

    api.http.getDetail(params)
  },
  convertDetails (items) {
    const result = []
    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const options = api.comm.jsonToArray(item.options)
      const config = api.comm.jsonToObject(item.config)
      // const analysis = api.comm.jsonToObject(item.answerAnalysis)

      const optList = []
      for (const i in options) {
        const obj = options[i]
        optList.push({
          ...obj,
          selected: false,
          state: surveyComm.QuestionStates.normal
        })
      }

      const dstItem = {
        index: i + 1,
        questionId: item.questionId,
        questionnaireId: item.questionnaireId,
        sortNum: item.sortNum,
        title: item.title,
        description: item.description || '',
        type: api.comm.trim(item.type),
        score: item.score,
        options: optList,
        error: '',
        result: '',
        analysis: {},
        config,
        readonly: false
      }

      result.push(dstItem)
    }
    return result
  },
  convertItem (srcItem, parseDetails = true) {
    const type = srcItem.type
    const nowTime = dayjs().format('yyyy-MM-dd hh:mm')
    const validityStart = dayjs(srcItem.validityStart).format('YYYY-MM-DD hh:mm')
    const validityEnd = dayjs(srcItem.validityEnd).format('YYYY-MM-DD hh:mm')

    const sharePoint = parseInt(srcItem.sharePoint, 10) || 0
    const submitPoint = parseInt(srcItem.submitPoint, 10) || 0
    let config = {}
    if (srcItem.config) {
      try {
        config = JSON.parse(srcItem.config)
      } catch (e) {
        config = {}
      }
    }

    let when = surveyComm.When.NOTSTART
    if (nowTime < validityStart) {
      when = surveyComm.When.NOTSTART
    } else {
      if (nowTime > validityEnd) {
        when = surveyComm.When.END
      } else {
        when = surveyComm.When.PROCESSING
      }
    }

    const surLabels = surveyComm.getLabels(type)
    const timelimit = `${surLabels.hintTitle} ${validityStart} ~ ${validityEnd}`
    const buttonText = surLabels.buttonTexts[when]
    const buttonDisabled = surLabels.buttonDisableds[when]

    let details = []
    if (parseDetails) {
      details = surveyMain.convertDetails(srcItem.details)
    }

    const dstItem = {
      questionnaireId: srcItem.questionnaireId,
      title: srcItem.title,
      timelimit,
      cover: srcItem.cover,
      description: srcItem.description || '',
      type,
      validityStart,
      validityEnd,
      when,
      sharePoint,
      submitPoint,
      config,
      details,
      status: srcItem.status,
      createTime: srcItem.createTime,
      buttonText,
      buttonDisabled
    }

    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = surveyMain.convertItem(item, false)
      params.items.push(dstItem)
    })

    return params.items.length
  }
}

export default surveyMain
