<template>
  <v-card
    :elevation="elevation"
    :class="[round, padding, 'transition-swing']"
    :color="backColor"
    flat
    tile
  >
    <v-card-text
      v-if="title"
    >
      <span :class="['text-h6', 'pr-2', foreColor]">{{ `${index}.` }}</span>
      <span :class="['text-h6', foreColor]">{{ title }}</span>
      <span class="text-subtitle-2 blue-grey--text lighten-3 pl-2">{{ `(${type})` }}</span>
    </v-card-text>
    <div
      v-if="options.length"
      class="d-flex flex-column pl-9 mt-0"
    >
      <div
        v-for="(opt, optIndex) in options"
        :key="optIndex"
        class="d-flex flex-column"
      >
        <v-checkbox
          v-model="result"
          hide-details
          :value="opt.id"
          style="margin: 0 0;"
          @change="onChange($event, optIndex)"
        >
          <template #label>
            <span :class="['text-subtitle-1', foreColor]">
              <span class="px-2">{{ `${opt.id}.` }}</span>
              <span>{{ opt.content }}</span>
            </span>
          </template>
        </v-checkbox>
        <v-img
          v-if="opt.image"
          :src="opt.image"
          aspect-ratio="2"
          class="rounded-lg mt-1 mb-6"
        />
      </div>
    </div>
    <div
      v-if="error"
      class="d-flex flex-row pl-10"
    >
      <span class="text-subtitle-2 red--text">{{ error }}</span>
    </div>
  </v-card>
</template>

<script>
  import api from '@/api/co.api'
  import survey from '@/api/co.survey'

  export default {
    name: 'LandQuestionMultiple',
    props: {
      question: {
        type: Object,
        default: () => {
          return {}
        }
      },
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      elevation: {
        type: [Number, String],
        default: 1
      },
      answerText: {
        type: String,
        default: '答案:'
      },
      analysisText: {
        type: String,
        default: '解析:'
      },
      error: {
        type: String,
        default: ''
      },
      showAnswer: {
        type: Boolean,
        default: false
      },
      showAnalysis: {
        type: Boolean,
        default: false
      },
      showType: {
        type: Boolean,
        default: true
      },
      margin: {
        type: String,
        default: 'mx-4 mt-4'
      },
      padding: {
        type: String,
        default: 'pa-4'
      },
    },
    data () {
      return {
        foreColor: 'blue-grey--text darken-4',
        iconSize: '28',
        index: 1,
        title: '',
        type: '',
        result: [],
        options: [],
        anaItems: [],
        readonly: false,
        defState: 'normal',
        iconColor: survey.comm.IconColors.selected,
        textColor: survey.comm.TextColors.unselected,
        textColors: survey.comm.TextColors,
        iconColors: survey.comm.IconColors
      }
    },
    watch: {
      question: {
        handler (newVal, oldVal) {
          this.index = newVal.index
          this.title = newVal.title || ''
          this.type = newVal.type || ''
          const analysis = newVal.analysis || {}
          this.anaItems = analysis.items || []
          this.readonly = newVal.readonly === true
          this.options.splice(0, this.options.length)
          const temp = newVal.result || ''
          this.result = temp.split(',')

          const opts = newVal.options || []
          for (const i in opts) {
            const opt = opts[i]
            this.options.push({
              ...opt
            })
          }
        },
        immediate: true
      }
    },
    methods: {
      onChange (ev, index) {
        // console.log('onChange ev: %o, index: %o', ev, index)
        const result = []
        for (const i in ev) {
          const val = api.comm.trim(ev[i])
          if (val) {
            result.push(val)
          }
        }
        this.$emit('change', {
          result: result.join(','),
          question: this.question
        })
      },
    }
  }
</script>
