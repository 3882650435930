<template>
  <div
    class="d-flex flex-column"
    :class="[ pattern ]"
  >
    <v-file-input
      v-if="!readonly"
      counter
      show-size
      :label="label"
      :multiple="multiple"
      :prepend-icon="prependIcon"
      :hint="hint"
      :accept="accept"
      outlined
      persistent-hint
      :loading="loading"
      :disabled="loading"
      :value="addItems"
      @change="onChange"
    >
      <template #selection="{ index, text, file }">
        <v-tooltip
          v-if="index < 3"
          bottom
        >
          <template #activator="{ on, attrs }">
            <v-chip
              dark
              label
              small
              v-bind="attrs"
              v-on="on"
            >
              {{ text }}
            </v-chip>
          </template>
          <span>{{ file.name }}</span>
        </v-tooltip>
        <span
          v-else-if="index === 3"
          class="text-overline grey--text text--darken-3 mx-2"
        >
          +{{ addItems.length - 3 }} 文件
        </span>
      </template>
      <template
        v-if="needUpload"
        #append
      >
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              icon
              width="24"
              height="24"
              :color="btnUpload.color"
              :disabled="loading"
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="toUploads"
            >
              <v-icon>
                {{ btnUpload.icon }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ btnUpload.text }}</span>
        </v-tooltip>
      </template>
    </v-file-input>
    <div class="d-flex flex-column">
      <div
        v-for="(desc , ind) in descs"
        :key="`desc_${ind}`"
        class="d-flex flex-row align-center rounded blue-grey lighten-5 pa-3 mb-2"
      >
        <div
          class="d-flex flex-row co-flex-grow align-center"
          style="max-width: 70%;"
        >
          <v-icon
            v-if="desc.icon"
            :color="desc.color"
          >
            {{ desc.icon }}
          </v-icon>
          <span
            class="pl-2 pr-6 co d-inline-block text-truncate"
          >
            {{ desc.nameEx }}
          </span>
        </div>
        <v-spacer />
        <div class="d-flex flex-row align-center">
          <div
            v-for="(btn, btnIndex) in desc.buttons"
            :key="`btn_${btnIndex}`"
          >
            <v-tooltip
              v-if="btn.visible"
              bottom
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  :outlined="btn.outlined"
                  :dark="btn.dark"
                  :disabled="loading"
                  x-small
                  fab
                  depressed
                  :color="btn.color"
                  class="ml-3"
                  width="28"
                  height="28"
                  v-bind="attrs"
                  v-on="on"
                  @click="onHandle(btn, ind)"
                >
                  <v-icon>
                    {{ btn.icon }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ btn.text }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
    <land-dialog ref="dlg" />
  </div>
</template>

<script>
  import api from '@/api/co.api'
  import app from '@/api/co.app'
  const fileStates = api.comm.FileStates

  const BtnUpload = {
    visible: true,
    outlined: true,
    dark: false,
    name: 'upload',
    text: '上传文件',
    icon: 'mdi-tray-arrow-up',
    color: 'primary',
  }

  const BtnView = {
    visible: true,
    outlined: true,
    dark: false,
    name: 'view',
    text: '预览文件',
    icon: 'mdi-eye-outline',
    color: 'indigo',
  }

  const BtnOk = {
    visible: true,
    outlined: false,
    dark: true,
    name: 'ok',
    text: '上传成功',
    icon: 'mdi-check',
    color: 'green'
  }

  const BtnDelete = {
    visible: true,
    outlined: true,
    dark: false,
    name: 'delete',
    text: '删除',
    icon: 'mdi-trash-can-outline',
    color: 'red',
  }

  export default {
    name: 'LandFileInput',
    props: {
      files: {
        type: Array,
        default: () => {
          return []
        }
      },
      label: {
        type: String,
        default: '上传文件'
      },
      hint: {
        type: String,
        default: ''
      },
      accept: {
        type: String,
        default: ''
      },
      multiple: {
        type: Boolean,
        default: false
      },
      readonly: {
        type: Boolean,
        default: false
      },
      prependIcon: {
        type: String,
        default: ''
      },
      pattern: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        loading: false,
        descs: [],
        addItems: [],
        delItems: [],
        actions: {
          upload: this.toUpload,
          view: this.toView,
          delete: this.toDelete
        },
        btnUpload: {
          ...BtnUpload
        },
      }
    },
    computed: {
      needUpload () {
        let result = false
        for (const i in this.descs) {
          const desc = this.descs[i]
          if (desc.state === fileStates.ADDING) {
            result = true
            break
          }
        }
        return result
      }
    },
    methods: {
      toDelete (index) {
        const dlg = this.$refs.dlg
        if (dlg) {
          dlg.show({
            title: '删除提示',
            content: '确实需要删除此文件吗？',
            showCancel: true,
            confirm: (res) => {
              this.items.splice(index, 1)
              this.descs.splice(index, 1)
            }
          })
        }
      },
      toView (index) {

      },
      toUpload (index) {

      },
      getAddingIndexes () {
        const arr = []
        for (const i in this.descs) {
          const item = this.descs[i]
          if (item.state === fileStates.ADDING) {
            const ind = parseInt(i, 10)
            if (ind) {
              arr.push(ind)
            }
          }
        }
        return arr
      },
      toUploads (ev) {
        const inds = this.getAddingIndexes()
        // const len = inds.length
        // let cnt = 0
        this.loading = true
        for (const i in inds) {
          const ind = inds[i]
          const desc = this.descs[ind]
          if (desc.file) {}
        }
      },
      onHandle (btn, fileIndex) {
        // console.log('onHandle btn: %o', btn)
        const action = this.actions[btn.name]
        if (typeof action === 'function') {
          action(fileIndex)
        }
      },
      uploadSuccess ({
        url = '',
        index
      }) {
        const desc = this.descs[index]
        if (desc) {
          desc.path = url
          desc.state = fileStates.UPLOADED
          desc.buttons = [
            { ...BtnView },
            { ...BtnOk },
            { ...BtnDelete }
          ]
        }
      },
      upload (file) {
        const executing = function () {

        }

        const executed = function (res) {
          console.log('res: %o', res)
        }

        const formData = new FormData()
        formData.append('file', file)

        api.http.pushBy({
          url: `${api.url.OSS_RES_UPLOAD}?siteId=${app.init.siteId}`,
          data: formData,
          options: {
            'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundaryaFNePCg5W0c29JmA'
          },
          executing,
          executed
        })
      },
      addFileDesc (file) {
        const desc = api.comm.parsePath(file.name)
        desc.state = fileStates.ADDING
        desc.file = file
        desc.buttons = [
          { ...BtnUpload },
          { ...BtnOk, visible: false },
          { ...BtnDelete }
        ]
        this.descs.push(desc)
      },
      addPathDesc (path) {
        const desc = api.comm.parsePath(path)
        desc.state = fileStates.UPLOADED
        desc.buttons = [
          { ...BtnView },
          { ...BtnOk },
        ]
        if (!this.readonly) {
          desc.buttons.push({ ...BtnDelete })
        }
        this.descs.push(desc)
      },
      onChange (files) {
        console.log('files: %o', files)
        this.descs = []

        if (!files) {
          return
        }

        if (Array.isArray(files)) {
          for (const i in files) {
            const item = files[i]
            this.addFileDesc(item)
          }
        } else {
          this.addFileDesc(files)
        }
      }
    }
  }
</script>
