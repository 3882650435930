<template>
  <v-row justify="center">
    <template v-if="reversed">
      <v-col
        v-if="content.images && content.images.length"
        cols="12"
        md="6"
        xl="5"
      >
        <land-images-guide
          :image-width="imageWidth"
          :image-height="imageHeight"
          :images="content.images"
          :active="imgActive"
          :cycle="cycle"
          :show-indicator="showIndicator"
        />
      </v-col>

      <v-col
        cols="12"
        md="6"
        xl="5"
      >
        <land-text-guide
          :title="content.title"
          :text="content.text"
          :features="content.features"
          :hovers="content.hovers"
          :button="content.button"
          :contact="content.contact"
          @action="onAction"
          @hover="onHover"
        />
      </v-col>
    </template>
    <template v-else>
      <v-col
        cols="12"
        md="6"
        xl="5"
      >
        <land-text-guide
          :title="content.title"
          :text="content.text"
          :features="content.features"
          :hovers="content.hovers"
          :button="content.button"
          :contact="content.contact"
          @action="onAction"
          @hover="onHover"
        />
      </v-col>

      <v-col
        v-if="content.images && content.images.length"
        cols="12"
        md="6"
        xl="5"
      >
        <land-images-guide
          :image-width="imageWidth"
          :image-height="imageHeight"
          :images="content.images"
          :active="imgActive"
          :cycle="cycle"
          :show-indicator="showIndicator"
        />
      </v-col>
    </template>
  </v-row>
</template>

<script>
  export default {
    name: 'LandImagesTextGuide',
    props: {
      reversed: {
        type: Boolean,
        default: false
      },
      imageWidth: {
        type: [Number, String],
        default: 344
      },
      imageHeight: {
        type: [Number, String],
        default: 500
      },
      showIndicator: {
        type: Boolean,
        default: true
      },
      cycle: {
        type: Boolean,
        default: true
      },
      ganged: {
        type: Boolean,
        default: true
      },
      content: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        imgActive: 0
      }
    },
    methods: {
      onAction (res) {
        this.$emit('action', res)
      },
      onHover (active) {
        if (this.ganged) {
          this.imgActive = active
        }
      }
    }
  }
</script>
