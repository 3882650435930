<template>
  <div
    class="d-flex flex-column justify-center"
    style="height: 100%"
  >
    <div
      v-if="title"
      :class="['text-h6', 'text-sm-h5']"
      v-text="title"
    />

    <div
      v-if="text"
      :class="['text-subtitle-2', 'text-md-subtitle-1', 'mt-8']"
      style="font-weight: 400;"
      v-html="text"
    />

    <div
      v-if="features && features.length"
      class="d-flex flex-row align-center mt-10"
    >
      <div
        v-for="(feature, index) in features"
        :key="`feature_${index}`"
        class="d-flex align-center"
        :class="[index < (features.length - 1) ? 'mr-8' : '']"
      >
        <v-icon
          v-if="feature.icon"
          :color="feature.iconColor || 'primary'"
          class="mr-2"
          :size="icoSize"
        >
          {{ feature.icon }}
        </v-icon>

        <div
          v-if="feature.text"
          :class="['text-body-2', 'text-sm-h6', feature.textColor ? `${feature.textColor}--text` : 'black--text']"
          v-text="feature.text"
        />
      </div>
      <!-- <div
        v-if="index < (features.length - 1)"
        :key="`spacer_${index}`"
        class="pa-4"
      /> -->
    </div>

    <div
      v-if="hovers.length"
      class="d-flex flex-row align-center mt-10"
    >
      <div
        v-for="(hov, index) in hovers"
        :key="`hover_${index}`"
        class="d-flex flex-column justify-center align-center"
        :class="[index < hovers.length - 1 ? 'mr-8' : '']"
        style="height: 120px;"
        @mouseenter="onHoverEnter(index)"
      >
        <v-avatar
          :color="hov.color || 'primary'"
          :size="active === index ? 72 : 60"
          :style="{opacity: (active === index ? 1 : 0.45)}"
          :class="['transition-ease-in-out']"
        >
          <img
            v-if="hov.image"
            style="width: 34px;height: 34px; border-radius: 0;"
            :src="hov.image"
          >
          <v-icon
            v-else-if="hov.name"
            dark
            size="34"
          >
            {{ hov.name }}
          </v-icon>
          <span
            v-else-if="hov.char"
            class="white--text text-h5"
          >
            {{ hov.char }}
          </span>
        </v-avatar>
        <div
          v-if="hov.text"
          :class="['pa-2', 'text-subtitle-1', 'black--text']"
        >
          {{ hov.text }}
        </div>
      </div>
    </div>

    <v-btn
      v-if="button && button.text"
      depressed
      :color="button.backColor || 'white'"
      :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text', 'mt-12']"
      :width="btnWidth"
      :x-large="btnXLarge"
      @click="onAction"
    >
      {{ button.text }}
    </v-btn>
    <div
      v-if="contact && contact.text"
      :class="['text-subtitle-1', 'text-sm-h6', 'mt-3', contact.color ? `${contact.color}--text` : 'black--text']"
      v-html="contact.text"
    />
  </div>
</template>

<script>
  export default {
    name: 'LandTextGuide',
    props: {
      title: {
        type: String,
        default: ''
      },
      subtitle: {
        type: String,
        default: ''
      },
      text: {
        type: String,
        default: ''
      },
      features: {
        type: Array,
        default: () => {
          return []
        }
      },
      hovers: {
        type: Array,
        default: () => {
          return []
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
      contact: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data () {
      return {
        active: 0
      }
    },
    computed: {
      icoSize () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 24
        } else {
          return 32
        }
      },
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return true
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 140
        } else {
          return 180
        }
      },
    },
    methods: {
      onAction () {
        this.$emit('action', this.button)
      },
      onHoverEnter (index) {
        this.active = index
        this.$emit('hover', this.active)
      }
    }
  }
</script>
