
const local = {}

local.get = function (key) {
  return localStorage.getItem(key)
}

local.set = function (key, value) {
  localStorage.setItem(key, value)
}

local.remove = function (key) {
  localStorage.removeItem(key)
}

local.clear = function () {
  localStorage.clear()
}

local.key = function (index) {
  return localStorage.key(index)
}

export default local
