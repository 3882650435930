
import api from '@/api/co.api.js'

const surveyPractice = {
  getParams ({
    siteId,
    search = '',
    searchType = '',
    sort = 'createTime',
    order = 'desc',
    limit = '10',
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.UC_QUESTION_ANSWER_PRACTICE_LIST,
      data: {
        siteId,
        search,
        searchType,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  getDetail ({ id, executing = null, executed = null, transform = null }) {
    const params = api.comm.createParams({
      url: `${api.url.UC_QUESTION_ANSWER_PRACTICE_DETAIL}/${id}`,
      executing,
      executed,
      transform: transform || this.convertItem
    })

    api.http.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.http.delete({
      url: api.url.UC_QUESTION_ANSWER_PRACTICE_DELETE,
      id,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.http.create({
      url: api.url.UC_QUESTION_ANSWER_PRACTICE_CREATE,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    const dstItem = {
      ...srcItem
    }
    return dstItem
  },
  convertItems (params, srcItems) {
  srcItems.forEach(item => {
    const dstItem = surveyPractice.convertItem(item, false)
    params.items.push(dstItem)
  })

  return params.items.length
  }
}

export default surveyPractice
