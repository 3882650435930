<template>
  <v-slide-group>
    <template v-for="(item, index) in items">
      <v-slide-item
        :key="`affi_${index}`"
        class="align-self-center"
      >
        <v-img
          :src="item.cover"
          color="grey"
          contain
          height="48"
          width="128"
        />
      </v-slide-item>

      <v-responsive
        v-if="dividered && (index < (items.length - 1))"
        :key="`divider_${index}`"
        class="text-center"
        height="32"
        width="48"
      >
        <v-divider vertical />
      </v-responsive>
    </template>
  </v-slide-group>
</template>

<script>
  export default {
    name: 'LandAffiliates',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      dividered: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {}
    }
  }
</script>
