<template>
  <v-theme-provider :dark="dark">
    <div>
      <land-info-card
        :title="title"
        :color="assistColor"
      >
        <slot />
      </land-info-card>

      <v-card
        class="d-flex flex-column"
        color="transparent"
        flat
        tile
      >
        <div
          v-for="(item, i) in items"
          :key="i"
          class="d-flex flex-column"
        >
          <v-card
            class="d-flex flex-row py-3"
            color="transparent"
            flat
            tile
          >
            <v-icon
              :color="dark ? 'white' : assistColor"
            >
              {{ item.icon }}
            </v-icon>
            <div class="text-subtitle-2 ml-4">
              {{ item.text }}
            </div>
          </v-card>
          <v-divider
            class="my-1"
          />
        </div>

        <div
          v-if="qrcodes.length"
          :class="[
            'mt-6', 'd-flex', 'flex-row',
            qrcodes.length > 2 ? 'justify-space-between' : ''
          ]"
        >
          <div
            v-for="(qrc, j) in qrcodes"
            :key="j"
            class="d-flex flex-row"
          >
            <div class="d-flex flex-column align-center">
              <v-img
                :src="qrc.src"
                height="128"
                width="128"
                class="rounded"
              />
              <div class="text-body-1 mt-4">
                {{ qrc.title }}
              </div>
            </div>
            <div
              v-if="(qrcodes.length < 3) && (j + 1 !== qrcodes.length)"
              class="mx-8"
            />
          </div>
        </div>
      </v-card>
    </div>
  </v-theme-provider>
</template>

<script>
  import config from '@/api/co.config'
  const SiteKeys = config.comm.siteKeys

  export default {
    name: 'LandBusinessContact',
    props: {
      dark: Boolean,
      dense: Boolean,
      title: String,
      assistColor: {
        type: String,
        default: 'primary'
      },
    },
    data () {
      return {
        items: [],
        qrcodes: []
      }
    },
    created () {
      this.configContact()
      this.configQrcode()
    },
    methods: {
      configContact () {
        let param = config.getSiteParam(SiteKeys.address)
        const addr = {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: param.value,
        }

        param = config.getSiteParam(SiteKeys.telphone)
        const phone = {
          icon: 'mdi-cellphone',
          title: 'Phone',
          text: param.value,
        }

        param = config.getSiteParam(SiteKeys.email)
        const email = {
          icon: 'mdi-email',
          title: 'email',
          text: param.value,
        }

        this.items.push(addr, phone, email)
      },
      configQrcode () {
        let param = config.getSiteParam(SiteKeys.qrcode1)
        if (param.value) {
          this.qrcodes.push({
            title: param.label,
            src: param.value
          })
        }

        param = config.getSiteParam(SiteKeys.qrcode2)
        if (param.value) {
          this.qrcodes.push({
            title: param.label,
            src: param.value
          })
        }

        param = config.getSiteParam(SiteKeys.qrcode3)
        if (param.value) {
          this.qrcodes.push({
            title: param.label,
            src: param.value
          })
        }
      }
    }
  }
</script>
