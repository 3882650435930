
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'

// Types 问卷类型
const Types = {
  // 问卷调查
  QUESTIONNAIRE: 'questionnaire',
  // 投票
  VOTE: 'vote',
  // 表单
  FORM: 'form',
  // 考试
  EXAM: 'exam'
}

// States 问卷状态
const States = {
  CREATE: 'create',
  PUBLISH: 'publish',
  DOWN: 'down',
  FINISHED: 'finished'
}

// When 问卷进度
const When = {
  NOTSTART: 0,
  PROCESSING: 1,
  END: 2
}

const QuestionTypes = {
  SINGLE: '单选',
  MULTIPLE: '多选',
  COMPLETION: '填空'
}

/**
 * SurveyLabels 问卷相关标签名称
 * 详细内容参考：/configs/land.njznzz.survey.json
 */
const SurveyLabels = {}
const SurveyExplains = {}

const QuestionStates = {
  normal: 'normal',
  readonly: 'readonly',
  unselected: 'unselected',
  selected: 'selected',
  success: 'success',
  error: 'error'
}

const IconColors = {
  normal: 'black',
  readonly: 'gray300',
  unselected: 'gray600',
  selected: 'black',
  success: 'success',
  error: 'error'
}
const TextColors = {
  normal: app.style.fore.colors.black,
  readonly: app.style.fore.colors.gray600,
  unselected: app.style.fore.colors.black,
  selected: app.style.fore.colors.black,
  success: app.style.fore.colors.success,
  error: app.style.fore.colors.error,
}

const surveyComm = {
  Types,
  States,
  Labels: SurveyLabels,
  When,
  QuestionTypes,
  QuestionStates,
  IconColors,
  TextColors,
  extract () {
    const str = lib.local.get(app.keys.survey_info)
    let data = {}
    if (str) {
      data = JSON.parse(str)
    }
    this.config(data)
  },
  config (data) {
    if (!data) {
      return
    }

    let items = data.labels || {}
    for (const key in items) {
      SurveyLabels[key] = api.comm.deepClone(items[key])
    } // for (let key in items)

    items = data.explains || {}
    for (const key in items) {
      SurveyExplains[key] = api.comm.deepClone(items[key])
    }
  },
  reset (data) {
    this.config(data)
    const value = JSON.stringify(data)
    lib.local.set(app.keys.survey_info, value)
  },
  getLabels (type) {
    let result = SurveyLabels[type]
    if (!result) {
      result = {
        title: '调查问卷',
        buttonTexts: ['即将开始', '参与调查', '调查结束'],
        buttonDisableds: [true, false, true],
        hintTitle: '参与时间：',
        detailTitle: '问卷详情',
        myTitle: '我的调查',
        submitText: '立即参与',
        endHint: '调查问卷已结束'
      }
    }

    return result
  },
  getExplain (type) {
    let result = SurveyExplains[type]
    if (!result) {
      result = {
        content: []
      }
    }
    return result
  },
}

export default surveyComm
