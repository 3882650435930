<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <land-section-heading
        align="left"
        tag="h2"
        :subtitle="intro.subtitle"
        :title="intro.title"
      >
        {{ intro.text }}
      </land-section-heading>

      <v-row>
        <v-col
          v-for="(feature, i) in features"
          :key="`feature_${i}`"
          cols="12"
          md="6"
        >
          <div class="d-flex align-center">
            <v-img
              v-if="feature.cover"
              :src="feature.cover"
              width="72"
              height="72"
            />
            <land-avatar
              v-else
              :icon="feature.icon"
              class="mr-6"
              color="primary"
              dark
              outlined
            />

            <v-responsive max-width="112">
              <land-subtitle
                :title="feature.title"
                class="primary--text"
              />
            </v-responsive>
          </div>
        </v-col>
      </v-row>
    </v-col>

    <v-col
      cols="12"
      md="6"
    >
      <land-img
        :src="intro.cover"
        class="ml-auto"
        contain
        height="400"
        max-width="300"
      />
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LandIntro',
    props: {
      intro: {
        type: Object,
        default: () => {
          return {}
        }
      },
      features: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data () {
      return {}
    }
  }
</script>
