<template>
  <v-row :class="[margin, padding, backColor]">
    <v-col
      v-for="(item, i) in items"
      :key="`notice_${i}`"
      cols="12"
      class="px-0"
    >
      <v-hover
        v-slot="{ hover }"
        :disabled="!hovered"
        close-delay="120"
      >
        <v-card
          :elevation="hover ? hoverElevation : normalElevation"
          :class="[round, 'transition-swing', 'co-overflow-hidden']"
          :color="cardColor"
          flat
          tile
        >
          <div class="d-flex flex-row">
            <v-avatar
              v-if="covered && item.cover"
              tile
              :width="coverWd"
              :height="coverHt"
              class="co-shrink-0"
              @click="onClick(item, i)"
            >
              <v-img
                v-if="item.cover"
                :src="item.cover"
              />
            </v-avatar>
            <div
              class="d-flex flex-column justify-space-between px-6 py-3"
              style="width: 100%;"
            >
              <div class="d-flex flex-column co-box-border">
                <div
                  class="text-md-h6 text-sm-subtitle-1 co-text-line1"
                >
                  {{ item.title }}
                </div>

                <div
                  v-if="item.subtitle"
                  class="text-md-subtitle-2 text-sm-body-2 mt-2 co-text-line2"
                >
                  {{ item.subtitle }}
                </div>
              </div>

              <div class="d-flex flex-row justify-space-between align-center">
                <land-subtitle>
                  {{ item.createTime }}
                </land-subtitle>
                <v-btn
                  text
                  :color="buttonColor"
                  @click="onClick(item, i)"
                >
                  查看详情
                </v-btn>
              </div>
            </div>
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LandNotices',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      effect: {
        type: String,
        default: 'white'
      },
      covered: {
        type: Boolean,
        default: false
      },
      coverWidth: {
        type: [Number, String],
        default: 300
      },
      coverHeight: {
        type: [Number, String],
        default: 180
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      cardColor: {
        type: String,
        default: 'white'
      },
      buttonColor: {
        type: String,
        default: 'primary'
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {}
    },
    computed: {
      coverWd () {
        const wd = Number(this.coverWidth) || 260
        if (this.$vuetify.breakpoint.mdAndUp) {
          return wd
        } else {
          return wd * 0.8
        }
      },
      coverHt () {
        const ht = Number(this.coverHeight) || 156
        if (this.$vuetify.breakpoint.mdAndUp) {
          return ht
        } else {
          return ht * 0.8
        }
      },
      timeFontSize () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 'text-md-body-2'
        } else {
          return 'text-sm-caption'
        }
      }
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>

<style lang="sass">
</style>
