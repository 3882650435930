
import api from '@/api/co.api.js'
import surveyComm from './co.survey.comm.js'

const ExamTypes = [
  surveyComm.Types.EXAM
]

const surveyAnswer = {
  getParams ({
    siteId,
    type = '',
    status = '',
    search = '',
    searchType = '',
    sort = 'createTime',
    order = 'desc',
    limit = '10',
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.UC_QUESTION_ANSWER_LIST,
      data: {
        siteId,
        type,
        status,
        search,
        searchType,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  getDetail ({ id, executing = null, executed = null, transform = null }) {
    const params = api.comm.createParams({
      url: `${api.url.UC_QUESTION_ANSWER_DETAIL}/${id}`,
      executing,
      executed,
      transform: transform || this.convertItem
    })

    api.http.getDetail(params)
  },
  submit ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.http.push({
      url: `${api.url.UC_QUESTION_ANSWER_SUBMIT}/${id}`,
      data,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.http.update({
      url: api.url.UC_QUESTION_ANSWER_UPDATE,
      id,
      data,
      executing,
      executed
    })
  },
  convertDetails (items, type) {
    const details = []

    for (let i = 0; i < items.length; i++) {
      const item = items[i]
      const options = api.comm.jsonToArray(item.options)
      const config = api.comm.jsonToObject(item.config)
      const analysis = api.comm.jsonToObject(item.answerAnalysis)

      const correctFlag = parseInt(item.correctFlag, 10) || 0
      const answer = api.comm.trim(item.answer)
      const result = api.comm.trim(item.answerInfo || '')

      const resArr = result.split(',')
      const optList = []

      for (const i in options) {
        const src = options[i]
        const dst = { ...src }
        if (resArr.indexOf(dst.id) !== -1) {
          dst.selected = true
          if (ExamTypes.indexOf(type) !== -1) {
            if (answer === result) {
              dst.state = surveyComm.QuestionStates.success
            } else {
              dst.state = surveyComm.QuestionStates.error
            }
          } else {
            dst.state = surveyComm.QuestionStates.normal
          }
        } else {
          dst.selected = false
        }
        optList.push(dst)
      }

      const dstItem = {
        index: i + 1,
        detailsId: item.detailsId,
        answerId: item.answerId,
        questionId: item.questionId,
        questionnaireId: item.questionnaireId,
        sortNum: item.sortNum,
        title: item.title,
        description: item.description || '',
        type: api.comm.trim(item.type),
        score: item.score,
        options: optList,
        config,
        answer,
        result,
        error: '',
        correctFlag,
        readonly: true,
        analysis
      }

      details.push(dstItem)
    }
    return details
  },
  convertItem (srcItem, parseDetails = true) {
    const type = srcItem.type
    const validityStart = api.date.newDate(srcItem.validityStart, 'yyyy-MM-dd hh:mm')
    const validityEnd = api.date.newDate(srcItem.validityEnd, 'yyyy-MM-dd hh:mm')
    const config = api.comm.jsonToObject(srcItem.config)
    const sharePoint = parseInt(srcItem.sharePoint, 10) || 0
    const submitPoint = parseInt(srcItem.submitPoint, 10) || 0
    const score = parseInt(srcItem.score, 10) || 0
    let details = []
    let correct = '0%'
    let passed = false

    if (parseDetails) {
      details = surveyAnswer.convertDetails(srcItem.details, srcItem.type)
      let cnt = 0
      for (const i in details) {
        const item = details[i]
        if (item.correctFlag) {
          cnt += 1
        }
      }

      let per = 0
      if (details.length) {
        per = (cnt / details.length) * 100
        const pass = parseFloat(config.pass) || 0
        if (per >= pass) {
          passed = true
        }
        correct = per.toFixed(1)
        correct = api.comm.cutEnding(correct, '.0')
      }
    }

    const dstItem = {
      answerId: srcItem.answerId,
      questionnaireId: srcItem.questionnaireId,
      title: srcItem.title,
      image: srcItem.cover,
      description: srcItem.description || '',
      type,
      validityStart,
      validityEnd,
      sharePoint,
      submitPoint,
      score,
      config,
      correct: `${correct}%`,
      passed,
      details,
      status: srcItem.status,
      createTime: srcItem.createTime,
    }
    return dstItem
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = surveyAnswer.convertItem(item, false)
      params.items.push(dstItem)
    })

    return params.items.length
  }
}

export default surveyAnswer
