<template>
  <v-simple-table style="background-color: transparent;">
    <thead v-if="header.length">
      <tr>
        <template v-for="(head, index) in header">
          <th
            :key="`header_${index}`"
            :class="['text-body-1', 'font-weight-bold', head.align ? `text-${head.align}` : 'text-start']"
            :colspan="head.colspan || 4"
          >
            {{ head.text || '' }}
          </th>
        </template>
      </tr>
    </thead>

    <tbody class="text-body-1">
      <template v-for="(item, rowInd) in items">
        <tr
          :key="`item_${rowInd}`"
        >
          <template v-for="(col, colInd) in header">
            <td
              :key="`data_${rowInd}_${colInd}`"
              :class="[col.align ? `text-${col.align}` : 'text-start', 'py-2', 'text-body-1']"
              :colspan="col.colspan || 4"
            >
              <template v-if="typeof item[col.value] === 'boolean'">
                <v-icon :color="item[col.value] ? 'success' : 'error'">
                  {{ item[col.value] ? 'mdi-check-circle-outline' : 'mdi-close-circle-outline' }}
                </v-icon>
              </template>

              <template v-else>
                <span :class="[item.class ? item.class[col.value] || '' : '']">
                  <span
                    v-if="col.indexed === true"
                    :class="['text-body-2', 'blue-grey--text', rowInd > 8 ? 'mr-3': 'mr-4']"
                  >
                    {{ rowInd + 1 }}
                  </span>
                  {{ item[col.value] }}
                </span>
              </template>
              <template v-if="col.assist">
                <br>
                <span class="text-body-2 grey--text">
                  {{ item[col.assist] }}
                </span>
              </template>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </v-simple-table>
</template>

<script>
  export default {
    name: 'LandSimpleTable',
    props: {
      header: {
        type: Array,
        default: () => {
          return []
        }
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      indexed: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
