<template>
  <div class="d-flex flex-row justify-center align-center">
    <div
      v-if="showIndicator && lfIcons.length"
      class="d-flex flex-column justify-space-around shrink py-4"
      :style="{height: `${imageHeight}px`}"
    >
      <div
        v-for="(lfIcon, lfIndex) in lfIcons"
        :key="`lficon_${lfIndex}`"
        :class="[
          'd-flex', 'flex-row', 'pr-3',
          `justify-${lfIcon.align || lfStyles[lfIndex].align}`
        ]"
        style="width: 110px;"
      >
        <div
          class="d-flex flex-column justify-center align-center"
          :style="{opacity: (leftActive === lfIndex ? 1 : 0.45)}"
          @mouseenter="onLeftIconEnter(lfIndex)"
        >
          <v-avatar
            :color="lfIcon.color || lfStyles[lfIndex].color"
            :size="lfStyles[lfIndex].size"
          >
            <img
              v-if="lfIcon.image"
              :src="lfIcon.image"
            >
            <v-icon
              v-else-if="lfIcon.name"
              dark
              :size="lfStyles[lfIndex].iconSize"
            >
              {{ lfIcon.name }}
            </v-icon>
            <span
              v-else-if="lfIcon.char"
              class="white--text text-h5"
            >
              {{ lfIcon.char }}
            </span>
          </v-avatar>
          <div
            v-if="lfIcon.text"
            :class="['pa-2', `${lfIcon.color || lfStyles[lfIndex].color}--text`]"
          >
            {{ lfIcon.text }}
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{width: `${imgWidth}px`, height: `${imgHeight}px`}"
      :class="[rounded ? 'co-round4 co-overflow-hidden' : '']"
    >
      <v-carousel
        v-model="wdActive"
        :cycle="cycle"
        :height="imgHeight"
        hide-delimiter-background
        :show-arrows="false"
        hide-delimiters
        light
        @change="changeCarousel"
      >
        <v-carousel-item
          v-for="(img, imgInd) in wdImages"
          :key="`img_${imgInd}`"
        >
          <v-img
            transition="scale-transition"
            contain
            height="100%"
            :src="img"
          />
        </v-carousel-item>
      </v-carousel>
    </div>
    <div
      v-if="showIndicator && rtIcons.length"
      class="d-flex flex-column justify-space-around shrink py-4"
      :style="{height: `${imageHeight}px`}"
    >
      <div
        v-for="(rtIcon, rtIndex) in rtIcons"
        :key="`rticon_${rtIndex}`"
        :class="[
          'd-flex', 'flex-row', 'pl-3',
          `justify-${rtIcon.align || rtStyles[rtIndex].align}`
        ]"
        style="width: 110px;"
      >
        <div
          class="d-flex flex-column justify-center align-center"
          :style="{opacity: (rightActive === rtIndex ? 1 : 0.45)}"
          @mouseenter="onRightIconEnter(rtIndex)"
        >
          <v-avatar
            :color="rtIcon.color || rtStyles[rtIndex].color"
            :size="rtStyles[rtIndex].size"
          >
            <img
              v-if="rtIcon.image"
              :src="rtIcon.image"
            >
            <v-icon
              v-else-if="rtIcon.name"
              dark
              :size="rtStyles[rtIndex].iconSize"
            >
              {{ rtIcon.name }}
            </v-icon>
            <span
              v-else-if="rtIcon.char"
              class="white--text text-h5"
            >
              {{ rtIcon.char }}
            </span>
          </v-avatar>
          <div
            v-if="rtIcon.text"
            :class="['pa-2', `${rtIcon.color || rtStyles[rtIndex].color}--text`]"
          >
            {{ rtIcon.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LandImagesGuide',
    props: {
      imageWidth: {
        type: [Number, String],
        default: 344
      },
      imageHeight: {
        type: [Number, String],
        default: 500
      },
      showIndicator: {
        type: Boolean,
        default: true
      },
      cycle: {
        type: Boolean,
        default: true
      },
      rounded: {
        type: Boolean,
        default: true
      },
      active: {
        type: [Number, String],
        default: 0
      },
      images: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    data () {
      return {
        wdImages: [],
        wdActive: 0,
        leftActive: 0,
        rightActive: -1,
        lfIcons: [],
        rtIcons: [],
        lfStyles: [{
          size: 64,
          color: 'orange',
          align: 'end',
          iconSize: 36
        }, {
          size: 56,
          color: 'green',
          align: 'start',
          iconSize: 32
        }, {
          size: 48,
          color: 'purple',
          align: 'center',
          iconSize: 26
        }, {
          size: 60,
          color: 'blue',
          align: 'end',
          iconSize: 34
        }],
        rtStyles: [{
          size: 60,
          color: 'red',
          align: 'start',
          iconSize: 34
        }, {
          size: 48,
          color: 'blue',
          align: 'center',
          iconSize: 26
        }, {
          size: 56,
          color: 'green',
          align: 'end',
          iconSize: 32
        }, {
          size: 64,
          color: 'indigo',
          align: 'start',
          iconSize: 36
        }]
      }
    },
    computed: {
      imgWidth () {
        const wd = parseInt(this.imageWidth) || 344
        if (this.$vuetify.breakpoint.smAndDown) {
          return wd * 0.7
        } else {
          return wd
        }
      },
      imgHeight () {
        const ht = parseInt(this.imageHeight) || 500
        if (this.$vuetify.breakpoint.smAndDown) {
          return ht * 0.7
        } else {
          return ht
        }
      }
    },
    watch: {
      active: {
        handler (val) {
          this.wdActive = parseInt(val) || 0
        },
        immediate: true
      },
      images: {
        handler (val) {
          this.wdImages.splice(0, this.wdImages.length)

          this.lfIcons.splice(0, this.lfIcons.length)
          this.rtIcons.splice(0, this.rtIcons.length)

          const imgs = val || []
          const icons = []
          let ind = 0
          for (const key in imgs) {
            const img = imgs[key]
            this.wdImages.push(img.src)
            if (img.indicator) {
              icons.push({ ...img.indicator })
            } else {
              ind += 1
              icons.push({
                char: `${ind}`,
                Text: ''
              })
            }
          }

          let cnt = icons.length
          if (cnt > 8) {
            cnt = 8
          }
          if (cnt <= 3) {
            for (const i in icons) {
              this.lfIcons.push(icons[i])
            }
          } else if (cnt > 3) {
            for (let i = 0; i < cnt; i++) {
              if ((i + 1) % 2) {
                this.lfIcons.push(icons[i])
              } else {
                this.rtIcons.push(icons[i])
              }
            }
          }
        },
        immediate: true
      }
    },
    methods: {
      onLeftIconEnter (index) {
        this.leftActive = index
        this.rightActive = -1
        this.wdActive = index * 2
      },
      onRightIconEnter (index) {
        this.rightActive = index
        this.leftActive = -1
        this.wdActive = index * 2 + 1
      },
      changeCarousel (number) {
        this.wdActive = number
        if ((number + 1) % 2) {
          this.rightActive = -1
          this.leftActive = number / 2
        } else {
          this.leftActive = -1
          this.rightActive = (number - 1) / 2
        }
      }
    }
  }
</script>
