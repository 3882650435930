
import app from '@/api/co.app'
import local from '@/api/co.libs.local'

const CONFIG_CACHE_KEY = `${app.init.name}_conf_site`

const configCache = {
  key: CONFIG_CACHE_KEY,
  params: null,
  items: [],
  data: {},
  cache: {},
  extract () {
    const temp = local.get(CONFIG_CACHE_KEY) || ''
    let obj = {}
    if (temp) {
      try {
        obj = JSON.parse(temp)
      } catch (error) {
        obj = {}
      }
    }
    configCache.cache = obj
    // console.log('co.config.cache extract, cache:%o', temp)
  },
  store (data) {
    const str = JSON.stringify(data)
    local.set(CONFIG_CACHE_KEY, str)
  },
}

export default configCache
