<template>
  <div
    :class="classes"
    class="land-avatar d-inline-flex"
  >
    <v-avatar
      v-if="outlined"
      :color="color || ($vuetify.theme.isDark ? 'grey darken-3' : 'grey lighten-4')"
      :size="outlineSize"
      :style="styles"
      class="land-avatar__outline"
      style="opacity: .4"
    />

    <!-- <v-avatar
      :color="color || ($vuetify.theme.isDark ? 'grey darken-4' : 'white')"
      :size="size"
      class="land-avatar__avatar"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <land-icon
        v-if="icon"
        :color="wdIconColor"
        :dark="dark"
        :size="size / 2"
      >
        {{ icon }}
      </land-icon>
    </v-avatar> -->
    <v-btn
      :color="color || ($vuetify.theme.isDark ? 'grey darken-4' : 'white')"
      :height="size"
      :width="size"
      fab
      class="land-avatar__avatar"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <land-icon
        v-if="icon"
        :color="wdIconColor"
        :dark="dark"
        :size="size / 2"
      >
        {{ icon }}
      </land-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'LandAvatar',
    inject: ['theme'],
    props: {
      color: String,
      iconColor: String,
      dark: Boolean,
      icon: {
        type: String,
        default: ''
      },
      outlined: {
        type: Boolean,
        default: true,
      },
      size: {
        type: [Number, String],
        default: 56,
      },
    },

    data: () => ({
      multiply: 6,
    }),

    computed: {
      classes () {
        return [
          this.outlined && 'land-avatar--outlined',
        ]
      },
      isDark () {
        return this.theme.isDark || this.dark
      },
      outlineSize () {
        return Number(this.size) + (this.size / this.multiply)
      },
      styles () {
        const margin = this.size / (this.multiply * 2)
        return {
          // Aligns the outline content with the content
          margin: `-${margin}px 0 0 -${margin}px`,
        }
      },
      wdIconColor () {
        if (this.dark) {
          return this.iconColor || 'white'
        } else {
          return this.iconColor || 'primary'
        }
      }
    },
  }
</script>

<style lang="sass">
  .land-avatar
    border-radius: 50%
    position: relative
    overflow: visible

    &__outline
      position: absolute !important
      left: 0
      top: 0

    &.land-avatar--outlined .land-avatar__avatar
      border-width: thin
      border-style: solid

  .theme--light .land-avatar--outlined .land-avatar__avatar
    border-color: rgba(0, 0, 0, .12) !important

  .theme--dark .land-avatar--outlined .land-avatar__avatar
    border-color: rgba(255, 255, 255, .12) !important
</style>
