<template>
  <v-sheet
    :color="backColor"
  >
    <v-simple-table
      style="background-color: inherit;"
    >
      <template #default>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="`inform_${index}`"
          >
            <td class="pa-3">
              <a
                class="text-md-subtitle-1 text-sm-subtitle-2 blue-grey--text darken-4"
                @click="onClick(item, index)"
              >
                {{ item.title }}
              </a>
            </td>
            <td class="grey--text lighten-3 text-right pa-3">
              {{ item.createTime }}
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-sheet>
</template>

<script>
  export default {
    name: 'LandInforms',
    props: {
      backColor: {
        type: String,
        default: 'white'
      },
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
    },
    data () {
      return {}
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>
