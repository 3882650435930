
import coUrl from '@/api/api/co.api.url.js'

const AlignTypes = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center'
}

const ColumnTypes = {
  EMPTY: '',
  SELECTION: 'selection',
  INDEX: 'index',
  EXPAND: 'expand',
  STATUS: 'status',
  IMAGE: 'image',
  TAG: 'tag',
  TAGS: 'tags',
  BOOLEAN: 'boolean',
  POPOVER: 'popover',
  LINK: 'link'
}

const WidgetModes = {
  EDIT: 'edit',
  VIEW: 'view',
  READONLY: 'readonly'
}

const WidgetTypes = {
  INPUT: 'input',
  TEXTAREA: 'textarea',
  TAGS: 'tags',
  FILES: 'files',
  LABEL: 'label',
  STATUS: 'status',
  TABLE: 'table',
  SELECT: 'select',
  AVATAR: 'avatar',
  BUTTON: 'button'
}

const RapidKeys = {
  YES: 'rk-yes',
  NO: 'rk-no',
  NEW: 'rk-new',
  EDIT: 'rk-edit',
  VIEW: 'rk-view',
  SAVE: 'rk-save',
  DELETE: 'rk-delete',
  SEARCH: 'rk-search',
  RESET: 'rk-reset',
  EXPORT: 'rk-export',
  EXPORT_EXCEL: 'rk-export-excel',
  EXPORT_CSV: 'rk-export-csv',
  ISSUE: 'rk-issue',
  REVIEW1: 'rk-review1',
  REVIEW2: 'rk-review2',
  SUBMIT: 'rk-submit',
  VERIFY: 'rk-verify',
  ALLOT: 'rk-allot',
  ALLOT_MULTI: 'rk-allot-multi',
  AGREE: 'rk-agree',
  REJECT: 'rk-reject',
  UP: 'rk-up',
  DOWN: 'rk-down'
}

const RapidTypes = {
  EMPTY: '',
  PRIMARY: 'primary',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DANGER: 'danger'
}

const RapidSizes = {
  MEDIUM: 'medium',
  SMALL: 'small',
  MINI: 'mini'
}

const RapidIcons = {
  EMPTY: '',
  EDIT: 'el-icon-edit',
  NEW: 'el-icon-plus',
  DELETE: 'el-icon-delete',
  VERIFY: 'el-icon-finished',
  SEARCH: 'el-icon-search',
  CHECK: 'el-icon-check',
  REJECT: 'el-icon-close',
  VIEW: 'el-icon-view',
  DOWN: 'el-icon-bottom',
  UP: 'el-icon-top'
}

const DefRapidItem = {
  visible: true,
  disabled: false,
  loading: false,
  plain: true,
  icon: '',
  type: RapidTypes.PRIMARY,
  key: '',
  size: RapidSizes.MEDIUM,
  circle: false,
  caption: '按钮'
}

const RapidItems = [
  {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    circle: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.SUCCESS,
    key: RapidKeys.YES,
    size: RapidSizes.MEDIUM,
    caption: '是'
  },
  {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.WARNING,
    key: RapidKeys.NO,
    size: RapidSizes.MEDIUM,
    caption: '否'
  },
  {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.SEARCH,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.SEARCH,
    size: RapidSizes.MEDIUM,
    caption: '查询'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.EMPTY,
    key: RapidKeys.RESET,
    size: RapidSizes.MEDIUM,
    caption: '重置'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.NEW,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.NEW,
    size: RapidSizes.MEDIUM,
    caption: '增加'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.SAVE,
    size: RapidSizes.MEDIUM,
    caption: '保存'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.REVIEW1,
    size: RapidSizes.MEDIUM,
    caption: '评审'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.VERIFY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.VERIFY,
    size: RapidSizes.MEDIUM,
    caption: '核实'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.ALLOT,
    size: RapidSizes.MEDIUM,
    caption: '分配'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.ALLOT_MULTI,
    size: RapidSizes.MEDIUM,
    caption: '分配'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.REVIEW2,
    size: RapidSizes.MEDIUM,
    caption: '评审'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.SUBMIT,
    size: RapidSizes.MEDIUM,
    caption: '提交'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.EDIT,
    type: RapidTypes.EMPTY,
    key: RapidKeys.EDIT,
    size: RapidSizes.MEDIUM,
    caption: '编辑'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: false,
    icon: RapidIcons.VIEW,
    type: RapidTypes.EMPTY,
    key: RapidKeys.VIEW,
    size: RapidSizes.MEDIUM,
    caption: '查看'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.DELETE,
    type: RapidTypes.DANGER,
    key: RapidKeys.DELETE,
    size: RapidSizes.MEDIUM,
    caption: '删除'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.PRIMARY,
    key: RapidKeys.ISSUE,
    size: RapidSizes.MEDIUM,
    caption: '下发'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.EMPTY,
    key: RapidKeys.EXPORT,
    size: RapidSizes.MEDIUM,
    caption: '导出'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.EMPTY,
    key: RapidKeys.EXPORT_EXCEL,
    size: RapidSizes.MEDIUM,
    caption: '导出到Excel'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.EMPTY,
    type: RapidTypes.EMPTY,
    key: RapidKeys.EXPORT_CSV,
    size: RapidSizes.MEDIUM,
    caption: '导出到Text'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.CHECK,
    type: RapidTypes.SUCCESS,
    key: RapidKeys.CHECK,
    size: RapidSizes.MEDIUM,
    caption: '同意'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.REJECT,
    type: RapidTypes.DANGER,
    key: RapidKeys.REJECT,
    size: RapidSizes.MEDIUM,
    caption: '退回'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.UP,
    type: RapidTypes.EMPTY,
    key: RapidKeys.UP,
    size: RapidSizes.MEDIUM,
    caption: '上移'
  }, {
    visible: true,
    disabled: false,
    loading: false,
    plain: true,
    icon: RapidIcons.DOWN,
    type: RapidTypes.EMPTY,
    key: RapidKeys.DOWN,
    size: RapidSizes.MEDIUM,
    caption: '下移'
  }
]

const apiRapid = {
  RAPID_KEYS: RapidKeys,
  RAPID_TYPES: RapidTypes,
  RAPID_SIZES: RapidSizes,
  RAPID_ICONS: RapidIcons,
  RAPID_ITEMS: RapidItems,
  WIDGET_TYPES: WidgetTypes,
  WIDGET_MODES: WidgetModes,
  COLUMN_TYPES: ColumnTypes,
  ALIGN_TYPES: AlignTypes,

  create (rapidKey) {
    let rapid = null
    for (const i in RapidItems) {
      const item = RapidItems[i]
      if (item.key === rapidKey) {
        rapid = { ...item }
        break
      }
    }

    if (!rapid) {
      rapid = { ...DefRapidItem }
      rapid.key = rapidKey
    }

    return rapid
  },
  createTableColumn (title, key, type = '', width = '') {
    const col = {
      title,
      key,
      type,
      width,
      minwidth: '',
      align: 'left',
      sortable: false,
      overflow: false,
      enabledExport: true,
      style: '',
      activeName: ''
    }

    return col
  },
  createColumn ({
    text,
    key,
    type = '',
    width = '100',
    hint = '',
    align = 'left',
    sortable = false,
    overflow = false,
    visible = true
  }) {
    const _col = {
      text,
      key,
      type,
      width,
      hint,
      minwidth: '',
      align,
      sortable,
      overflow,
      visible,
      enabledExport: true,
      style: '',
      activeName: ''
    }

    return _col
  },
  createFormItem ({ title, key, type = '', visible = true, holder = '', maxlength = '', minlength = '', showWordLimit = false }) {
    const extendAttrs = {
      [WidgetTypes.AVATAR]: {
        action: `${coUrl.OSS_RES_UPLOAD}?type=image`,
        accept: '.jpg,.jpeg,.png,.gif',
        handleSuccess: null,
        beforeUpload: null
      },
      [WidgetTypes.TAGS]: {
        options: {
          closable: false,
          showEdit: false,
          value: '',
          hint: '+ 增加标签'
        }
      },
      [WidgetTypes.TEXTAREA]: {
        placeholder: holder || `请输入${title}`,
        value: '',
        model: 'textarea',
        rows: 4
      },
      [WidgetTypes.SELECT]: {
        placeholder: holder || `请选择${title}`,
        options: []
      },
      [WidgetTypes.INPUT]: {
        placeholder: holder || `请输入${title}`,
        value: '',
        model: 'text'
      }
    }

    const _item = {
      title,
      key,
      type,
      visible,
      maxlength,
      minlength,
      showWordLimit,
      width: '120'
    }
    if (type) {
      const _extend = extendAttrs[type]
      if (_extend) {
        Object.assign(_item, _extend)
      }
    }
    // console.log('createFormItem, key=%o, item: %o', key, _item)
    return _item
  },
  createSheetItem (title, key, type, visible = true, holder = '') {
    const _item = {
      title,
      key,
      type,
      visible,
      disalbed: false,
      width: '120'
    }

    switch (type) {
      case WidgetTypes.AVATAR:
        _item.action = `${coUrl.OSS_RES_UPLOAD}?type=image`
        _item['accept'] = '.jpg,.jpeg,.png,.gif'
        _item['handleSuccess'] = null
        _item['beforeUpload'] = null
        break
      case WidgetTypes.TAGS:
        _item['options'] = {
          closable: false,
          showEdit: false,
          value: '',
          hint: '+ 增加标签'
        }
        break
      case WidgetTypes.TEXTAREA:
        _item['placeholder'] = holder || `请输入${title}`
        _item['rows'] = '4'
        _item['maxlength'] = '250'
        _item['showWordLimit'] = true
        break
      case WidgetTypes.SELECT:
        _item['options'] = []
        _item['placeholder'] = holder || `请选择${title}`
        break
      case WidgetTypes.INPUT:
        _item['placeholder'] = holder || `请输入${title}`
        break
    }
    return _item
  }
}

export default apiRapid
