
const paddings = {
  p0: 'co-p0',
  p1: 'co-p1',
  p2: 'co-p2',
  p3: 'co-p3',
  p4: 'co-p4',
  p5: 'co-p5',
  p6: 'co-p6',
  p7: 'co-p7',
  p8: 'co-p8',
  py0: 'co-py0',
  py1: 'co-py1',
  py2: 'co-py2',
  py3: 'co-py3',
  py4: 'co-py4',
  py5: 'co-py5',
  py6: 'co-py6',
  py7: 'co-py7',
  py8: 'co-py8',
  px0: 'co-px0',
  px1: 'co-px1',
  px2: 'co-px2',
  px3: 'co-px3',
  px4: 'co-px4',
  px5: 'co-px5',
  px6: 'co-px6',
  px7: 'co-px7',
  px8: 'co-px8',
  pl0: 'co-pl0',
  pl1: 'co-pl1',
  pl2: 'co-pl2',
  pl3: 'co-pl3',
  pl4: 'co-pl4',
  pl5: 'co-pl5',
  pl6: 'co-pl6',
  pl7: 'co-pl7',
  pl8: 'co-pl8',
  pr0: 'co-pr0',
  pr1: 'co-pr1',
  pr2: 'co-pr2',
  pr3: 'co-pr3',
  pr4: 'co-pr4',
  pr5: 'co-pr5',
  pr6: 'co-pr6',
  pr7: 'co-pr7',
  pr8: 'co-pr8',
  pt0: 'co-pt0',
  pt1: 'co-pt1',
  pt2: 'co-pt2',
  pt3: 'co-pt3',
  pt4: 'co-pt4',
  pt5: 'co-pt5',
  pt6: 'co-pt6',
  pt7: 'co-pt7',
  pt8: 'co-pt8',
  pb0: 'co-pb0',
  pb1: 'co-pb1',
  pb2: 'co-pb2',
  pb3: 'co-pb3',
  pb4: 'co-pb4',
  pb5: 'co-pb5',
  pb6: 'co-pb6',
  pb7: 'co-pb7',
  pb8: 'co-pb8',
}

const margins = {
  m0: 'co-m0',
  m1: 'co-m1',
  m2: 'co-m2',
  m3: 'co-m3',
  m4: 'co-m4',
  m5: 'co-m5',
  m6: 'co-m6',
  m7: 'co-m7',
  m8: 'co-m8',
  my0: 'co-my0',
  my1: 'co-my1',
  my2: 'co-my2',
  my3: 'co-my3',
  my4: 'co-my4',
  my5: 'co-my5',
  my6: 'co-my6',
  my7: 'co-my7',
  my8: 'co-my8',
  mx0: 'co-mx0',
  mx1: 'co-mx1',
  mx2: 'co-mx2',
  mx3: 'co-mx3',
  mx4: 'co-mx4',
  mx5: 'co-mx5',
  mx6: 'co-mx6',
  mx7: 'co-mx7',
  mx8: 'co-mx8',
  ml0: 'co-ml0',
  ml1: 'co-ml1',
  ml2: 'co-ml2',
  ml3: 'co-ml3',
  ml4: 'co-ml4',
  ml5: 'co-ml5',
  ml6: 'co-ml6',
  ml7: 'co-ml7',
  ml8: 'co-ml8',
  mr0: 'co-mr0',
  mr1: 'co-mr1',
  mr2: 'co-mr2',
  mr3: 'co-mr3',
  mr4: 'co-mr4',
  mr5: 'co-mr5',
  mr6: 'co-mr6',
  mr7: 'co-mr7',
  mr8: 'co-mr8',
  mt0: 'co-mt0',
  mt1: 'co-mt1',
  mt2: 'co-mt2',
  mt3: 'co-mt3',
  mt4: 'co-mt4',
  mt5: 'co-mt5',
  mt6: 'co-mt6',
  mt7: 'co-mt7',
  mt8: 'co-mt8',
  mb0: 'co-mb0',
  mb1: 'co-mb1',
  mb2: 'co-mb2',
  mb3: 'co-mb3',
  mb4: 'co-mb4',
  mb5: 'co-mb5',
  mb6: 'co-mb6',
  mb7: 'co-mb7',
  mb8: 'co-mb8',
}

const foreground = {
  opacitys: {
    o0: 'co-text-opacity0',
    o10: 'co-text-opacity10',
    o20: 'co-text-opacity20',
    o30: 'co-text-opacity30',
    o40: 'co-text-opacity40',
    o50: 'co-text-opacity50',
    o60: 'co-text-opacity60',
    o70: 'co-text-opacity70',
    o80: 'co-text-opacity80',
    o90: 'co-text-opacity90',
    o100: 'co-text-opacity100'
  },
  colors: {
    transparent: 'co-text-transparent',
    black: 'co-text-black',
    white: 'co-text-white',
    major: 'co-text-major',
    minor: 'co-text-minor',
    assist: 'co-text-assist',
    success: 'co-text-success',
    info: 'co-text-info',
    warning: 'co-text-warning',
    error: 'co-text-error',
    message: 'co-text-message',
    gray: 'co-text-gray-500',
    gray100: 'co-text-gray-100',
    gray200: 'co-text-gray-200',
    gray300: 'co-text-gray-300',
    gray400: 'co-text-gray-400',
    gray500: 'co-text-gray-500',
    gray600: 'co-text-gray-600',
    gray700: 'co-text-gray-700',
    gray800: 'co-text-gray-800',
    gray900: 'co-text-gray-900',
    red: 'co-text-red-500',
    red100: 'co-text-red-100',
    red300: 'co-text-red-300',
    red500: 'co-text-red-500',
    red700: 'co-text-red-700',
    red900: 'co-text-red-900',
    blue: 'co-text-blue-500',
    blue100: 'co-text-blue-100',
    blue300: 'co-text-blue-300',
    blue500: 'co-text-blue-500',
    blue700: 'co-text-blue-700',
    blue900: 'co-text-blue-900',
    cyan: 'co-text-cyan-500',
    cyan100: 'co-text-cyan-100',
    cyan300: 'co-text-cyan-300',
    cyan500: 'co-text-cyan-500',
    cyan700: 'co-text-cyan-700',
    cyan900: 'co-text-cyan-900',
    green: 'co-text-green-500',
    green100: 'co-text-green-100',
    green300: 'co-text-green-300',
    green500: 'co-text-green-500',
    green700: 'co-text-green-700',
    green900: 'co-text-green-900',
    teal: 'co-text-teal-500',
    teal100: 'co-text-teal-100',
    teal300: 'co-text-teal-300',
    teal500: 'co-text-teal-500',
    teal700: 'co-text-teal-700',
    teal900: 'co-text-teal-900',
    orange: 'co-text-orange-500',
    orange100: 'co-text-orange-100',
    orange300: 'co-text-orange-300',
    orange500: 'co-text-orange-500',
    orange700: 'co-text-orange-700',
    orange900: 'co-text-orange-900',
    yellow: 'co-text-yellow-500',
    yellow100: 'co-text-yellow-100',
    yellow300: 'co-text-yellow-300',
    yellow500: 'co-text-yellow-500',
    yellow700: 'co-text-yellow-700',
    yellow900: 'co-text-yellow-900',
    purple: 'co-text-purple-500',
    purple100: 'co-text-purple-100',
    purple300: 'co-text-purple-300',
    purple500: 'co-text-purple-500',
    purple700: 'co-text-purple-700',
    purple900: 'co-text-purple-900',
    pink: 'co-text-pink-500',
    pink100: 'co-text-pink-100',
    pink300: 'co-text-pink-300',
    pink500: 'co-text-pink-500',
    pink700: 'co-text-pink-700',
    pink900: 'co-text-pink-900'
  }
}

const background = {
  opacitys: {
    o0: 'co-bg-opacity0',
    o10: 'co-bg-opacity10',
    o20: 'co-bg-opacity20',
    o30: 'co-bg-opacity30',
    o40: 'co-bg-opacity40',
    o50: 'co-bg-opacity50',
    o60: 'co-bg-opacity60',
    o70: 'co-bg-opacity70',
    o80: 'co-bg-opacity80',
    o90: 'co-bg-opacity90',
    o100: 'co-bg-opacity100'
  },
  colors: {
    transparent: 'co-bg-transparent',
    black: 'co-bg-black',
    white: 'co-bg-white',
    major: 'co-bg-major',
    minor: 'co-bg-minor',
    assist: 'co-bg-assist',
    success: 'co-bg-success',
    info: 'co-bg-info',
    warning: 'co-bg-warning',
    error: 'co-bg-error',
    message: 'co-bg-message',
    gray: 'co-bg-gray-500',
    gray100: 'co-bg-gray-100',
    gray200: 'co-bg-gray-200',
    gray300: 'co-bg-gray-300',
    gray400: 'co-bg-gray-400',
    gray500: 'co-bg-gray-500',
    gray600: 'co-bg-gray-600',
    gray700: 'co-bg-gray-700',
    gray800: 'co-bg-gray-800',
    gray900: 'co-bg-gray-900',
    red: 'co-bg-red-500',
    red100: 'co-bg-red-100',
    red300: 'co-bg-red-300',
    red500: 'co-bg-red-500',
    red700: 'co-bg-red-700',
    red900: 'co-bg-red-900',
    blue: 'co-bg-blue-500',
    blue100: 'co-bg-blue-100',
    blue300: 'co-bg-blue-300',
    blue500: 'co-bg-blue-500',
    blue700: 'co-bg-blue-700',
    blue900: 'co-bg-blue-900',
    cyan: 'co-bg-cyan-500',
    cyan100: 'co-bg-cyan-100',
    cyan300: 'co-bg-cyan-300',
    cyan500: 'co-bg-cyan-500',
    cyan700: 'co-bg-cyan-700',
    cyan900: 'co-bg-cyan-900',
    green: 'co-bg-green-500',
    green100: 'co-bg-green-100',
    green300: 'co-bg-green-300',
    green500: 'co-bg-green-500',
    green700: 'co-bg-green-700',
    green900: 'co-bg-green-900',
    teal: 'co-bg-teal-500',
    teal100: 'co-bg-teal-100',
    teal300: 'co-bg-teal-300',
    teal500: 'co-bg-teal-500',
    teal700: 'co-bg-teal-700',
    teal900: 'co-bg-teal-900',
    orange: 'co-bg-orange-500',
    orange100: 'co-bg-orange-100',
    orange300: 'co-bg-orange-300',
    orange500: 'co-bg-orange-500',
    orange700: 'co-bg-orange-700',
    orange900: 'co-bg-orange-900',
    yellow: 'co-bg-yellow-500',
    yellow100: 'co-bg-yellow-100',
    yellow300: 'co-bg-yellow-300',
    yellow500: 'co-bg-yellow-500',
    yellow700: 'co-bg-yellow-700',
    yellow900: 'co-bg-yellow-900',
    purple: 'co-bg-purple-500',
    purple100: 'co-bg-purple-100',
    purple300: 'co-bg-purple-300',
    purple500: 'co-bg-purple-500',
    purple700: 'co-bg-purple-700',
    purple900: 'co-bg-purple-900',
    pink: 'co-bg-pink-500',
    pink100: 'co-bg-pink-100',
    pink300: 'co-bg-pink-300',
    pink500: 'co-bg-pink-500',
    pink700: 'co-bg-pink-700',
    pink900: 'co-bg-pink-900'
  }
}

const border = {
  types: {
    solid: 'co-border-solid',
    dot: 'co-border-dot',
    dash: 'co-border-dash',
    double: 'co-border-double'
  },
  colors: {
    transparent: 'co-border-transparent',
    black: 'co-border-black',
    white: 'co-border-white',
    major: 'co-border-major',
    minor: 'co-border-minor',
    assist: 'co-border-assist',
    success: 'co-border-success',
    info: 'co-border-info',
    warning: 'co-border-warning',
    error: 'co-border-error',
    message: 'co-border-message',
    gray: 'co-border-gray-500',
    gray100: 'co-border-gray-100',
    gray200: 'co-border-gray-200',
    gray300: 'co-border-gray-300',
    gray400: 'co-border-gray-400',
    gray500: 'co-border-gray-500',
    gray600: 'co-border-gray-600',
    gray700: 'co-border-gray-700',
    gray800: 'co-border-gray-800',
    gray900: 'co-border-gray-900',
    red: 'co-border-red-500',
    red100: 'co-border-red-100',
    red300: 'co-border-red-300',
    red500: 'co-border-red-500',
    red700: 'co-border-red-700',
    red900: 'co-border-red-900',
    blue: 'co-border-blue-500',
    blue100: 'co-border-blue-100',
    blue300: 'co-border-blue-300',
    blue500: 'co-border-blue-500',
    blue700: 'co-border-blue-700',
    blue900: 'co-border-blue-900',
    cyan: 'co-border-cyan-500',
    cyan100: 'co-border-cyan-100',
    cyan300: 'co-border-cyan-300',
    cyan500: 'co-border-cyan-500',
    cyan700: 'co-border-cyan-700',
    cyan900: 'co-border-cyan-900',
    green: 'co-border-green-500',
    green100: 'co-border-green-100',
    green300: 'co-border-green-300',
    green500: 'co-border-green-500',
    green700: 'co-border-green-700',
    green900: 'co-border-green-900',
    teal: 'co-border-teal-500',
    teal100: 'co-border-teal-100',
    teal300: 'co-border-teal-300',
    teal500: 'co-border-teal-500',
    teal700: 'co-border-teal-700',
    teal900: 'co-border-teal-900',
    orange: 'co-border-orange-500',
    orange100: 'co-border-orange-100',
    orange300: 'co-border-orange-300',
    orange500: 'co-border-orange-500',
    orange700: 'co-border-orange-700',
    orange900: 'co-border-orange-900',
    yellow: 'co-border-yellow-500',
    yellow100: 'co-border-yellow-100',
    yellow300: 'co-border-yellow-300',
    yellow500: 'co-border-yellow-500',
    yellow700: 'co-border-yellow-700',
    yellow900: 'co-border-yellow-900',
    purple: 'co-border-purple-500',
    purple100: 'co-border-purple-100',
    purple300: 'co-border-purple-300',
    purple500: 'co-border-purple-500',
    purple700: 'co-border-purple-700',
    purple900: 'co-border-purple-900',
    pink: 'co-border-pink-500',
    pink100: 'co-border-pink-100',
    pink300: 'co-border-pink-300',
    pink500: 'co-border-pink-500',
    pink700: 'co-border-pink-700',
    pink900: 'co-border-pink-900'
  },
  opacitys: {
    o0: 'co-border-opacity0',
    o10: 'co-border-opacity10',
    o20: 'co-border-opacity20',
    o30: 'co-border-opacity30',
    o40: 'co-border-opacity40',
    o50: 'co-border-opacity50',
    o60: 'co-border-opacity60',
    o70: 'co-border-opacity70',
    o80: 'co-border-opacity80',
    o90: 'co-border-opacity90',
    o100: 'co-border-opacity100'
  },
  outlines: {
    none: 'co-border0',
    all: 'co-border',
    top: 'co-border-t',
    left: 'co-border-l',
    right: 'co-border-r',
    bottom: 'co-border-b'
  }
}

const grid = {
  gaps: {
    g0: 'co-gap0',
    g1: 'co-gap1',
    g2: 'co-gap2',
    g3: 'co-gap3',
    g4: 'co-gap4',
    g5: 'co-gap5',
    g6: 'co-gap6',
    g7: 'co-gap7',
    g8: 'co-gap8',
    g9: 'co-gap9',
    g10: 'co-gap10'
  },
  cols: {
    c1: 'co-grid-cols1',
    c2: 'co-grid-cols2',
    c3: 'co-grid-cols3',
    c4: 'co-grid-cols4',
    c5: 'co-grid-cols5',
    c6: 'co-grid-cols6'
  },
  rows: {
    r1: 'co-grid-rows1',
    r2: 'co-grid-rows2',
    r3: 'co-grid-rows3',
    r4: 'co-grid-rows4',
    r5: 'co-grid-rows5',
    r6: 'co-grid-rows6'
  },
  spans: {
    none: '',
    s1: 'co-col-span1',
    s2: 'co-col-span2',
    s3: 'co-col-span3',
    s4: 'co-col-span4',
    s5: 'co-col-span5',
    s6: 'co-col-span6',
    s7: 'co-col-span7',
    s8: 'co-col-span8',
    s9: 'co-col-span9',
    s10: 'co-col-span10',
    s11: 'co-col-span11',
    s12: 'co-col-span12',
  }
}

const shadows = {
  none: '',
  xs: 'co-shadow-xs',
  sm: 'co-shadow-sm',
  md: 'co-shadow-md',
  lg: 'co-shadow-lg',
  xl: 'co-shadow-xl',
  xl2: 'co-shadow-2xl'
}

const colours = {
  red: '#E53E3E',
  blue: '#2196F3',
  orange: '#ff9800',
  cyan: '#00BCD4',
  teal: '#009688',
  green: '#4CAF50',
  pink: '#ED64A6',
  purple: '#9F7AEA',
  gray: '#4a5568',
  black: '#1a202c',
  white: '#ffffff',
  yellow: '#FDD835'
}

const images = {
  sm21: 'co-sm-ratio21',
  sm53: 'co-sm-ratio53',
  sm32: 'co-sm-ratio32',
  sm43: 'co-sm-ratio43',
  sm11: 'co-sm-ratio11',
  sm169: 'co-sm-ratio169',
  md21: 'co-md-ratio21',
  md53: 'co-md-ratio53',
  md32: 'co-md-ratio32',
  md43: 'co-md-ratio43',
  md11: 'co-md-ratio11',
  md169: 'co-md-ratio169',
  lg21: 'co-lg-ratio21',
  lg53: 'co-lg-ratio53',
  lg32: 'co-lg-ratio32',
  lg43: 'co-lg-ratio43',
  lg11: 'co-lg-ratio11',
  lg169: 'co-lg-ratio169',
  xl21: 'co-xl-ratio21',
  xl53: 'co-xl-ratio53',
  xl32: 'co-xl-ratio32',
  xl43: 'co-xl-ratio43',
  xl11: 'co-xl-ratio11',
  xl169: 'co-xl-ratio169'
}

const appStyle = {
  colours,
  grid,
  shadows,
  effects: {
    dark: {
      default: {
        icon: 'white',
        fore: foreground.colors.white,
        back: background.colors.info,
        border: border.colors.info,
      },
      icon: {
        success: 'white',
        warning: 'white',
        info: 'white',
        error: 'white',
        white: 'gray500'
      },
      fore: {
        success: foreground.colors.white,
        warning: foreground.colors.white,
        info: foreground.colors.white,
        error: foreground.colors.white,
        white: foreground.colors.gray900
      },
      back: {
        success: background.colors.success,
        warning: background.colors.warning,
        info: background.colors.info,
        error: background.colors.error,
        message: background.colors.message,
        black: background.colors.black,
        gray: background.colors.gray,
        red: background.colors.red,
        blue: background.colors.blue,
        purple: background.colors.purple,
        pink: background.colors.pink,
        green: background.colors.green,
        orange: background.colors.orange,
        yellow: background.colors.yellow700,
        white: background.colors.white,
        cyan: background.colors.cyan,
        teal: background.colors.teal
      },
      border: {
        success: border.colors.success,
        warning: border.colors.warning,
        info: border.colors.info,
        error: border.colors.error,
        message: border.colors.message,
        black: border.colors.black,
        gray: border.colors.gray,
        red: border.colors.red,
        blue: border.colors.blue,
        purple: border.colors.purple,
        pink: border.colors.pink,
        green: border.colors.green,
        orange: border.colors.orange,
        yellow: border.colors.yellow,
        white: border.colors.white,
        cyan: border.colors.cyan,
        teal: border.colors.teal
      }
    },
    light: {
      default: {
        icon: 'info',
        fore: foreground.colors.info,
        back: background.colors.blue100,
        border: border.colors.info,
      },
      icon: {
        success: 'success',
        warning: 'warning',
        info: 'info',
        error: 'error',
        message: 'message',
        gray: 'gray',
        red: 'red',
        blue: 'blue',
        purple: 'purple',
        pink: 'pink',
        green: 'green',
        orange: 'orange',
        yellow: 'yellow',
        cyan: 'cyan',
        teal: 'teal'
      },
      fore: {
        success: foreground.colors.success,
        warning: foreground.colors.warning,
        info: foreground.colors.info,
        error: foreground.colors.error,
        message: foreground.colors.message,
        black: foreground.colors.black,
        gray: foreground.colors.gray,
        red: foreground.colors.red,
        blue: foreground.colors.blue,
        purple: foreground.colors.purple,
        pink: foreground.colors.pink,
        green: foreground.colors.green,
        orange: foreground.colors.orange,
        yellow: foreground.colors.yellow700,
        cyan: foreground.colors.cyan,
        teal: foreground.colors.teal
      },
      back: {
        success: background.colors.green100,
        warning: background.colors.yellow100,
        info: background.colors.blue100,
        error: background.colors.red100,
        message: background.colors.gray200,
        black: background.colors.gray100,
        gray: background.colors.gray100,
        red: background.colors.red100,
        blue: background.colors.blue100,
        purple: background.colors.purple100,
        pink: background.colors.pink100,
        green: background.colors.green100,
        orange: background.colors.orange100,
        yellow: background.colors.yellow100,
        cyan: background.colors.cyan100,
        teal: background.colors.teal100
      },
      border: {
        success: border.colors.success,
        warning: border.colors.warning,
        info: border.colors.info,
        error: border.colors.error,
        message: border.colors.message,
        black: border.colors.black,
        gray: border.colors.gray,
        red: border.colors.red,
        blue: border.colors.blue,
        purple: border.colors.purple,
        pink: border.colors.pink,
        green: border.colors.green,
        orange: border.colors.orange,
        yellow: border.colors.yellow,
        cyan: border.colors.cyan,
        teal: border.colors.teal
      }
    },
    plain: {
      default: {
        icon: 'info',
        fore: foreground.colors.info,
        back: background.colors.white,
        border: border.colors.info,
      },
      icon: {
        success: 'success',
        warning: 'warning',
        info: 'info',
        error: 'error',
        message: 'message',
        gray: 'gray',
        red: 'red',
        blue: 'blue',
        purple: 'purple',
        pink: 'pink',
        green: 'green',
        orange: 'orange',
        yellow: 'yellow',
        cyan: 'cyan',
        teal: 'teal'
      },
      fore: {
        success: foreground.colors.success,
        warning: foreground.colors.warning,
        info: foreground.colors.info,
        error: foreground.colors.error,
        message: foreground.colors.message,
        black: foreground.colors.black,
        gray: foreground.colors.gray,
        red: foreground.colors.red,
        blue: foreground.colors.blue,
        purple: foreground.colors.purple,
        pink: foreground.colors.pink,
        green: foreground.colors.green,
        orange: foreground.colors.orange,
        yellow: foreground.colors.yellow700,
        cyan: foreground.colors.cyan,
        teal: foreground.colors.teal
      },
      back: {
        success: background.colors.white,
        warning: background.colors.white,
        info: background.colors.white,
        error: background.colors.white,
      },
      border: {
        success: border.colors.success,
        warning: border.colors.warning,
        info: border.colors.info,
        error: border.colors.error,
        message: border.colors.message,
        black: border.colors.black,
        gray: border.colors.gray,
        red: border.colors.red,
        blue: border.colors.blue,
        purple: border.colors.purple,
        pink: border.colors.pink,
        green: border.colors.green,
        orange: border.colors.orange,
        yellow: border.colors.yellow,
        cyan: border.colors.cyan,
        teal: border.colors.teal
      }
    }
  },
  flex: {
    direction: {
      col: 'co-flex-col',
      row: 'co-flex-row'
    },
    justify: {
      start: 'co-justify-start',
      center: 'co-justify-center',
      end: 'co-justify-end',
      between: 'co-justify-between',
      around: 'co-justify-around'
    },
    items: {
      start: 'co-items-start',
      end: 'co-items-end',
      center: 'co-items-center'
    }
  },
  text: {
    sizes: {
      xs2: 'co-text-2xs',
      xs: 'co-text-xs',
      sm: 'co-text-sm',
      md: 'co-text-md',
      lg: 'co-text-lg',
      xl: 'co-text-xl',
      xl2: 'co-text-2xl',
      xl3: 'co-text-3xl',
      xl4: 'co-text-4xl',
    },
    weights: {
      w200: 'co-font-200',
      w400: 'co-font-400',
      w600: 'co-font-600',
      w800: 'co-font-800',
      thin: 'co-font-200',
      normal: 'co-font-400',
      bold: 'co-font-600',
      black: 'co-font-800'
    },
  },
  icosizes: {
    s40: 'co-icon40',
    s48: 'co-icon48',
    s56: 'co-icon56',
    s64: 'co-icon64',
    s72: 'co-icon72',
    s80: 'co-icon80',
    s88: 'co-icon88',
    s96: 'co-icon96',
    s120: 'co-icon120',
    s160: 'co-icon160',
    s200: 'co-icon200',
    s280: 'co-icon280',
  },
  images,
  // percents width,height 百分比等级
  percents: {
    p100: 'co-per100',
    p80: 'co-per80',
    p75: 'co-per75',
    p70: 'co-per70',
    p60: 'co-per60',
    p50: 'co-per50',
    p40: 'co-per40',
    p30: 'co-per30',
    p25: 'co-per25'
  },
  opacitys: {
    o0: 'co-opacity0',
    o10: 'co-opacity10',
    o20: 'co-opacity20',
    o30: 'co-opacity30',
    o40: 'co-opacity40',
    o50: 'co-opacity50',
    o60: 'co-opacity60',
    o70: 'co-opacity70',
    o80: 'co-opacity80',
    o90: 'co-opacity90',
    o100: 'co-opacity100'
  },
  rounds: {
    none: 'co-round0',
    sm: 'co-round1',
    md: 'co-round4',
    lg: 'co-round8',
    xl: 'co-round10',
    r0: 'co-round0',
    r1: 'co-round1',
    r2: 'co-round2',
    r3: 'co-round3',
    r4: 'co-round4',
    r5: 'co-round5',
    r6: 'co-round6',
    r7: 'co-round7',
    r8: 'co-round8',
    r9: 'co-round9',
    r10: 'co-round10',
    r50: 'co-round50',
    full: 'co-round-full',
  },
  margins,
  paddings,
  back: background,
  fore: foreground,
  border,
  heights: {
    sm: 'co-h72',
    md: 'co-h88',
    lg: 'co-h120',
    xl: 'co-h160',
    full: 'co-h-full',
    auto: 'co-h-auto',
    h0: 'co-h0',
    h2: 'co-h2',
    h4: 'co-h4',
    h8: 'co-h8',
    h12: 'co-h12',
    h16: 'co-h16',
    h20: 'co-h20',
    h24: 'co-h24',
    h32: 'co-h32',
    h40: 'co-h40',
    h48: 'co-h48',
    h56: 'co-h56',
    h64: 'co-h64',
    h72: 'co-h72',
    h80: 'co-h80',
    h88: 'co-h88',
    h96: 'co-h96',
    h120: 'co-h120',
    h160: 'co-h160',
    h200: 'co-h200',
    h220: 'co-h220',
    h240: 'co-h240',
    h260: 'co-h260',
    h280: 'co-h280',
    h300: 'co-h300',
  },
  widths: {
    full: 'co-w-full',
    auto: 'co-w-auto',
    w0: 'co-w0',
    w2: 'co-w2',
    w4: 'co-w4',
    w8: 'co-w8',
    w12: 'co-w12',
    w16: 'co-w16',
    w20: 'co-w20',
    w24: 'co-w24',
    w32: 'co-w32',
    w40: 'co-w40',
    w48: 'co-w48',
    w56: 'co-w56',
    w64: 'co-w64',
    w72: 'co-w72',
    w80: 'co-w80',
    w88: 'co-w88',
    w96: 'co-w96',
    w120: 'co-w120',
    w160: 'co-w160',
    w200: 'co-w200',
    w220: 'co-w220',
    w240: 'co-w240',
    w260: 'co-w260',
    w280: 'co-w280',
    w300: 'co-w300',
    w400: 'co-w400',
    w500: 'co-w500',
    w600: 'co-w600',
    w700: 'co-w700'
  },
  parseMargin (margin) {
    if (!margin) {
      return ''
    }

    const styles = []
    const tmp = margin.replace(/\s+/g, '')
    const arr = tmp.split('|')
    const vals = Array.from(new Set([...arr]))
    for (const i in vals) {
      const style = margins[vals[i]]
      if (style) {
        styles.push(style)
      }
    }
    if (styles.length) {
      return styles.join(' ')
    } else {
      return ''
    }
  },
  parsePadding (padding) {
    if (!padding) {
      return ''
    }

    const styles = []
    const tmp = padding.replace(/\s+/g, '')
    const arr = tmp.split('|')
    const vals = Array.from(new Set([...arr]))
    for (const i in vals) {
      const style = paddings[vals[i]]
      if (style) {
        styles.push(style)
      }
    }
    if (styles.length) {
      return styles.join(' ')
    } else {
      return ''
    }
  }
}

export default appStyle
