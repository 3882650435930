
import api from '@/api/co.api'
import app from '@/api/co.app'
import lib from '@/api/co.lib'

const Types = {
  // ACTIVITY_REGISTER 活动报名
  ACTIVITY_REGISTER: 'activity_eventreg',
  // PROJECT_REPORT 项目申报
  PROJECT_REPORT: 'activity_projectrep',
  // ARTICLE_COLLECT 文章征集
  ARTICLE_COLLECT: 'activity_articlecol',
  // ACTIVITY_SUMMARY 申报总结
  ACTIVITY_SUMMARY: 'activity_reportsummary'
}

const States = {
  NOTSTART: 0,
  PROCESSING: 1,
  END: 2
}

const ReportStates = {
  // CREATE 编制中
  CREATE: 'create',
  // VERIFING 领队核实(即下级提交文件给上级核实)
  VERIFING: 'verifing',
  // CHECKING 审核中
  CHECKING: 'checking',
  // ALLOCATION 待分配
  ALLOCATION: 'allocation',
  // REVIEWING 评审中
  REVIEWING: 'reviewing',
  // UNREPORT 取消上报（上级退回下级课题）
  UNREPORT: 'unreport',
  // SUCCESS 评审通过
  SUCCESS: 'success',
  // FAIL 评审不通过
  FAIL: 'fail',
  // FALLBACK 退回
  FALLBACK: 'fallback',
  // FINISH 结束
  FINISH: 'finish'
}

const ReportStateDescs = {
  [ReportStates.CREATE]: {
    text: '编制中',
    value: ReportStates.CREATE,
    effect: 'dark',
    type: 'black'
  },

  [ReportStates.VERIFING]: {
    text: '核实中',
    value: ReportStates.VERIFING,
    effect: 'dark',
    type: 'teal'
  },

  [ReportStates.CHECKING]: {
    text: '审核中',
    value: ReportStates.CHECKING,
    effect: 'dark',
    type: 'cyan'
  },

  [ReportStates.REVIEWING]: {
    text: '评审中',
    value: ReportStates.REVIEWING,
    effect: 'dark',
    type: 'blue'
  },

  [ReportStates.FALLBACK]: {
    text: '退回',
    value: ReportStates.FALLBACK,
    effect: 'dark',
    type: 'gray'
  },

  [ReportStates.ALLOCATION]: {
    text: '待分配',
    value: ReportStates.ALLOCATION,
    effect: 'dark',
    type: 'yellow'
  },

  [ReportStates.FINISH]: {
    text: '结束',
    value: ReportStates.FINISH,
    effect: 'dark',
    type: 'black'
  },

  [ReportStates.FAIL]: {
    text: '不通过',
    value: ReportStates.FAIL,
    effect: 'dark',
    type: 'red'
  },

  [ReportStates.SUCCESS]: {
    text: '通过',
    value: ReportStates.SUCCESS,
    effect: 'dark',
    type: 'green'
  }
}

const ReportTypes = {
  // 问题解决型
  SOLVE: 'solve',
  // 创新型
  INNOVATE: 'innovate'
}

const ReportTypeDescs = {
  [ReportTypes.SOLVE]: {
    text: '问题解决型',
    value: ReportTypes.SOLVE,
    effect: 'light',
    type: 'green'
  },
  [ReportTypes.INNOVATE]: {
    text: '创新型',
    value: ReportTypes.INNOVATE,
    effect: 'light',
    type: 'purple'
  }
}

const Labels = {}

const activityComm = {
  Types,
  States,
  Labels,
  ReportStates,
  ReportStateDescs,
  ReportTypes,
  ReportTypeDescs,
  extract () {
    const str = lib.local.get(app.keys.activity_info)
    let data = {}
    if (str) {
      data = JSON.parse(str)
    }
    this.config(data)
  },
  config (data) {
    if (!data) {
      return
    }

    const items = data.labels || {}
    for (const i in items) {
      Labels[i] = api.comm.deepClone(items[i])
    }
    // console.log('co.activity.comm config, Labels: %o', Labels)
  },
  reset (data) {
    if (data) {
      this.config(data)
      const value = JSON.stringify(data)
      lib.local.set(app.keys.activity_info, value)
    }
  },
  getLabels (type) {
    let result = Labels[type]
    if (!result) {
      result = {
        title: '活动报名',
        buttonTexts: ['即将开始', '立即报名', '活动结束'],
        buttonDisableds: [true, false, true],
        hintTitle: '报名期限：',
        detailTitle: '活动详情',
        myTitle: '我的报名',
        submitText: '立即报名',
        endHint: '活动报名已结束',
        type: Types.ACTIVITY_REGISTER,
        page: 'registration'
      }
    }

    return result
  }
}

export default activityComm
