<template>
  <v-hover
    v-slot="{ hover }"
    :disabled="!hovered"
    close-delay="120"
  >
    <v-card
      :elevation="hover ? hoverElevation : normalElevation"
      :class="[round, padding, 'transition-swing']"
      :color="backColor"
      flat
      tile
    >
      <v-img
        v-if="item.cover"
        :aspect-ratio="ratio"
        :src="item.cover"
        class="align-end"
        height="inherit"
        @click="onClick"
      />
    </v-card>
  </v-hover>
</template>

<script>
  export default {
    name: 'LandVoteCard',
    props: {
      item: {
        type: Object,
        default: () => {
          return {}
        }
      },
      ratio: {
        // 默认为 5:3
        type: [Number, String],
        default: 5
      },
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    methods: {
      onClick () {
        const params = {
          item: this.item
        }
        this.$emit('click', params)
      }
    }
  }
</script>
