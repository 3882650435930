
import api from '@/api/co.api'
import comm from '@/api/activity/co.activity.comm'

import dayjs from 'dayjs'

const activityEnroll = {
  getParams ({
    siteId,
    activityId,
    sort = 'createTime',
    order = 'desc',
    limit = '10',
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.UC_ENROLL_LIST,
      data: {
        siteId,
        activityId,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  convertItems (params, srcItems) {
    const actLabels = comm.Labels[comm.Types.ACTIVITY_REGISTER]

    for (const i in srcItems) {
      const item = srcItems[i]

      const title = item.activityTitle
      // let subtitle = item.activitySubtitle
      const createTime = dayjs(item.createTime).format('YYYY-MM-DD hh:mm')
      const startTime = dayjs(item.startTime, 'YYYY-MM-DD')
      const endTime = dayjs(item.endTime, 'YYYY-MM-DD')

      const userInfo = api.comm.jsonToObject(item.userInfo)

      const infos = []
      for (const key in userInfo) {
        const val = userInfo[key]
        if (val) {
          infos.push({
            text: userInfo[key],
            type: 'message'
          })
        }
      }

      if (item.remark) {
        infos.push({
          text: item.remark,
          type: 'message'
        })
      }

      params.items.push({
        title,
        subtitle: `活动期限：${startTime} ~ ${endTime}`,
        remark: `${actLabels.hintTitle}${createTime}`,
        activityId: item.activityId,
        userId: item.userId,
        siteId: item.siteId,
        userInfo,
        createTime,
        infos
      })
    }

    return params.items.length
  }
}

export default activityEnroll
