
const basePath = 'https://res.landcoo.com:1060'
const appName = 'njznzz'

const appBase = {
  prep: 'co.web',
  name: appName,
  title: '江北新区智能制造产业园',
  version: '1.0.0',
  url_base: basePath,
  res_url: `${basePath}/${appName}`,
  res_skin: `${basePath}/${appName}/skin/def`,
  h5_url: `${basePath}/${appName}/h5/`,
  res_url_def: `${basePath}/landcoo`,
  res_skin_def: `${basePath}/landcoo/skin/def`,
  office_url: 'http://office.landcoo.com:8012',
}

export default appBase
