<template>
  <land-section
    :color="backColor"
    :space="space"
  >
    <v-img
      v-if="header.cover"
      :src="header.cover"
      class="mx-auto mb-8"
      max-width="96"
    />

    <land-section-heading
      v-if="header.title"
      :title="header.title"
      :color="assistColor"
      :icon="header.cover ? '' : header.icon"
      :outlined="header.outlined"
    >
      {{ header.subtitle }}
    </land-section-heading>

    <v-container
      v-if="features.length"
      :fluid="header.fluid === true ? true : false"
    >
      <v-row
        class="mx-2"
        justify="center"
      >
        <v-col
          v-for="(feature, i) in features"
          :key="`feature_${i}`"
          cols="12"
          :md="mdSpans"
          sm="6"
        >
          <div class="d-flex flex-column justify-center align-center">
            <v-img
              v-if="feature.image"
              :src="feature.image"
              class="mb-3"
              width="72"
            />
            <land-avatar
              v-else-if="feature.icon"
              :icon-color="feature.iconColor"
              :icon="feature.icon"
              :outlined="feature.outlined === false ? false : true"
              size="72"
              class="mb-3"
            />

            <land-title
              :title="feature.title"
              class="text-uppercase"
              space="2"
            />

            <land-body
              v-if="feature.subtitle"
              :text="feature.subtitle"
              align="center"
              space="4"
            />
          </div>
        </v-col>
      </v-row>
      <div
        v-if="button.text"
        class="d-flex flex-row justify-center mt-8"
      >
        <v-btn
          depressed
          :color="button.backColor || 'white'"
          :class="[button.foreColor ? `${button.foreColor}--text` : 'primary--text']"
          :width="btnWidth"
          :x-large="btnXLarge"
          @click="onClick"
        >
          {{ button.text }}
        </v-btn>
      </div>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'LandIntroIconsVer',
    props: {
      space: {
        type: [Number, String],
        default: 80,
      },
      backColor: {
        type: String,
        default: 'white'
      },
      foreColor: {
        type: String,
        default: 'primary'
      },
      assistColor: {
        type: String,
        default: 'primary'
      },
      header: {
        type: Object,
        default: () => {
          return {}
        }
      },
      features: {
        type: Array,
        default: () => {
          return []
        }
      },
      button: {
        type: Object,
        default: () => {
          return {}
        }
      },
    },
    computed: {
      btnXLarge () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return false
        } else {
          return true
        }
      },
      btnWidth () {
        if (this.$vuetify.breakpoint.smAndDown) {
          return 140
        } else {
          return 180
        }
      },
      mdSpans () {
        let result = 4
        const cnt = this.features.length
        if (cnt < 3) {
          result = 6
        } else if (cnt === 3) {
          result = 4
        } else if (cnt === 4) {
          result = 3
        } else {
          result = 4
        }
        return result
      }
    },
    methods: {
      onClick () {
        this.$emit('action', this.button)
      }
    }
  }
</script>
