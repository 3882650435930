/**
 * 用于web端各种主题
 * 当前仅在web端使用
 */

import api from '@/api/co.api'
import coCate from '@/api/kind/co.kind.category'

/**
 * ThemeModels 表示主题显示样式
 */
const ThemeModels = {
  // icon 图标样式(横排)
  icon: 'theme-md-icon',
  // card 卡片样式
  card: 'theme-md-card',
  // text 文本样式
  text: 'theme-md-text',
  // base 基本样式(图标竖排)
  base: 'theme-md-base',

  iconsub: 'theme-md-iconsub',
  cardsub: 'theme-md-cardsub',
  textsub: 'theme-md-textsub',
  basesub: 'theme-md-basesub',
}

/**
 * ThemeTypes 表示主题内容显示那些页面内所定义的类型
 */
const ThemeTypes = {
  // home 表示首页
  home: 'theme-tp-home',
  // notice 表示新闻、资讯
  notice: 'theme-tp-notice',
  // content 表示商品
  content: 'theme-tp-content',
  // about 表示关于我们
  about: 'theme-tp-about',
  // contact 表示联系方式
  contact: 'theme-tp-contact',
  // service 表示服务、营销、市场
  service: 'theme-tp-service',
  // case 表示案例
  case: 'theme-tp-case',
  // material 资料、政策等
  material: 'theme-tp-material',
  // party 党建
  party: 'theme-tp-party',
  extend1: 'theme-tp-extend1'
}

const ThemeTargets = {
}

const kindTheme = {
  Models: ThemeModels,
  Types: ThemeTypes,
  Targets: ThemeTargets,
  getFeatures (theme = {}) {
    const parent = theme.header || {}
    const trigger = theme.trigger
    if (!parent.categoryId) {
      console.warn('co.kind.theme getFeatures, categoryId is null!')
      if (typeof trigger === 'function') {
        trigger(theme)
      }
      return
    }

    const caches = []
    const executed = function (res) {
      if (res.status) {
        for (const i in caches) {
          const item = caches[i]
          const classify = item.pattern.classify || {}
          const subtitle = item.pattern.subtitle || {}

          theme.features.push({
            categoryId: item.categoryId,
            parentId: item.parentId,
            siteId: item.siteId,
            model: item.model,
            type: item.type,
            target: item.target,
            title: item.name,
            subtitle: subtitle.value || '',
            remark: item.remark || '',
            cover: item.thumb || '',
            icon: classify.icon || '',
            dark: classify.dark === true,
            color: classify.color || '',
            callout: classify.callout || '',
            pattern: api.comm.deepClone(item.pattern)
          })
        } // for (const i in caches)
      }

      if (typeof trigger === 'function') {
        trigger(theme)
      }
    }
    const params = coCate.getParams({
      siteId: parent.siteId,
      parentId: parent.categoryId,
      caches,
      executed
    })

    api.http.getItems(params)
  },
  get ({
    siteId,
    type,
    model,
    target,
    executing = null,
    executed = null
  }) {
    const caches = []
    const result = {
      status: false,
      header: {},
      features: [],
      trigger: null
    }
    const processing = function () {
      if (typeof executing === 'function') {
        executing()
      }
    }

    const processed = function (res) {
      // console.log('co.kind.theme get, res: %o', res)
      result.status = res.status
      if (res.status) {
        if (caches.length) {
          const item = caches[0]
          const classify = item.pattern.classify || {}
          const subtitle = item.pattern.subtitle || {}

          result.header = {
            categoryId: item.categoryId,
            parentId: item.parentId,
            siteId: item.siteId,
            model: item.model,
            type: item.type,
            target: item.target,
            title: item.name,
            subtitle: subtitle.value || '',
            icon: classify.icon || '',
            cover: item.thumb || '',
            outlined: classify.outlined === true,
            pattern: api.comm.deepClone(item.pattern)
          }
          result.trigger = executed
          kindTheme.getFeatures(result)
        } else {
          result.status = false
          if (typeof executed === 'function') {
            executed(result)
          }
        }
      } else {
        if (typeof executed === 'function') {
          executed(result)
        }
      } // if (res.status) else
    } // const processed = function (res)

    const params = coCate.getParams({
      siteId,
      type,
      model,
      target,
      caches,
      executing: processing,
      executed: processed
    })

    api.http.getItems(params)
  }
}

export default kindTheme
