
import feed from '@/api/co.feed'

export default {
  data () {
    return {
      feedInfo: {},
      contact: {
        title: '给我们留言',
        buttonText: '提交留言',
        submiting: false
      },
      dlgHint: {
        name: 'dlgHint'
      }
    }
  },
  methods: {
    showDialogHint ({
      title,
      content,
      type = 'info',
      cancelText = '否',
      confirmText = '是',
      showCancel = false,
      cancel = null,
      confirm = null
    }) {
      const dlg = this.$refs[this.dlgHint.name]
      if (dlg) {
        dlg.show({
          title,
          content,
          type,
          cancelText,
          confirmText,
          showCancel,
          cancel,
          confirm
        })
      }
    },
    onSubmit (res) {
      // console.log('KeepInTouch onSubmit, res: %o', res)
      const me = this
      const data = {
        title: res.title,
        problemDesc: res.message,
        contactName: res.name,
        contactMobile: res.phone,
        siteId: this.$init.siteId,
        feedbackType: feed.Types.CONSULT
      }
      Object.assign(this.feedInfo, data)

      const executing = function () {
        me.contact.submiting = true
      }

      const executed = function (resp) {
        // console.log('home messsage save !  resp: %o', resp)
        me.contact.submiting = false
        if (resp.status) {
          me.feedInfo.feedbackId = resp.data.feedbackId
          me.feedInfo.createTime = resp.data.createTime
          me.showDialogHint({
            title: '提交成功',
            content: '留言提交成功，我们会第一时间联系你',
            confirmText: '好的',
            type: 'success'
          })
        } else {
          me.showDialogHint({
            title: '提交失败',
            content: '留言提交失败，请联系客服',
            confirmText: '好的',
            type: 'error'
          })
        }
      }

      feed.create({
        data,
        executing,
        executed
      })
    },
  }
}
