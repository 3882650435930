
const apiPage = {
  home: {
    path: '/home',
    name: 'home',
    title: '首页'
  },
  noticeDetail: {
    path: 'notice/detail',
    name: 'NoticeDetail',
    title: '资讯详情'
  },
  noticeList: {
    path: '/notice/list',
    name: 'NoticeList',
    title: '资讯列表'
  },
  activityDetail: {
    path: 'home/activity',
    name: 'ActivityDetail',
    title: '活动详情'
  },
  activityList: {
    path: 'home/activitys',
    name: 'ActivityList',
    title: '活动列表'
  },
  homeProblem: {
    path: 'home/problem',
    name: 'HomeProblem',
    title: '问卷详情'
  },
  registration: {
    path: 'home/registration',
    name: 'Registration',
    title: '活动报名'
  },
  reporting: {
    path: 'home/reporting',
    name: 'Reporting',
    title: '项目申报'
  },
  parkImages: {
    path: 'park/images',
    name: 'ParkImages',
    title: '园区图片'
  },
  partyNotices: {
    path: 'party/notices',
    name: 'PartyNotices',
    title: '智汇党建'
  },
  partyNotice: {
    path: 'party/notice',
    name: 'PartyNotice',
    title: '党建详情'
  },
  homePsqs: {
    path: 'home/psqs',
    name: 'HomePsqs',
    title: '问卷调查'
  },
  policyDetail: {
    path: '/policy/detail',
    name: 'PolicyDetail',
    title: '政策详情'
  },
  policyList: {
    path: '/policy/list',
    name: 'PolicyList',
    title: '政策列表'
  },
  discloseDetail: {
    path: '/disclose/detail',
    name: 'DiscloseDetail',
    title: '信息详情'
  },
  discloseList: {
    path: '/disclose/list',
    name: 'DiscloseList',
    title: '信息列表'
  },
  discloseResume: {
    path: '/disclose/resume',
    name: 'DiscloseResume',
    title: '领导分工'
  },
}

export default apiPage
