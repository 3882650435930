
import app from '@/api/co.app'
import cache from '@/api/config/co.config.cache'
import comm from '@/api/config/co.config.comm'

const configParser = {
  preset (item) {
    const me = cache
    if (!item) {
      return
    }
    if (!item.content) {
      return
    }
    try {
      const obj = JSON.parse(item.content)
      me.data[item.value] = obj
    } catch (err) {
      console.warn('co.config.parser preset, err: %o', err)
      console.warn('co.config.parser preset, item.content is not json!')
      console.warn('co.config.parser preset, item=%o', item)
    }
  },
  [comm.Keys.base]: function (item) {
    const me = cache
    if (!item) {
      return
    }
    if (!item.content) {
      return
    }
    try {
      const obj = JSON.parse(item.content)
      Object.assign(app.init, obj)
      obj.valid = true
      me.data[item.value] = obj
    } catch (e) {
      console.error('co.config.parser base, item.content is not json!')
      console.warn('co.config.parser base, item=%o', item)
    }
  },
  [comm.Keys.site]: function (item) {
    const me = cache
    if (!item) {
      return
    }
    if (!item.content) {
      return
    }
    try {
      const obj = JSON.parse(item.content)
      for (const _key in obj) {
        const _item = obj[_key]
        if (_item.visible === 'true' || _item.visible === true) {
          _item.visible = true
        } else {
          _item.visible = false
        }
      }
      obj.valid = true
      me.data[item.value] = obj
    } catch (e) {
      console.error('co.config.parser site, item.content is not json!')
      console.warn('co.config.parser site, item=%o', item)
    }
  },
}

export default configParser
