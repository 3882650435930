
import api from '@/api/co.api'
import app from '@/api/co.app'

import comm from '@/api/activity/co.activity.comm'
import enroll from '@/api/activity/co.activity.enroll'
import main from '@/api/activity/co.activity.main'
import report from '@/api/activity/co.activity.report'

const CFG_FILE_NAME = `land.${app.base.name}.activity.json`

const coActivity = {
  comm,
  enroll,
  main,
  report,
  initialize () {
    comm.extract()

    const executed = function (res) {
      if (res.status) {
        comm.reset(res.data)
      }
    }

    const params = {
      url: api.url.CMS_API_JSON,
      data: {
        fileName: CFG_FILE_NAME,
        fileType: 'json',
        dirName: 'json'
      },
      executed
    }

    api.http.getJson(params)
  },
}

export default coActivity
