<template>
  <v-fade-transition mode="out-in">
    <router-view />
  </v-fade-transition>
</template>

<script>
  import app from '@/api/co.app'
  import config from '@/api/co.config'
  import kind from '@/api/co.kind'
  import activity from '@/api/co.activity'

  export default {
    name: 'App',
    metaInfo: {
      title: 'App',
      titleTemplate: `%s | ${app.init.title}`,
      htmlAttrs: { lang: 'en' },
      meta: [
        { charset: 'utf-8' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      ],
    },
    created () {
      this.presetParams()
    },
    methods: {
      presetParams () {
        config.initialize({
          siteId: app.init.siteId,
          type: 'conf_site'
        })

        kind.initialize()
        activity.initialize()
      },
    }
  }
</script>

<style lang="scss">
  @import '~@/styles/land/co-style.scss';

  .extra-padding {
    padding-bottom: 96px !important;
    padding-top: 96px !important;

    @media screen and (max-width: 959px) {
      padding-top: 48px !important;
      padding-bottom: 48px !important;
    }
  }

  .co-overflow-hidden {
    overflow: hidden !important;
  }

  .co-shrink-0 {
    flex-shrink: 0 !important;
  }

  .co-text-line1 {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
  }

  .co-text-line2 {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 2 !important;
    -webkit-box-orient: vertical !important;
  }

  .co-box-boder {
    box-sizing: border-box !important;
  }

  .co-box-content {
    box-sizing: content-box !important
  }
</style>
