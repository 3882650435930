
import api from '@/api/co.api'
import comm from '@/api/activity/co.activity.comm'
import dayjs from 'dayjs'

const activityReport = {
  getParams ({
    siteId,
    activityId,
    type = '',
    sort = 'createTime',
    order = 'desc',
    limit = '10',
    caches = [],
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: api.url.UC_REPORT_LIST,
      data: {
        siteId,
        activityId,
        type,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [],
      executing,
      executed,
      transform: transform || this.convertItems
    })

    return params
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = api.comm.createParams({
      url: `${api.url.UC_REPORT_DETAIL}/${id}`,
      executing,
      executed,
      transform: transform || this.convertItem
    })

    api.http.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null,
  }) {
    api.http.delete({
      url: api.url.UC_REPORT_DELETE,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data = {},
    executing = null,
    executed = null,
  }) {
    api.http.update({
      url: api.url.UC_REPORT_UPDATE,
      id,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    const actLabels = comm.Labels[srcItem.type]

    const updateTime = dayjs(srcItem.updateTime, 'YYYY-MM-DD hh:mm')
    const startTime = dayjs(srcItem.startTime, 'YYYY-MM-DD')
    const endTime = dayjs(srcItem.endTime, 'YYYY-MM-DD')

    const reportInfo = api.comm.jsonToObject(srcItem.reportInfo)
    const statusDesc = comm.getReportStateDesc(srcItem.status)
    const icon = {
      name: 'more_ver',
      color: 'gray700',
      margin: 'm0',
      padding: 'pl6'
    }

    const infoUpdate = {
      name: 'update',
      text: `${updateTime}`,
      effect: 'plain',
      type: 'gray'
    }
    const infoReport = {
      name: 'report',
      text: '没有提交文件',
      type: 'gray'
    }
    if (reportInfo.length) {
      infoReport.text = `${reportInfo.length} 个文件`
      infoReport.type = 'info'
    }
    const infoState = {
      ...statusDesc
    }
    const infos = []
    infos.push(infoState)
    infos.push(infoReport)
    infos.push(infoUpdate)

    let _remark = {
      report: {},
      verifing: {},
      checking: {}
    }

    let memo = ''
    let remarkExtend = false
    try {
      if (srcItem.remark) {
        _remark = JSON.parse(srcItem.remark)
        remarkExtend = true
        memo = _remark.report.memo || ''
      }
    } catch (e) {
      remarkExtend = false
      memo = srcItem.remark || ''
    }

    const timelimit = `${actLabels.hintTitle} ${startTime} ~ ${endTime}`

    const dstItem = {
      title: srcItem.activityTitle,
      subtitle: timelimit,
      timelimit,
      icon,
      infos,
      status: srcItem.status,
      statusDesc,
      reportId: srcItem.reportId,
      reportTitle: srcItem.reportTitle,
      companyId: srcItem.companyId || '',
      leaderId: srcItem.leaderId || '',
      groupName: srcItem.groupName || '',
      groupMember: srcItem.groupMember || '',
      type: srcItem.activityType,
      activityId: srcItem.activityId,
      userId: srcItem.userId,
      siteId: srcItem.siteId,
      reportInfo,
      memo,
      remarkExtend,
      createTime: srcItem.createTime
    }
    return dstItem
  },
  convertItems (params, srcItems) {
    for (const i in srcItems) {
      const dstItem = activityReport.convertItem(srcItems[i])
      params.items.push(dstItem)
    }

    return params.items.length
  }
}

export default activityReport
