<template>
  <v-row
    :class="[margin]"
    :no-gutters="false"
  >
    <v-col
      v-for="(item, i) in items"
      :key="`gallery_${i}`"
      class="land-gallery"
      cols="12"
      :sm="smCols"
      :md="mdCols"
    >
      <v-hover
        v-slot="{ hover }"
        :disabled="!hovered"
        close-delay="120"
      >
        <v-card
          :elevation="hover ? hoverElevation : normalElevation"
          :class="[round, padding, 'transition-swing']"
          :color="backColor"
          flat
          tile
        >
          <v-img
            :aspect-ratio="ratio"
            :src="item.cover || defCover"
            class="align-end"
            height="inherit"
            @click="onClick(item, i)"
          >
            <div
              v-if="titled && floated"
              :class="[
                'text-uppercase', 'text-md-h6', 'text-sm-h7', 'px-5', 'py-2',
                textAlign, 'white--text', 'grey darken-4', 'co-text-line1'
              ]"
              style="opacity: 0.75; line-height: 32px;"
            >
              {{ item.title }}
            </div>
          </v-img>
          <div
            v-if="titled && !floated"
            :class="[
              'text-uppercase', 'text-md-h6', 'text-sm-h7',
              'px-5', 'py-3', textAlign, 'co-text-line1'
            ]"
            style="line-height: 32px"
            @click="onClick(item, i)"
          >
            {{ item.title }}
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
  import app from '@/api/co.app'

  export default {
    name: 'LandGallery',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      ratio: {
        // 默认为 5:3
        type: [Number, String],
        default: 1.67
      },
      backColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      titled: {
        type: Boolean,
        default: true
      },
      floated: {
        type: Boolean,
        default: false
      },
      titleAlign: {
        type: String,
        default: 'left'
      },
      perColumns: {
        type: [Number, String],
        default: 3
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 18
      }
    },
    data () {
      return {
        perCols: {
          c1: {
            sm: 12,
            md: 12
          },
          c2: {
            sm: 12,
            md: 6
          },
          c3: {
            sm: 6,
            md: 4
          },
          c4: {
            sm: 6,
            md: 3
          },
        },
        titleAligns: {
          left: 'text-left',
          center: 'text-center',
          right: 'text-right'
        },
        defCover: `${app.base.res_skin}/assist/def_cover.jpg`
      }
    },
    computed: {
      smCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.sm
      },
      mdCols () {
        const obj = this.perCols[`c${this.perColumns}`] || {
          sm: 6,
          md: 4
        }
        return obj.md
      },
      textAlign () {
        return this.titleAligns[this.titleAlign] || this.titleAligns.left
      }
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>

<style lang="sass">
  .land-gallery
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)

  .land-overlay
    height: 56px
    left: 0 !important
    right: 0 !important
    bottom: 0 !important
    top: auto !important
</style>
