<template>
  <v-row :class="[margin, padding, backColor]">
    <v-col
      v-for="(item, i) in items"
      :key="`notice_${i}`"
      cols="12"
      class="px-0 py-0"
    >
      <v-hover
        v-slot="{ hover }"
        :disabled="!hovered"
        close-delay="120"
      >
        <v-card
          :elevation="dense ? 0 : (hover ? hoverElevation : normalElevation)"
          :class="[round, 'transition-swing', 'co-overflow-hidden', dense ? '' : 'mb-4']"
          :color="cardColor"
          flat
          tile
        >
          <div
            :class="['d-flex', 'flex-row', 'align-center', 'co-w-full',
                     dense ? 'pr-4' : 'px-4',
                     dense ? 'py-1' : 'py-3'
            ]"
          >
            <v-icon
              v-if="indicator"
              :small="indicatorSmall"
              :color="hover ? 'primary' : 'blue-grey lighten-2'"
              class="pr-3"
            >
              {{ indicator }}
            </v-icon>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <a
                  :class="['text-subtitle-1', 'd-inline-block', 'text-truncate',
                           hover ? 'primary--text' : 'black--text darken-4'
                  ]"
                  style="flex: 1;"
                  v-bind="attrs"
                  v-on="on"
                  @click="onClick(item, i)"
                >
                  {{ item.title }}
                </a>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>

            <span class="text-body-2 blue-grey--text lighten-2 co-shrink0 pl-9">
              {{ shortDated ? item.shortDate : item.createDate }}
            </span>
          </div>
        </v-card>
      </v-hover>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'LandPlainNotices',
    props: {
      items: {
        type: Array,
        default: () => {
          return []
        }
      },
      backColor: {
        type: String,
        default: 'transparent'
      },
      cardColor: {
        type: String,
        default: 'white'
      },
      round: {
        type: String,
        default: 'rounded-lg'
      },
      margin: {
        type: String,
        default: 'ma-0'
      },
      shortDated: {
        type: Boolean,
        default: false
      },
      indicator: {
        type: String,
        default: 'mdi-record'
      },
      indicatorSmall: {
        type: Boolean,
        default: true
      },
      padding: {
        type: String,
        default: 'pa-0'
      },
      hovered: {
        type: Boolean,
        default: true
      },
      dense: {
        type: Boolean,
        default: false
      },
      normalElevation: {
        type: [Number, String],
        default: 0
      },
      hoverElevation: {
        type: [Number, String],
        default: 8
      }
    },
    data () {
      return {}
    },
    computed: {
      timeFontSize () {
        if (this.$vuetify.breakpoint.mdAndUp) {
          return 'text-md-body-2'
        } else {
          return 'text-sm-caption'
        }
      }
    },
    methods: {
      onClick (item, index) {
        const params = {
          index,
          item
        }
        this.$emit('click', params)
      }
    }
  }
</script>

<style lang="sass">
</style>
