
import api from '@/api/co.api'

const HrefTypes = {
  // 文件
  FILE: 'file',
  // 图片
  IMAGE: 'image',
  // 链接
  URL: 'url'
}

const HrefTypesDesc = {
  [HrefTypes.URL]: {
    text: '链接',
    value: HrefTypes.URL,
    style: api.comm.StateStyles.BLUE
  },
  [HrefTypes.FILE]: {
    text: '文件',
    value: HrefTypes.FILE,
    style: api.comm.StateStyles.TEAL
  },
  [HrefTypes.IMAGE]: {
    text: '图片',
    value: HrefTypes.IMAGE,
    style: api.comm.StateStyles.ORANGE
  }
}

const coKindComm = {
  HrefTypes,
  HrefTypesDesc
}

export default coKindComm
