
import app from '@/api/co.app'

import comm from '@/api/kind/co.kind.comm'
import topic from '@/api/kind/co.kind.topic'
import category from '@/api/kind/co.kind.category'
import banner from '@/api/kind/co.kind.banner'
import theme from '@/api/kind/co.kind.theme'

const HeroTypes = [
  banner.Types.notice,
  banner.Types.service,
  banner.Types.park
]

export default {
  comm,
  topic,
  category,
  banner,
  theme,
  initialize () {
    banner.initialize({
      siteId: app.init.siteId,
      type: HeroTypes.join('|')
    })
  }
}
