var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column",class:[ _vm.pattern ]},[(!_vm.readonly)?_c('v-file-input',{attrs:{"counter":"","show-size":"","label":_vm.label,"multiple":_vm.multiple,"prepend-icon":_vm.prependIcon,"hint":_vm.hint,"accept":_vm.accept,"outlined":"","persistent-hint":"","loading":_vm.loading,"disabled":_vm.loading,"value":_vm.addItems},on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var index = ref.index;
var text = ref.text;
var file = ref.file;
return [(index < 3)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"dark":"","label":"","small":""}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(text)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(file.name))])]):(index === 3)?_c('span',{staticClass:"text-overline grey--text text--darken-3 mx-2"},[_vm._v(" +"+_vm._s(_vm.addItems.length - 3)+" 文件 ")]):_vm._e()]}},(_vm.needUpload)?{key:"append",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"icon":"","width":"24","height":"24","color":_vm.btnUpload.color,"disabled":_vm.loading},on:{"click":_vm.toUploads}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.btnUpload.icon)+" ")])],1)]}}],null,false,2485732689)},[_c('span',[_vm._v(_vm._s(_vm.btnUpload.text))])])]},proxy:true}:null],null,true)}):_vm._e(),_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.descs),function(desc,ind){return _c('div',{key:("desc_" + ind),staticClass:"d-flex flex-row align-center rounded blue-grey lighten-5 pa-3 mb-2"},[_c('div',{staticClass:"d-flex flex-row co-flex-grow align-center",staticStyle:{"max-width":"70%"}},[(desc.icon)?_c('v-icon',{attrs:{"color":desc.color}},[_vm._v(" "+_vm._s(desc.icon)+" ")]):_vm._e(),_c('span',{staticClass:"pl-2 pr-6 co d-inline-block text-truncate"},[_vm._v(" "+_vm._s(desc.nameEx)+" ")])],1),_c('v-spacer'),_c('div',{staticClass:"d-flex flex-row align-center"},_vm._l((desc.buttons),function(btn,btnIndex){return _c('div',{key:("btn_" + btnIndex)},[(btn.visible)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",attrs:{"outlined":btn.outlined,"dark":btn.dark,"disabled":_vm.loading,"x-small":"","fab":"","depressed":"","color":btn.color,"width":"28","height":"28"},on:{"click":function($event){return _vm.onHandle(btn, ind)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(btn.icon)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(btn.text))])]):_vm._e()],1)}),0)],1)}),0),_c('land-dialog',{ref:"dlg"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }