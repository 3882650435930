
import api from '@/api/co.api'

const configMain = {
  getParams ({
    siteId = '',
    type = '',
    search = '',
    limit = '20',
    sort = 'label',
    order = 'asc',
    caches = [],
    executing = null,
    executed = null
  }) {
    const me = this
    const obj = {
      url: api.url.UPMS_CONFIG_LIST,
      data: {
        siteId,
        type,
        search,
        offset: '0',
        limit,
        sort,
        order
      },
      items: caches || [], // 保存已请求到的数据
      reload: false, // 数据是否重新加载
      loadType: 0,
      total: 0,
      executing, // 请求前执行
      executed, // 请求后执行
      transform: me.convertItems
    }

    return obj
  },
  getDetail ({
    id,
    executing = null,
    executed = null,
    transform = null
  }) {
    const params = {
      url: `${api.url.MNG_CONFIG_DETAIL}/${id}`,
      data: null,
      executing,
      executed,
      transform: transform || this.convertItem
    }
    api.http.getDetail(params)
  },
  delete ({
    id,
    executing = null,
    executed = null
  }) {
    api.http.delete({
      url: api.url.MNG_CONFIG_DELETE,
      id,
      executing,
      executed
    })
  },
  update ({
    id,
    data,
    executing = null,
    executed = null
  }) {
    api.http.update({
      url: api.url.MNG_CONFIG_UPDATE,
      id,
      data,
      executing,
      executed
    })
  },
  create ({
    data,
    executing = null,
    executed = null
  }) {
    api.http.create({
      url: api.url.MNG_CONFIG_CREATE,
      data,
      executing,
      executed
    })
  },
  convertItem (srcItem) {
    return { ...srcItem }
  },
  convertItems (params, srcItems) {
    srcItems.forEach(item => {
      const dstItem = configMain.convertItem(item)
      params.items.push(dstItem)
    })
    return params.items.length
  }
}

export default configMain
